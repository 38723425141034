.BCP__wrapper-main {
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    overflow: visible;
    border: 0;
    background: linear-gradient(180deg, #7E59CB 0%, #170D14 100%);
}

.BCP__wrapper {
    min-width: 100%;
    min-height: 100vh;
    background: url(../../../public/assets/bookCall-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.BCP__body {
    min-height: 100%;
    max-width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: 4.5rem 15rem;
}

.BCP__body>h1 {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    font-size: 3.6rem;
    font-weight: 700;
}

@media screen and (max-width: 992px) {
    .BCP__body {
        padding: 4.5rem 4.5rem;
    }
}

@media screen and (max-width: 600px) {
    .BCP__body {
        padding: 3rem 2rem;
    }
}