.editAbout__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 80%;
    height: 80%;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    overflow-y: scroll;
}

.editAbout__container::-webkit-scrollbar {
    margin-top: 1rem;
    width: .000007rem;
}

.editAbout__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    padding: 2rem 3rem;
}

.editAbout__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editAbout__main>h1>span {
    cursor: pointer;
}

.editAbout__main>div {
    display: flex;
    flex-flow: column nowrap;
}

.editAbout__formcol {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 100%;
}

.editAbout__formcol>div {
    width: 100%;
}

.editAbout__formcol>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
}

.editAbout__formcol>div>input {
    min-width: 100%;
    height: 3.5rem;
    outline: none;
    background-color: black;
    color: white;
    padding: 0.6rem 1.7rem;
    font-size: 1.2rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}

.editAbout__formcol>div>textarea {
    width: 100%;
    min-height: 20rem;
    outline: none;
    background-color: black;
    color: rgba(255, 255, 255, 0.938);
    padding: 0.6rem 1.7rem;
    font-size: 1.4rem;
    margin: 1rem 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}

.editAbout__form-margin {
    margin-right: 5%;
}

.editAbout__save {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5rem;
}

.editAbout__save>div {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 3.5rem;
    width: 8rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .editAbout__container {
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
        border-radius: 0;
    }
}

@media screen and (max-width: 650px) {
    .editAbout__main {
        padding: 1rem 1.5rem;
    }

    .editAbout__formcol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-width: 100%;
    }

    .editAbout__form-margin {
        margin-right: 0;
    }

}