.personal__main {
  min-height: 100vh;
  min-width: 100%;
  border-radius: 50px 0px 0px 50px;
  background: #000;
  box-shadow: -5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 4.5rem 8rem 4.5rem 8rem;
  position: relative;
  background-image: url("../../../../public/assets/world-map.svg");
  background-repeat: no-repeat;
}

.personal {
  height: 100%;
  width: 100%;
}

/* ----------------- top heading --------------- */
.personal__head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.personal__head>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 2.6rem;
  font-weight: 700;
}

.personal__head>img {
  width: 5rem;
  height: 5rem;
}

/* ----------------- top para --------------- */

.personal>p {
  color: #fff;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0.6rem;
}

/* ----------------- Steps --------------- */
.personal__step {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.12rem;
}

/* Step 1 */
.personal__step1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5rem;
}

.personal__step1>p {
  width: 4.55rem;
  height: 4.5rem;
  border-radius: 10rem;
  background: linear-gradient(to top, #6f57d8 14.06%, #d56a8f 93.75%);
  display: grid;
  place-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 400;
  margin-right: 0.95rem;
}

.personal__step1>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Step 2 */
.personal__step2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.personal__step2>p {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  display: grid;
  place-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 400;
  margin-right: 0.95rem;
  background: linear-gradient(#000000 0 0) padding-box,
    linear-gradient(to bottom, #6f57d8, #d56a8f) border-box;
  border: 2px solid transparent;
}

.personal__step2>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* --------------personal__form--------------- */
.personal__form {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  color: white;
}

.personal__form>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.4rem;
  font-weight: 500;
}

.personal__form>input {
  width: 100%;
  height: 5rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  border: 2px solid #7d59cc;
  outline: none;
  background-color: black;
  color: white;
  padding: 0.6rem 1.7rem;
  font-size: 1.4rem;
  margin: 0.87rem 0 2.5rem 0;
}

.personal__form>div {
  width: 12rem;
  height: 4rem;
  border-radius: 0.9375rem;
  background: linear-gradient(180deg, #7557d2 0%, #b363a5 100%);
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
}




.personal__phoneNo {

  position: relative;
  margin: 0.87rem 0 2.5rem 0;
  min-width: 100%;

}

/* Custom styles for the react-phone-number-input */
.PhoneInput {
  position: absolute;

  border: 1px solid white;
  width: 100%;
  height: 5rem;
  border-radius: 0.9375rem;
  border: 2px solid #7d59cc;
  outline: none;
  background-color: black;
  color: white;
  padding: 0.6rem 1.7rem;
  font-size: 1.4rem;
}

/* Style the input field */
.PhoneInputInput {
  background-color: transparent;
  outline: none;
  color: white;
  border: none;
  font-size: 1.4rem;
  padding-left: .5rem;
}

/* Style the country select dropdown */
.PhoneInputCountrySelect {
  /* Set the dropdown's background color to dark */
  background-color: #333;
  /* Set the text color to white */
  color: white;

  /* Set the dropdown arrow color to white */
  &::after {
    color: white;
  }

}

/* Style the country select container to place it on the left */
.PhoneInputCountry {
  /* Set the container's alignment to the left */
  text-align: left;
  /* Set padding for better spacing */
  /* padding-left: 10px; */
  /* Adjust margin to move it closer to the input field */
  /* margin-right: -10px; */
}

/* Style the country select input */
.PhoneInputCountrySelect input {
  /* Set the input's text color to white */
  color: rgb(255, 33, 33);
  /* Remove the default border */
  border: none;
  /* Add a bottom border to separate it from the dropdown */
  border-bottom: 1px solid #444;
}


@media screen and (max-width: 990px) {
  .personal__main {
    border-radius: 0 0 0 0;
    padding: 4.5rem 4rem 4.5rem 4rem;
  }

  .personal__head>h1 {
    font-size: 3rem;
  }

  .personal__head>img {
    width: 6rem;
    height: 6rem;
  }

  .personal>p {
    font-size: 1.8rem;
  }

}

@media screen and (max-width: 990px) {
  .personal__main {
    padding: 4.5rem 2rem 4.5rem 2rem;
  }
}