.deleteChatModal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 4px;
    border-radius: 2rem;
    /* background: linear-gradient(347deg, #000 0%, #18151C 100%); */
    background: #18151C;
    height: 20rem;
    width: 40rem;
}

.deleteChatModal__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 100%;
    padding: 2rem 4rem;
}

.deleteChatModal__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deleteChatModal__main>h1>span {
    cursor: pointer;
}


.deleteChatModal__save {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 2rem;
}

.deleteChatModal__chatBg>div {
    background: var(--g9,
            conic-gradient(from 55deg at 48.2% 55.51%,
                rgba(71, 56, 105, 1) 0%,
                rgba(46, 113, 153, 1) 30%,
                rgba(37, 80, 104, 1) 50%,
                rgba(18, 29, 54, 1) 70%,
                rgba(18, 25, 47, 1) 85%,
                rgba(47, 19, 78, 1) 100%));
}

.deleteChatModal__mentorBg>div {
    background: linear-gradient(112deg, #7C58C9 12.37%, #BD5E81 65.76%);
}

.deleteChatModal__save>div {
    display: grid;
    place-content: center;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}