/* ------------------profile__about----------------- */
.profile__aboutmain {
    padding: 4rem 8rem;
    max-width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile__about {
    max-width: 135rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 3rem;
}

.profile__about-left {
    flex-basis: 40%;
    /* border: 1px solid white; */
    min-height: 100%;
    max-width: 100%;
}

/* ------------------profile about experince----------------- */
.profile__about-exp {
    display: flex;
    flex-flow: column nowrap;
}

.profile__about-exp>h1 {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin-bottom: 1.75rem;
}

.profile__about-exp-comp {
    display: flex;
    flex-flow: column nowrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    padding-bottom: .5rem;
    margin-bottom: 2rem;
}
.profile__about-exp-comp>p{
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    letter-spacing: 0.03rem;
}

.profile__about-exp-comp-img {
    display: flex;
    flex-flow: row;
}

.profile__about-exp-comp-img>img {
    width: 2rem;
    height: 2rem;
    margin-right: .8rem;
    margin-left: 1rem;
}

.profile__about-exp-comp-img>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
}

.profile__about-exp-comp-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.2rem;
}

.profile__about-exp-comp-text>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.03rem;
}

.profile__about-exp-comp-text>span {
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.01875rem;
}

.profile__edu {
    margin-top: 3rem;
}

/* ------------------profile about right side----------------- */
.profile__about-right {
    flex-basis: 60%;
    min-height: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

/* ------------------profile about right side description----------------- */
.profile__about-right-desc {
    display: flex;
    flex-flow: column nowrap;
}

.profile__about-right-desc>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.profile__about-right-desc>h1 {
    color: #7350B8;
    font-family: Poppins;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: .3rem;
}

.profile__about-right-desc>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    letter-spacing: 0.05rem;
}

.profile__about-right-desc>p>span {
    font-size: 1rem;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

/* -------------skills--------------- */
.profile__about-skills {
    max-width: 100%;
}

.profile__about-skills-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 1.7rem 0 2rem 0;
}

.profile__about-skills-head>img {
    height: 2.7rem;
    width: 2.7rem;
}

.profile__about-skills-head>h1 {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
}

.profile__about-skills>div {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.8rem;

}

.profile__about-skills>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .7rem 1rem;
    border-radius: 0.375rem;
    border: 1px solid #FFF;
    color: #FFF;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
}

.profile__about-skills>div>span {
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 3rem;
    text-decoration-line: underline;
    cursor: pointer;
}

/* -----------------profile__about-right-trial-------------- */
.profile__about-right-trial {
    max-width: 100%;
    min-height: 9.75rem;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 2px solid transparent;
    border-radius: 0.625rem;
    position: relative;
    padding: 1.6rem 1.5rem 1rem 1.6rem;
    margin-top: 2.4rem;
}

.profile__about-right-trial-float {
    position: absolute;
    top: -1.6rem;
    left: 3.5rem;
    padding: .7rem 1.6rem;
    border-radius: 2.9375rem;
    background: rgba(190, 94, 128);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 600;
}

.profile__about-right-trial>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
}

.profile__about-right-trial-btn {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: flex-end;
}

.profile__about-right-trial-btn>div {
    max-width: 21.375rem;
    padding: .5rem 1.6rem;
    border-radius: 1.6875rem;
    background: #FFF;
    box-shadow: -5px -4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #7057D6;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    cursor: pointer;
    user-select: none;
}

.profile__about-right-trial-btn>div>i {
    margin-left: 1rem;
}



@media screen and (max-width: 1100px) {
    .profile__aboutmain {
        padding: 2rem 4rem;
    }

    .profile__about {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 992px) {

    .profile__about {
        flex-flow: column-reverse nowrap;
        gap: 2rem;
    }

    .profile__about-left {
        flex-basis: 100%;
        gap: 1rem;
    }

    /* ------------------profile about experince----------------- */
    .profile__about-exp {
        display: flex;
        flex-flow: column nowrap;
    }

    .profile__edu {
        margin-top: 0;
    }

    /* ------------------profile about right side----------------- */
    .profile__about-right {
        flex-basis: 60%;
        min-height: 100%;
        max-width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    /* ------------------profile about right side description----------------- */
    .profile__about-right-desc {
        display: flex;
        flex-flow: column nowrap;
    }

    .profile__about-right-desc>h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 1.4rem;
    }

    .profile__about-right-desc>h1 {
        color: #7350B8;
        font-family: Poppins;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        margin-bottom: .3rem;
    }

    .profile__about-right-desc>p {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
    }

    /* -------------skills--------------- */
    .profile__about-skills {
        max-width: 100%;
    }

    .profile__about-skills-head {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 1.7rem 0 2rem 0;
    }

    .profile__about-skills-head>img {
        height: 2.7rem;
        width: 2.7rem;
    }

    .profile__about-skills-head>h1 {
        color: rgba(255, 255, 255, 0.60);
        font-family: Poppins;
        font-size: 2rem;
        font-weight: 600;
    }

    .profile__about-skills>div {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.8rem;

    }

    .profile__about-skills>div>div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .7rem 1rem;
        border-radius: 0.375rem;
        border: 1px solid #FFF;
        color: #FFF;
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 500;
    }

    /* .profile__about-skills>div>span {
        color: #FFF;
        font-family: Poppins;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.9rem;
        text-decoration-line: underline;
        cursor: pointer;
    } */

    .profile__about-right-trial {
        display: none;
    }

}

@media screen and (max-width:600px) {
    .profile__aboutmain {
        padding: 2rem;
    }

    .profile__about {
        gap: 1.5rem;
    }

    .profile__about-exp-comp-text {
        flex-flow: column nowrap;
        align-items: flex-start;
        margin-top: 1.2rem;
        gap: .3rem;
    }
}