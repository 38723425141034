.eventcard {
    min-width: fit-content;
    max-height: 100%;
    border-radius: 2.4rem;
    background: linear-gradient(180deg, rgba(113, 79, 180, 0.60) 0%, rgba(28, 17, 32, 0.60) 100%);
    padding: 1.8rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}


/* --------------eventcard top heading--------------- */
.eventcard__tophead {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.eventcard__tophead>img {
    height: 3rem;
    margin-right: 0.4rem;
}

.eventcard__tophead>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* ------------------eventcard bio----------------- */
.eventcard__bio {
    height: 20rem;
    min-width: 100%;
    border-radius: 1.5rem;
    background: #DE6C87;
    position: relative;
    margin-top: 1rem;
    overflow: hidden;
}

.pasteventcard__bio {
    min-width: 100%;
    height: 20rem;
    border-radius: 0.5rem;
    background: linear-gradient(124deg, #1E1E1E 9.49%, #505360 70.29%);
    position: relative;
    margin-top: 1rem;
}

.eventcard__bio-img {
    position: absolute;
    top: 1.2rem;
    right: 1rem;
    width: 9rem;
    height: 9rem;
    border-radius: 10.6875rem;
    box-shadow: 4px 4px 70px 1px rgba(0, 0, 0, 0.50);
    overflow: hidden;
    display: grid;
    place-items: center;
}

.eventcard__bio-img>img {
    max-width: 100%;
}

.eventcard__bio>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    width: 23rem;
    padding: 1.4rem 0 0 1.3rem;
}

.pasteventcard__bio>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    width: 23rem;
    padding: 1.2rem 0 0 1.3rem;
}

.eventcard__bio>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    width: 23rem;
    margin: .4rem 0 1rem 1.3rem;
}

.pasteventcard__bio>h1 {
    color: #FFF;
    color: #FFF;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
    width: 23rem;
    margin: .4rem 0 1rem 1.3rem;
}

.eventscard__bio-bottom {
    position: absolute;
    bottom: 1.7rem;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 4.5rem;
}

.eventscard__bio-bottom1 {
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    width: 15rem;
    height: 4.5rem;
    margin-right: -1.3rem;
    padding: 0.3rem 0.62rem;
}

.eventscard__bio-bottom1>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: .07rem;
}

.eventscard__bio-bottom1>h3>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
}

.eventscard__bio-bottom2 {
    min-width: 8.25rem;
    border-radius: 0.3125rem;
    background: #E7E6E8;
    height: 100%;
    padding: 0.3rem 0.62rem;
}

.eventscard__bio-bottom2>h3 {
    color: #06050C;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 600;
}

.eventscard__bio-bottom2>p {
    color: #05050A;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 500;
}


/* --------------event card h1 -------- */
.eventcard__head {
    padding: 1rem 0.5rem;
    max-width: 36rem;
}

.eventcard__head>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.9rem;
    min-height: 100%;
}

/* -------------eventcard__mentor--------------- */
.eventcard__mentor {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.eventcard__mentor-img {
    width: 5rem;
    height: 5rem;
    border-radius: 10.6875rem;
    box-shadow: 4px 4px 70px 1px rgba(0, 0, 0, 0.50);
    display: grid;
    place-items: center;
    overflow: hidden;
    margin-right: .8rem;
}

.eventcard__mentor-img>img {
    max-width: 100%;
}

.eventcard__mentor-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
}

.eventcard__mentor-text>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5625rem;
}

.eventcard__mentor-text>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
}

/* ------------eventcard bottom ----------------- */
.eventcard__btm {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    min-width: 100%;
    justify-content: space-between;
}

/* ------------eventcard button ----------------- */
.eventcard__btn {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}

.eventcard__btn-time {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.eventcard__btn-time>h1 {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 1.4rem 0 .9rem;
}

.pasteventcard__btn-time>h1 {
    padding: .6rem 1rem;
    border-radius: 1rem;
    background: #000;
    color: rgba(255, 255, 255, 0.60);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 0 1.4rem 0 0;
}

.eventcard__btn-main {
    border-radius: 0.625rem;
    border: 1px solid #7A58CD;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 600;
    padding: .7rem 1.1rem;
    transition: background-color 0.5s ease-in-out;
    cursor: pointer;
}

.eventcard__btn-main:hover {
    border: 1px solid #7A58CD;
    color: #FFF;
    background-color: #DE6C87;
    transition: background-color 0.5s ease-in;
}

/* @media screen and (max-width: 1990px) {
    .eventcard {
        min-width: 40rem;
        height: 50rem;
        padding: 1.8rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1580px) {
    .eventcard {
        min-width: 40rem;
        padding: 1.8rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1440px) {
    .eventcard {
        min-width: 35rem;
    }
}

@media screen and (max-width: 1320px) {
    .eventcard {
        min-width: 35rem;
        padding: 3rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: space-between;
    }
}

@media screen and (max-width: 970px) {
    .eventcard {
        min-width: 0;
        padding: 3rem;
        height: 54rem;
    }
}

@media screen and (max-width: 600px) {
    .eventcard {
        min-width: 0;
        min-width: 48rem;
        height: 55rem;
        padding: 1.8rem;
        border-radius: 2rem;
    }

    .eventcard__bio {
        margin-top: 0;
        border-radius: 1.2rem;
    }

    .pasteventcard__bio {
        margin-top: 0;
        border-radius: 1.2rem;
    }

   

}

@media screen and (max-width: 360px) {
    .eventcard {
        min-width: 0;
        min-width: 48rem;
        padding: 1.8rem;
    }
} */




.eventcard-loading {
    min-width: 35rem;
    max-height: 45rem;
    border-radius: 2.4rem;
    background: #121212;
    padding: 1.8rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
}

@media screen and (max-width: 400px) {
    .eventcard-loading {
        min-width: 100%;
    }
}

.eventcard__tophead-loading {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 2rem;
    gap: 1rem;
}

.eventcard__bio-loading {
    height: 20rem;
    min-width: 100%;
    border-radius: 1.5rem;
    position: relative;
    margin-top: 1rem;
}

.eventcard__head-loading {
    display: flex;
    flex-flow: column nowrap;
    height: 5rem;
    gap: 1rem;
    margin-top: 1rem;
}

.eventcard__mentor-loading {
    display: flex;
    flex-flow: row nowrap;
    height: 5rem;
    gap: 1rem;
    margin-top: 1rem;
}

.eventcard__mentor-loading>div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 5rem;
    gap: 1rem;
    width: 100%;
}