/* ------------------drop down css------------------- */

.startup__custom-dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-height: 5rem;
    border-radius: 0.7rem;
    border: 2px solid #7D59CC;
    outline: none;
    background-color: black;
    font-size: 1rem;
    margin: 0 0 2.19rem 0;
}

.startup__chips-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-height: 3.4rem;
    padding: 0.5rem 1rem;
}

.startup__chips-container>span {
    padding: 0.4rem .67rem;
    font-size: 1.6rem;
    color: #E5E3E3;
    opacity: .5;
}

.startup__chip {
    height: fit-content;
    width: fit-content;
    padding: .6rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
    transition: background-color 0.5s;
    color: rgba(255, 255, 255, 0.70);
    border-radius: 0.625rem;
    /* background: linear-gradient(180deg, #6E4FB4 0%, #100B1A 100%); */
    background: rgb(228, 227, 227);
    color: black;
}

.startup__remove-icon {
    margin-left: 8px;
}

.startup__dropdown-arrow {
    position: absolute;
    right: 0.8rem;
    background-color: black;
    font-size: 1rem;
    cursor: pointer;
    color: #727171;
}

.startup__dropdown-arrow.open {
    transform: rotate(180deg);
    background-color: black;
}

.startup__options-list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background-color: black;
    border: 2px solid #7D59CC;
    overflow: hidden;
    z-index: 1;
}

.startup__option {
    cursor: pointer;
    padding: .8rem 2rem;
    overflow: hidden;
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #333;
    transition: background-color 0.5s;
}

.startup__option:hover {
    background: #444;
    transition: background-color 0.5s;
}

.startup__option.startup__option_selected {
    background: linear-gradient(111deg, #6F57D8 14.06%, #D56A8F 93.75%);
}

.startup__tick {
    position: absolute;
    right: 2rem;
    font-weight: 800;
}

.startup__selected {
    background-color: #444;
}

.startup__selected.dropdown-arrow {
    transform: rotate(0);
    margin-left: 8px;
}