.mentordash__wrapper-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(33, 28, 43), rgb(0, 0, 0));
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    padding-top: 2rem;
}

.mentordash__wrapper {
    min-width: 100%;
    min-height: 100vh;
    position: relative;
    padding: 0 8rem;
}

.mentordash__body {
    height: 100%;
    max-width: 135rem;
    margin: 0 auto;
    position: relative;
}

/* ----------------------Main profile-------------------- */
.mentordash__main {
    border-radius: 2rem 2rem 0 0;
}


.mentordash__main-banner {
    border-radius: 2rem;
    min-height: 11rem;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mentordash__main-banner>h1 {
    background-image: linear-gradient(to bottom, #6F57D8, #D56A8F);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3rem;
    font-family: Poppins;
    font-weight: 400;
}

.mentordash__main-about {
    min-height: 14rem;
    background: #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding: 4.5rem 4rem 2rem 4rem;
    gap: 2rem;
    position: relative;
}

.mentordash__main-about>span {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 4rem;
}

/* ----------------------main about left-------------------- */
.mentordash__main-about-left {
    flex-basis: 50%;
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.mentordash__main-about-left-img {
    background-color: #D9D9D9;
    position: absolute;
    top: -12rem;
    border-radius: 50%;
    overflow: hidden;
    width: 12rem;
    height: 12rem;
    cursor: pointer;
}

.mentordash__main-about-left-img>img {
    width: 100%;
    height: 100%;
}

.mentordash__main-about-left>h1 {
    color: #000;
    font-family: Poppins;
    font-size: 3.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.mentordash__main-about-left>h1>span>img {
    width: 5rem;
}

.mentordash__main-about-left>h3 {
    color: rgba(0, 0, 0, 0.80);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.032rem;
    margin-bottom: .5rem;
}

.mentordash__main-about-left-comp {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.90);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.032rem;
    margin-bottom: .5rem;
}

.mentordash__main-about-left-followup {
    color: black;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: .4rem 0;
}


.mentordash__main-about-left-followup-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    display: grid;
    place-items: center;
    margin-right: 1.2rem;
    background-color: #7350B8;
}

.mentordash__main-about-left-followup-icon>img {
    height: 1.5rem;
}

.mentordash__main-about-left-followup-text>p {
    color: rgba(0, 0, 0, 0.99);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}


.mentordash__main-about-left-comp>span {
    height: 3rem;
    width: 3rem;
}

.mentordash__main-about-left-comp>span>img {
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
}

.mentordash__main-about-left>h2 {
    color: rgba(4, 1, 1, 0.9);
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.12rem;
}

.mentordash__main-about-left-btn {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: .6rem;
}

.mentordash__main-about-left-btn-stat {
    color: rgba(0, 0, 0, 0.90);
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: 0.04rem;
}

.mentordash__main-about-left-btn-button {
    width: 13.1rem;
    height: 3.5rem;
    border-radius: .5rem;
    color: white;
    background-color: black;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.028rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    cursor: pointer;
}

.mentordash__main-about-left-btn-button>span {
    margin-right: .6rem;
}


/* ----------------------main about right-------------------- */
.mentordash__main-about-right {
    position: relative;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    color: black;
}

.mentordash__main-about-right>div {
    min-height: 50%;
}

.mentordash__main-about-right>div>h1 {
    color: #000;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.mentordash__main-about-right>div>h1>span {
    margin-right: 1rem;
}

.mentordash__main-about-right>div>span {
    color: rgba(0, 0, 0, 0.70);
    font-size: 1.6rem;
    font-weight: 500;
}

.mentordash__main-top-company {
    width: fit-content;
    max-height: 4.6875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #FFF;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: .4rem 1rem;
    margin-bottom: 2.5rem;
}

.mentordash__main-top-company>h1 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.0225rem;
}

.mentordash__main-top-company>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem
}

.mentordash__main-top-company>div>img {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-right: .5rem;
}




/* ----------------------Skill Section-------------------- */

.profile_main>div {
    margin-bottom: 20px;

}

.mentordash__skills-add {
    padding: 15px;
}

.mentordash__skills {
    /* border: 1px solid white; */
    padding: 10px;
    min-height: 19rem;
    padding: 20px;
    background-color: black;

}

.mentordash__skills>h2 {
    padding-left: 14px;
}

.mentordash__skills-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
}

.mentordash__add-input {
    display: flex;
    align-items: center;
    gap: 10px;

}

.mentordash__add-input>div {
    width: 67px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #BB65A0;

}

.mentordash__add-input>input {
    width: 247px;
    height: 40px;
    border-radius: 40px;
    color: #BB65A0;
    border: 1px solid #BB65A0;
    background: black;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    padding: 14px;

}

.mentordash__add-btn-select {
    color: black;
    background: black;
    width: 147px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid white;
    box-shadow: px 0px 6px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;


}

/* ----------------------Profile Introduction Section-------------------- */

.mentordash__intro {
    /* border: 1px solid white; */
    padding: 10px;
    min-height: 19rem;
    padding: 20px;
    background-color: black;
    border-radius: 10px;

}

.mentordash__intro>h2 {
    padding-left: 14px;
}

.mentordash__info>div {
    display: flex;

    margin: 18px;
    justify-content: space-between;
    align-items: center;

}

.mentordash__info>div>input {
    min-width: 49%;
    height: 40px;
    border-radius: 4px;
    color: #BB65A0;
    border: 1px solid #BB65A0;
    background: black;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    padding: 14px;


}

.mentordash__info>div>input::placeholder {
    color: white;
}

.mentordash__info>div:nth-child(1)>input {
    width: 100%;
}

.mentordash__info>div:nth-child(4)>input {
    width: 100%;
}

.mentordash__info>div:nth-child(5)>input {
    width: 100%;
}

.mentordash__btn {
    display: flex;
    justify-content: end;
    padding-right: 10px;
}

.grad {
    background: linear-gradient(112.09deg, #7C58C9 12.37%, #BD5E81 65.76%);
}

@media (max-width: 1024px) {
    .mentordash__container {
        background-color: rgb(192, 147, 34);
    }
}

/* inspired form gumroad website */
.btn {
    --bg: #000;
    --hover-bg: #ff90e8;
    --hover-text: #000;
    color: #fff;
    border: 1px solid var(--bg);
    border-radius: 4px;
    padding: 0.8em 2em;
    background: var(--bg);
    transition: 0.2s;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.btn:hover {
    color: var(--hover-text);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-bg);
    box-shadow: 0.25rem 0.25rem var(--bg);
}

.btn:active {
    transform: translate(0);
    box-shadow: none;
}


/* -------modals------- */

.mentordash__modal {
    display: none;
}



/* ------------------skills section ------------------ */

.mentordash__section {
    min-height: 5rem;
    padding: 1.5rem 4rem;
    border-radius: 20px;
    background: linear-gradient(88deg, #000 10.02%, #18151C 94.68%);
    /* box-shadow: 0 0 1rem -.1rem rgba(255, 255, 255, 0.764); */
    display: flex;
    flex-flow: column nowrap;
    margin-top: 2rem;
}

.mentordash__section>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mentordash__section>h1>span>i {
    cursor: pointer;
}

.mentordash__section>h1>i {
    cursor: pointer;
}

.mentordash__section>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.032rem;
}

.mentordash__section>code {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.032rem;
}

.mentordash__skills-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: .8rem;
}

.mentordash__skills-container>div {
    border: 1px solid white;
    padding: .8rem 1rem;
    color: #FFF;
    font-family: Poppins;
    border-radius: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
}

.mentordash_stats-main {
    min-height: 60rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 4rem;
}

.mentordash_stats-main>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3.5rem;
    font-weight: 600;
    flex: 1;
}

.mentordash_stats-main>div {
    color: #FFF;
    font-family: Poppins;
    font-size: 3.5rem;
    font-weight: 600;
}

/* ------------------education conatiner ---------------- */
.metordash__edu-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.4rem;

}

.metordash__edu-container>div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1rem;
}

.metordash__edu-container>div>img {
    cursor: pointer;
}

.metordash__edu-container>div>div {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
}

.metordash__edu-container-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.metordash__edu-container-img>div {
    display: grid;
    place-items: center;
    height: 5rem;
    width: 5rem;
    overflow: hidden;
}

.metordash__edu-container-img>div>img {
    max-width: 100%;
}

.metordash__edu-container-text>h1 {
    color: white;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.044rem;
}

.metordash__edu-container-text>h2 {
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    margin: .3rem 0 .2rem 0;
}

.metordash__edu-container-text>p {
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.028rem;
}

/* ------------------Experince conatiner ---------------- */
.metordash__exp-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}

.metordash__exp-container>div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 1rem;
}

.metordash__exp-container>div>img {
    cursor: pointer;
}

.metordash__exp-container>div>div {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
}

.metordash__exp-container-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.metordash__exp-container-img>div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: .5rem 0;
    height: 100%;
    width: 5rem;
    overflow: hidden;
}

.metordash__exp-container-img>div>img {
    max-width: 100%;
}

.metordash__exp-container-text>h1 {
    color: white;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.044rem;
}

.metordash__exp-container-text>h2 {
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    margin: .3rem 0 .3rem 0;
}

.metordash__exp-container-text>p {
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.028rem;
    margin: 1rem 0 0 .6rem;
}

/* -------------------upcoming sessions----------------------  */
.metordash__up-s-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    gap: 1rem;
    border: 1px solid gray;
    border-radius: 2rem;
    margin-bottom: 1rem;
    padding: 2rem 4rem;
}

.metordash__up-s-time {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.metordash__up-s-time>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
}

.metordash__up-s-time>p {
    color: var(--AI-Body-text-color, #A0A0A0);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.metordash__up-s-slot {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid gray;
    padding-top: 1.2rem;
}

.metordash__up-s-schdule {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
}

.metordash__up-s-schdule>p {
    color: var(--AI-Body-text-color, #A0A0A0);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.metordash__up-s-btn {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    align-items: center;
}

.metordash__up-s-btn1 {
    display: flex;
    padding: 0.8rem 2.4rem;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: linear-gradient(112deg, #7C58C9 12.37%, #BD5E81 65.76%);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
}

.metordash__up-s-btn2 {
    cursor: pointer;
    display: flex;
    padding: 0.8rem 2.4rem;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    border: 1px solid var(--colour, #7057D7);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
}

/* -------------------progres - bar----------------------  */
.circular-progress-bar {
    width: 50rem;
    height: 50rem;
}

.circular-progress-bar__background {
    fill: none;
    stroke: rgba(255, 255, 255, 0.96);
    stroke-width: .35rem;
}

.circular-progress-bar__fill {
    fill: none;
    stroke: url(#gradient);
    stroke-width: .35rem;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 0.8s;
}

/* No found para css */
.metordash__noData {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.032rem;
}

@keyframes gradient-animation {
    from {
        stop-color: #7e59cb;
    }

    to {
        stop-color: #bb649e;
    }
}

@media screen and (max-width: 1150px) {
    .mentordash__wrapper {
        padding: 0 4rem;
    }
}

@media screen and (max-width: 1050px) {
    .mentordash_stats-main {
        min-height: 60rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        display: none;
    }

    .mentordash_stats-main>h1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 3.5rem;
        min-width: 100%;
    }

    /* -------------------progres - bar----------------------  */
    .circular-progress-bar {
        width: 50rem;
        height: 50rem;
    }
}

@media screen and (max-width: 900px) {
    .mentordash__wrapper {
        padding: 0 2rem;
    }

    .mentordash__main-about {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 2rem 4rem 2rem 4rem;
        gap: .8rem;
    }

    /* ----------------------main about left-------------------- */
    .mentordash__main-about-left {
        flex-basis: 100%;
    }

    .mentordash__main-about-left-img {
        top: -11.5rem;
        width: 9rem;
        height: 9rem;
    }

    /* ----------------------main about right-------------------- */
    .mentordash__main-about-right {
        flex-basis: 100%;
    }

    .mentordash__main-about-right>div {
        min-height: 50%;
    }

    .metordash__up-s-slot {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        gap: 1.2rem;
        align-items: flex-start;
        border-top: 1px solid gray;
        padding-top: 1.2rem;
    }
}

@media screen and (max-width: 800px) {
    .metordash__up-s-time {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (max-width: 600px) {
    .mentordash__wrapper {
        padding: 0 .5rem;
    }

    .mentordash__section {
        min-height: 5rem;
        padding: 1.5rem 1.5rem;
        border-radius: 20px;
        display: flex;
        flex-flow: column nowrap;
        margin-top: 2rem;
    }

    .mentordash__main-about {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 2rem 1.5rem 2rem 1.5rem;
        gap: .8rem;
    }

    .mentordash__main-about>span {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1.5rem;
    }

    .metordash__up-s-btn {
        display: flex;
        flex-flow: row wrap;
        gap: 2rem;
        align-items: center;
    }


}