.editIntro__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 80%;
    height: 80%;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    overflow-y: scroll;
}


.editIntro__container::-webkit-scrollbar {
    margin-top: 1rem;
    width: .000007rem;
}

.editIntro__container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;

}

.editIntro__container::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

.editIntro__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    padding: 2rem 3rem;
}

.editIntro__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editIntro__main>h1>span {
    cursor: pointer;
}



.editInto__image {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.editInto__image>h1 {
    font-family: Poppins;
    color: rgba(255, 255, 255, 0.95);
    font-size: 1.8rem;
    font-weight: 500;
    padding: 2rem 3rem .6rem 3rem;
    margin-bottom: 1rem;

}

.editInto__image>div {
    width: 16rem;
    height: 16rem;
    border-radius: 15.75rem;
    border: 3px solid #FFF;
    box-shadow: 4px 4px 70px 1px rgba(0, 0, 0, 0.50);
    overflow: hidden;
}

.editInto__image>div>img {
    width: 100%;
    height: 100%;
}

.editInto__image>p {
    margin-top: .7rem;
    font-size: 15px;
    color: rgb(88, 88, 88);
}

.editIntro__avatar-upload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0 2rem 0;
}

.editIntro__avatar-input {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 3rem;
    width: 12rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
}

.editIntro__avatar-input>input {
    position: absolute;
    opacity: 0;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    cursor: pointer;
    background-color: white;
    border: 1px solid;
}

.editIntro__custom-dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-height: 5rem;
    border-radius: 0.7rem;
    outline: none;
    font-size: 1rem;
    margin: 0 0 2.19rem 0;
    background:
        linear-gradient(#000000 0 0) padding-box,
        linear-gradient(45deg, #3140C1, #A254EB) border-box;
    border: 1px solid transparent;
}

.editIntro__chips-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0.5rem 1rem;
}

.editIntro__chips-container>span {
    padding: 0.4rem .67rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #E5E3E3;
    opacity: .5;
}


.editIntro__main>div {
    display: flex;
    flex-flow: column nowrap;
}

.editIntro__formcol {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 100%;
}

.editIntro__formcol>div {
    width: 100%;
}

.editIntro__formcol>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
}

.editIntro__formcol>div>input {
    min-width: 100%;
    height: 3.5rem;
    outline: none;
    background-color: black;
    color: white;
    font-size: 1.2rem;
    padding: 0.6rem 1.7rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}

.editIntro__upload {
    min-width: 100%;
    min-height: 3.5rem;
    padding: 0.6rem 1.7rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
    background-color: black;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.editIntro__upload-img {
    position: relative;
    height: 2rem;
    width: 10rem;
    overflow: hidden;
    color: white;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    cursor: pointer;
    font-size: 1.1rem;
    z-index: 1;
}

.editIntro__upload-img>input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.editIntro__upload-viewimg {
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editIntro__upload-viewimg>img {
    max-height: 100%;
}

.editIntro__formcol>div>textarea {
    width: 100%;
    height: 6rem;
    outline: none;
    background-color: black;
    color: white;
    padding: 0.6rem 1.7rem;
    font-size: 1.2rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}


.editIntro__form-margin {
    margin-right: 5%;
}

.editIntro__save {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.editIntro__save>div {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 3.5rem;
    width: 8rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}

.editIntro__roles-main {
    display: flex;
    flex-flow: row nowrap;
}

.editIntro__roles {
    min-width: 50%;
    height: 3.5rem;
    outline: none;
    background-color: rgb(39, 38, 38);
    color: white;
    font-size: 1.2rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.editIntro__roles-main>span {
    color: white;
    font-size: 1.5rem;
    margin: 0.5rem 1rem;
}

.editIntro__roles.selected {
    background: linear-gradient(to right, #47388d, #9e506b) border-box;
}


@media screen and (max-width: 1000px) {
    .editIntro__container {
        border-radius: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
    }
}

@media screen and (max-width: 650px) {
    .editIntro__main {
        padding: 1rem 1.5rem;
    }

    .editIntro__formcol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-width: 100%;
    }

    .editIntro__form-margin {
        margin-right: 0;
    }

    .editIntro__roles {
        min-width: fit-content;
        flex: 1;
        height: 3.5rem;
        outline: none;
        background-color: rgb(39, 38, 38);
        color: white;
        font-size: 1.2rem;
        margin: 0;
        border-radius: 0.7rem;
        display: grid;
        place-items: center;
        cursor: pointer;
        margin-bottom: 1rem;
    }

    .editIntro__roles-main>span {
        color: white;
        font-size: 1.5rem;
        margin: 0.5rem 1rem;
    }
}