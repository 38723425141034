.profile__container {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(33, 28, 43), rgb(0, 0, 0));
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
}

.profile {
    min-width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

/* ------------------------Profile Loading------------------------ */
.profile__loading-main {
    border: 5px solid red;
    max-width: 100vw;
    min-height: 100vh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    overflow: hidden;
    background: #121212;
    border: 0;
}

.profile__loading-wrapper {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

.profile__loading-body {
    min-height: 100vh;
    max-width: 100vw;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
    overflow: hidden;
    padding: 2rem 8rem;
}

.profile__loading-body>div {
    display: flex;
    gap: 2rem;
    flex-flow: column nowrap;
}

@media screen and (max-width: 990px) {
    .profile__loading-body {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 600px) {
    .profile__loading-body {
        padding: 2rem 2rem;
    }
}