.srm__wrapper-main {
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
    overflow: visible;
    box-sizing: border-box;
    overflow: hidden;
    border: 0;
    color: white;
    background: linear-gradient(150deg, #AA61AA 13.05%, #AA61AA 13.05%, #7F00FF 62.29%);
}

.srm__wrapper {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    background: linear-gradient(150deg, #AA61AA 13.05%, #AA61AA 13.05%, #7F00FF 62.29%);
    overflow: hidden;
}

.srm__body {
    position: relative;
    max-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-flow: row nowrap;
}

/* ------------------------Left--------------------------- */
.srm__l {
    flex-basis: 35%;
    flex-grow: 1;
    padding: 13rem 0 10rem 5.13rem;
    width: 100%;
}

.srm__l-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.srm__l-content>h1 {
    position: relative;
    color: #FFF;
    font-family: Roboto;
    font-size: 4rem;
    font-weight: 800;
    margin-bottom: 3rem;
    line-height: 1.3;
    max-width: 30rem;
}

.srm__l-content>img {
    max-width: 100%;
}

/* ------------------------Right--------------------------- */
.srm__r {
    flex-basis: 75%;
    flex-shrink: 1;
    max-height: 100vh;
    overflow-y: scroll;
}

.srm__startup {
    max-height: 100vh;
    overflow-y: scroll;
    overflow: hidden;
}

@media screen and (max-width: 990px) {
    .srm__wrapper {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        background: linear-gradient(150deg, #7F00FF 13.05%, #AA61AA 13.05%, #AA61AA 62.29%);
        overflow: hidden;
    }

    /* ------------------------Right--------------------------- */
    .srm__l {
        display: none;
    }

    /* ------------------------Right--------------------------- */
    .srm__r {
        max-height: 100vh;
        min-width: 100vw;
        overflow-y: scroll;
    }
}