/* --------------Container-------------- */
.services__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    background:
        linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to bottom, #714FB4, #714FB4);
    padding-bottom: 8rem;
}

.services__container {
    color: white;
    z-index: 1;
    max-width: 200rem;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: visible;
}

.services__body {
    max-width: 200rem;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
    padding: 0;
}

/* --------------evets main-------------- */
.services {
    color: white;
    max-width: 100%;
    min-height: 100%;
}

/* -------------services Heading--------------- */
.services__head {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-height: 90vh;
    background-image: url(../../../public/assets/servcies-hero-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.services__head>div {
    min-width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.8rem;
    padding: 0 10vw;
}

.services__head>div>h1 {
    color: #EAD9FF;
    text-align: center;
    font-family: Montserrat;
    font-size: 5.6rem;
    font-weight: 800;
}

.services__head>div>p {
    color: var(--AI-Body-text-color, #A0A0A0);
    text-align: center;
    font-family: Montserrat;
    font-size: 1.8rem;
    font-weight: 400;
}

.services__head>div>button {
    display: flex;
    width: 28.7rem;
    padding: 1.4rem 1.6rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    background: var(--new-grad, linear-gradient(66deg, #3140C1 43.85%, #A254E8 99.8%));
    color: #EAD9FF;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
}

/* -------------services cards container--------------- */
.services__section {
    padding: 4.5rem 8rem;
    min-height: 1vh;
}

/* ----------------------services navbar------------------------ */
.services__nav {
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(13rem, 1fr));
    grid-auto-rows: 11rem;
    gap: 0.8rem;
    margin-bottom: 4.5rem;
}

.services__nav-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 2rem;
    gap: 1.2rem;
    font-size: 1.5rem;
    border-radius: 1.2rem;
    background: linear-gradient(48deg, #C0C0C0 -16.36%, #3A5FCD -16.36%, rgba(128, 0, 128, 0.40) 129.74%);
    box-shadow: -4px 4px 0px 0px rgba(0, 0, 0, 0.12);
}

.services__nav-item-special {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 2rem;
    gap: 1.2rem;
    border-radius: 1.2rem;
    background: linear-gradient(48deg, #C0C0C0 -16.36%, #3A5FCD -16.36%, rgba(128, 0, 128, 0.40) 129.74%);
    box-shadow: -4px 4px 0px 0px rgba(0, 0, 0, 0.12);
}

.services__nav-item-special>div {
    border: 1px solid red;

}

.services__nav-item>div {
    width: 2.8rem;
    height: 2.8rem;
}

.services__nav-item>div>img {}

.services__nav-item>div>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}

/* ----------------------services section------------------------ */
.services__content {
    min-width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 0 auto;
}

/* ----------------------services left------------------------ */
.services__l {
    display: flex;
    flex-flow: column nowrap;
    gap: 2.5rem;
}

.services__l>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 5rem;
    font-weight: 600;
}

.services__l>p {
    color: var(--AI-Body-text-color, #A0A0A0);
    text-align: justify;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
}

/* ----------------------services left scroll ------------------------ */
.services__l-btm {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}

.services__l-btm>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.services__l-btm-scroll {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.4rem;
}

.services__l-btm-scroll-item {
    display: flex;
    flex-flow: row nowrap;
    padding: 1.2rem 2.6rem;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
}

.services__l-btm-scroll-item.selected {
    background: linear-gradient(66deg, #3140C1 43.85%, #A254E8 99.8%);
}

.services__l-btm>p {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 600;
    background: var(--new-grad, linear-gradient(66deg, #3140C1 43.85%, #A254E8 99.8%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

/* ----------------------services Right------------------------ */
.services__r {
    background: linear-gradient(219deg, rgba(107, 107, 107, 0.00) -11.28%, rgba(0, 0, 0, 0.74) 55.22%);
}

/* ----------------------card------------------------ */
.services__card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    min-width: 100%;
    min-height: 23rem;
    background: #191919;
    padding: 2.5rem 8rem;
}

.services__card-l {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.services__card-l>p {
    color: #A0A0A0;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.services__card-r {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.services__card-r>span {
    width: 30rem;
}

.services__card-r>p {
    color: var(--AI-Body-text-color, #A0A0A0);
    text-align: justify;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 990px) {

    /* -------------services cards container--------------- */
    .services__section {
        padding: 4.5rem 4em;
    }

    /* ----------------------services section------------------------ */
    .services__content {
        display: grid;
        grid-template-columns: 1fr;
    }

    /* ----------------------services left------------------------ */
    .services__l {
        display: flex;
        flex-flow: column nowrap;
        gap: 2.5rem;
    }

    .services__l>h1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 5rem;
        font-weight: 600;
    }

    .services__l>p {
        color: var(--AI-Body-text-color, #A0A0A0);
        text-align: justify;
        font-family: Poppins;
        font-size: 1.6rem;
        font-weight: 400;
    }

    /* ----------------------services left scroll ------------------------ */
    .services__l-btm {
        display: flex;
        flex-flow: column nowrap;
        gap: 2rem;
    }

    .services__l-btm>h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    /* ----------------------services Right------------------------ */
    .services__r {
        display: none;
    }

    /* ----------------------card------------------------ */
    .services__card {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        gap: 5rem;
        padding: 2.5rem 4rem;
    }

    .services__card-l {
        flex-flow: row nowrap;
        gap: .5rem;
    }
}

@media (max-width: 1250px) {
    .services__nav {
        grid-template-columns: repeat(3, minmax(20rem, 1fr));
    }
}

@media (max-width: 800px) {
    .services__nav {
        grid-template-columns: repeat(2, minmax(18rem, 1fr));
    }
}

@media (max-width: 600px) {
    .services__head>div>h1 {
        font-size: 4rem;
    }

    .services__head>div>p {
        font-size: 1.6rem;
    }

    .services__head>div>button {
        width: 22rem;
        padding: 1.1rem 1.3rem;
        font-size: 1.6rem;
    }

    .services__l>h1 {
        font-size: 4rem;
    }

    .services__l-btm>h2 {
        font-size: 2.2rem;
    }

    
.services__card-r>span {
    display: none;
}

.services__card-r>p {
    font-size: 1.5rem;
}

}

@media (max-width: 500px) {
    .services__nav {
        grid-template-columns: repeat(1, minmax(18rem, 1fr));
    }

    .services__head>div>h1 {
        font-size: 3.4rem;
    }

    /* .services__head>div>button {
        width: 25rem;
        padding: 1.1rem 1.3rem;
        font-size: 1.6rem;
    } */
}