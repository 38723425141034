/* --------------------------------------- */
.new-nav {
    background-color: transparent;
    padding: 1.3rem 0;
    position: fixed;
    top: 0;
    bottom: auto;
    position: relative;
}

.w-nav {
    z-index: 20;
    background: #000000;
    position: relative;
}

/* -------------------------------------- */

.side-padders.relative {
    z-index: 2;
    position: relative;
}

.side-padders {
    /* width: 100%; */
    padding-left: 4.8rem;
    padding-right: 4.8rem;
}

/* --------------------------------------- */
.container-135-width {
    z-index: 1;
    max-width: 200rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3%;
    padding-right: 3%;
}

/* --------------------------------------- */
.nav-main-flex {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar__logo2 {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    position: relative;
    min-width: 10rem;
}

.nav__sidebar>i {
    display: none;
}

.image-100 {
    max-width: 17rem;
    display: inline-block;
    position: static;
    object-fit: fill;
}

/* --------------------------------------- */
.nav-menu-6 {
    flex: 1;
    position: static;
    padding: auto;

}

.nav-link-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.nav-links-wrap {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 2.5rem;
    color: white;
}

.nav-linking {
    padding: 1rem 0;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    vertical-align: top;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    display: inline-block;
    position: relative;
    white-space: nowrap;
}

.nav-linking>span {
    background-image: linear-gradient(to bottom, #6F57D8, #D56A8F);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.nav-linking-ai {
    height: fit-content;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .7rem;
}

.nav-linking-ai>div {
    height: 2.4rem;
    width: 2.4rem;
}

.nav-linking-ai>p {
    color: #FFF;
    color: color(display-p3 1 1 1);
    font-family: Poppins;
    font-size: 1.6rem;
}

.nav-linking-ai>p>span {
    background-image: linear-gradient(to bottom, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: Poppins;
    font-weight: 600;
}

/* --------------------------------------- */
.nav-button-wrap {
    user-select: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.1s ease-in-out;
    padding: .5rem 1rem;
    color: white;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: .8rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    white-space: nowrap;
}

.nav-button-wrap:hover {
    box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
}

.nav-button-wrap-mobile {
    user-select: none;
    display: none;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.1s ease-in-out;
    padding: .5rem 1rem;
    color: white;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: .8rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    white-space: nowrap;
}

.nav-button-wrap-mobile:hover {
    box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
}

.navbar2__avatar {
    border: 1px solid white;
    display: flex;
    align-items: center;
    border-radius: 4rem;
    max-width: 100%;
    padding: 0 1.7rem 0 0;
    height: 4rem;
}

.navbar2__avatar>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: .5rem;
}

/* ----------------Menu Item-Profile------------------- */
.nav__menu-profile-main {
    display: flex;
    flex-flow: column nowrap;
}

.nav__menu-profile {
    min-width: 15rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    color: white;
}

.nav__menu-profile>p {
    color: #DEDEDE;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.nav__menu-profile-border-btm {
    border-bottom: 1px solid gray;
}

/* --------------------------------------- */
.nav-linking.active {
    background-image: linear-gradient(to bottom, #6F57D8, #D56A8F);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.nav__sidebar-content {
    display: none;
}

.nav-mob__main {
    display: none;
}


@media screen and (max-width: 1200px) {
    .side-padders {
        padding: 0 2rem;
    }

    .nav__sidebar>i {
        color: white;
        font-size: 3rem;
        margin-right: 1rem;
        cursor: pointer;
        display: block;
    }

    /* --------------------------------------- */
    .nav-menu-6 {
        display: none;
    }

    .nav-linking {
        padding: 10px 0;
        color: #FFF;
        font-family: Poppins;
        font-size: 1.7rem;
        font-weight: 500;
        vertical-align: top;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        text-decoration: none;
        display: inline-block;
        position: relative;
        white-space: nowrap;
    }

    .nav-button-wrap {
        display: none;
    }

    .nav-button-wrap-mobile {
        display: flex;
    }

    .nav__sidebar-content {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-flow: column nowrap;
        background: linear-gradient(to bottom, #382c6b, #7c3e53);
        width: 100vw;
        height: 100vh;
        z-index: 10;
        transition: left .4s ease-in-out;
        padding: 2.4rem 5.4rem;
        align-items: center;
    }

    .nav__sidebar-content.closed {
        left: 100vw;
    }

    .nav__sidebar-cross {
        min-width: 100%;
        height: 5rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
    }

    .nav__sidebar-cross>i {
        color: white;
        font-size: 3rem;
        margin-right: 1rem;
        cursor: pointer;
        display: block;
    }

    .nav-linking {
        font-size: 2.3rem;
    }


    .nav-mob__main {
        display: flex;
        position: fixed;
        min-height: 7rem;
        width: 100vw;
        background: black;
        z-index: 20;
        bottom: 0;
        border-top: 1px solid gray;
    }

    .nav-mob__menu {
        background-color: black;
        color: #fff;
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
        min-width: 100%;
    }

    .nav-mob__list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
        padding: 0;
        min-width: 100%;
    }

    .nav-mob__item {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.60);
    }

    .nav-mob__item>div {
        width: 2.7rem;
        height: 2.7rem;
    }

    .nav-mob__item>div>img {
        min-width: 100%;
        height: auto;
    }

    .nav-mob__item-text {
        font-family: Poppins;
        font-size: 1.3rem;
        font-weight: 400;
        /* color: #fff; */
    }

    .nav-mob__item.selected {
        color: #fff;
    }

}

@media screen and (max-width: 600px) {
    .side-padders {
        padding-left: 0;
        padding-right: 0;
    }

    .container-135-width {
        z-index: 1;
        max-width: 200rem;
        margin-left: 0;
        margin-right: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .nav-linking {
        font-size: 4rem;
    }

    .navbar2__avatar {
        border: none;
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 0;
        height: 4rem;
    }

    .navbar2__avatar>h3 {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .nav-mob__item>div {
        width: 2.4rem;
        height: 2.4rem;
    }

    .nav-mob__item-text {
        font-size: 1.1rem;
    }
}