.bd__main {
  padding: 3rem 5rem;
  max-width: 100%;
  min-height: 100%;
}

.bd__progressBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.bd__back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  color: #FFF;
  cursor: pointer;
}

.bd__progressBar-img {
  height: auto;
  flex: 1;
}

.bd__progressBar-img>img {
  max-height: 100%;
  min-width: 100%;
}

/* --------------bd__form--------------- */
.bd__heading-main {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.8rem;
  margin: 1.5rem 0;
}

.imgTop {
  margin-left: 3rem;
}

/* --------------bd__form--------------- */
.bd__form-main {
  display: flex;
  flex-flow: column nowrap;
  gap: 2.5rem;
}

.bd__form {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  position: relative;
}

.bd__form>h1 {
  color: #FFF;
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: .4rem;
}

.bd__form>textarea {
  width: 100%;
  height: 4.6rem;
  border-radius: 0.9375rem;
  padding: 0.6rem 1.7rem;
  font-size: 1.4rem;
  width: 100%;
  height: 10rem;
  outline: none;
  background-color: transparent;
  color: white;
  border-radius: 1.2rem;
  border: 1px solid rgba(255, 255, 255, 0.32);
  resize: none;
}

.bd__form>input {
  width: 100%;
  height: 4.6rem;
  border-radius: 0.9375rem;
  padding: 0.6rem 1.7rem;
  margin-top: .5rem;
  font-size: 1.4rem;
  width: 100%;
  height: 4.6rem;
  border-radius: 1.2rem;
  outline: none;
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.32);
}

/* ---------------submit button----------------- */
.bd__submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bd__submit>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36rem;
  height: 5rem;
  flex-shrink: 0;
  border-radius: 9.1rem;
  background: #4F408B;
  cursor: pointer;
}

.disabledTrialBtn {
  opacity: .4;
  pointer-events: none;
  cursor: not-allowed;
}

.bd__submit>div>p {
  font-size: 2rem;
  font-weight: 700;
  margin-right: 1rem;
}

/* ---------------input chips----------------- */
.bd__chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
}

.bd__chip-container>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
}

.bd__chip.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.bd__chip {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 0.6rem 1.2rem;
  height: 2.7rem;
  border-radius: 20px;
  cursor: pointer;
  color: #e5e3e3;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 400;
  transition: background-color 0.5s;
}

.bd__chip.selected {
  background: linear-gradient(91deg, #BF5F80 0.63%, #6F57D8 100%);
}

.bd__chip:hover {
  background-color: #6f57d8;
  transition: background-color 0.5s;
}

.bd__close-icon {
  margin-left: 8px;
  cursor: pointer;
}

.bd__area-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* border: 1px solid white; */
  margin-bottom: 1rem;
}

.bd__area-list>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
}

/* -------------------Buttons-------------------- */

.bd__form-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 4rem;
}

.bd__form-btn>div {
  min-width: fit-content;
  height: 4rem;
  border-radius: 0.9375rem;
  background: linear-gradient(180deg, #7557d2 0%, #b363a5 100%);
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
}


.bd__glassBg {
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: linear-gradient(117deg, rgba(255, 255, 255, 0.19) 11.68%, rgba(255, 255, 255, 0.10) 77.34%);
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .bd__main {
    padding: 2rem 1.5rem;
  }

  .bd__submit>div {
    width: 25rem;
    height: 4rem;
  }

  .bd__submit>div>p {
    font-size: 1.5rem;
  }
}