@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&family=REM&family=Roboto&family=Ropa+Sans&display=swap");

/* main css */
.home__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    background-color: black;
    padding-bottom: 8rem;
    overflow: hidden;
}

.home__container {
    background-color: black;
    color: white;
    z-index: 1;
    max-width: 200rem;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: visible;
}

.home__body {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
}

.home {
    background: #000;
    min-height: 100%;
    max-width: 100%;
    padding: 1.8rem 20rem 7.5rem 20rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.home__top {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    max-width:100rem;
}

.home__top>p {
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
}

.home__top_head {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
}

.home__top_head>h1 {
    color: #fff;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 4.5rem;
    font-weight: 700;
    position: relative;
    text-align: 'center';
}

.home__top_head>h1>span {
    background-image: linear-gradient(to bottom, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: "REM", sans-serif;
    text-align: 'center';
}

.home__inputBox {
    flex: 1;
    min-width: 100%;
        height: 7rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, .09);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    overflow: hidden;
}

.home__inputBox>input {
    flex: 1;
    width: 100%;
    outline: none;
    font-size: 1.7rem;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 1.2rem;
    background: transparent;
    transition: 1s all ease;
}

.home__inputBox>input:hover {
    border: 1px solid 6f57d8;
}

.home__inputBox>a {
    --clr-font-main: hsla(0 0% 20% / 100);
    --btn-bg-1: hsla(194 100% 69% / 1);
    --btn-bg-2: hsla(217 100% 56% / 1);
    --btn-bg-color: hsla(360 100% 100% / 1);
    --radii: 0.5em;
    cursor: pointer;
    padding: 0.9em 1.4em;
    min-width: 120px;
    min-height: 4.4rem;
    font-size: var(--size, 1.8rem);
    font-family: "Segoe UI", system-ui, sans-serif;
    font-weight: 700;
    transition: 0.8s;
    background-size: 280% auto;
    background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
    border: none;
    border-radius: var(--radii);
    color: var(--btn-bg-color);
    // box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
    display: grid;
    place-items: center;
}

.home__inputBox>a:hover {
    background-position: right top;
}

.home__inputBox>a:is(:focus, :focus-visible, :active) {
    outline: none;
    box-shadow: 0 0 0 3px var(--btn-bg-color), 0 0 0 6px var(--btn-bg-2);
}

@media (prefers-reduced-motion: reduce) {
    .home__inputBox>button {
        transition: linear;
    }
}


.home__inputBox>button:hover {
    box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
    transition: box-shadow 0.3s ease-in-out;
}

.home__searchTerms {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1.3rem;
    max-width: 80rem;
}

.home__searchTerms>div {
    user-select: none;
    padding: .3rem .6rem;
    font-size: 1.2rem;
    border-radius: 2rem;
    transition: all 1s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    color: white;
    cursor: pointer;
    background: rgba(255, 255, 255, .09);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
}

.home__searchTerms>div:hover {
    box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
    transition: box-shadow 0.3s ease-in-out;
}

/* ----------- card ---------- */
.home__card {
    max-width: 100%;
    min-height: 50.6rem;
    background: #000;
    padding: 0rem 20rem;
    color: white;
}

.home__card-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 1.1rem 0 0 0;
}

.home__card-head>h1 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 3.6rem;
    font-weight: 500;
}

.home__card-head>img {
    max-height: 7rem;
}

.home__cardMain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 4rem;
    margin-top: 5.8rem;
    justify-content: center;
}

#freeCallBtn {
    font-size: 180%;
}

@media screen and (max-width: 1700px) {
    .home {
        padding: 1.8rem 15rem 7.5rem 15rem;
    }

    .home__card {
        padding: 0rem 15rem;
    }
}

@media screen and (max-width: 1500px) {
    .home {
        padding: 1.8rem 10rem 7.5rem 10rem;
    }

    .home__card {
        padding: 0rem 10rem;
    }
}

@media screen and (max-width: 1400px) {
    .home {
        padding: 1.8rem 8rem 7.5rem 8rem;
    }

    .home__card {
        padding: 0rem 8rem;
    }
}

@media screen and (max-width: 1230px) {
    .home__left-head>h1 {
        font-size: 8.8rem;
    }

    .home__left-head>h1>p {
        left: 20rem;
    }

    .home__left-bottom>h1 {
        margin-right: 1rem;
        font-size: 2.6rem;
    }

    .home__left-bottom-call {
        max-width: 22rem;
    }

    .home__left-bottom-call>img {
        height: 2rem;
        margin-right: 1rem;
    }

    .home__left-bottom-call>p {
        font-size: 1.8rem;
    }

    /* ----------------- Right --------------- */
    .home__right>div {
        max-width: 48rem;
        max-height: 30rem;
        padding: 3.5rem;
    }

    /* ------------Hero buttons ----------------- */

    .home__social-discord {
        gap: 1.2rem;
    }

    .home__social-discord>p {
        font-size: 1.8rem;
    }

    .home__social-freeMentor>p {
        font-size: 1.8rem;
    }

    .home__social-mentor>p {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 992px) {
    .home {
        padding: 1.8rem 4rem 7.5rem 4rem;
    }

    .home__card {
        padding: 0rem 4rem;
    }

    .home__container-main {
        padding-bottom: 8rem;
    }

    /*-------------------left-----------------------*/
    .home__left-head>h1 {
        font-size: 11rem;
    }

    /* ----------------- Right --------------- */
    .home__right {
        display: none;
    }

    /* ------------Bottom ----------------- */

    .home__social {
        flex-flow: column nowrap;
    }

    .home__social-discord {
        min-width: 100%;
    }

    .home__social-discord>img {
        width: 4rem;
        height: 4rem;
    }

    .home__social-freeMentor {
        min-width: 100%;
        max-height: 6.4rem;
        padding: 0.7rem 2.1rem;
        gap: .7rem;
    }

    .home__social-freeMentortext {
        display: none;
    }

    .home__card-head>h1 {
        font-size: 2.8rem;
    }


    .home__top{
        padding-top:5rem;
      }
      .home__top_head {
        margin: 2rem 0;
      }
    
      .home__top > p {
        font-size: 1.5rem;
      }
      .home__top_head > h1 {
        font-size: 3.5rem;
      }
}

@media screen and (max-width: 600px) {
    .home {
        padding: 1.8rem 2rem 7.5rem 2rem;
    }

    .home__card {
        padding: 0rem 2rem;
    }

    .home__container-main {
        padding-bottom: 4rem;
    }

    /*-------------------left-----------------------*/
    .home__left-box {
        flex-basis: 100%;
        margin-top: 2rem;
    }

    .home__left-head>h1 {
        font-size: 8rem;
    }

    .home__left-head>h1>p {
        left: 17rem;
        bottom: 0rem;
        font-size: 1.2rem;
    }

    .home__top{
        padding-top:3rem;
      }
      .home__top_head {
        margin: 1.6rem 0;
      }
    
      .home__top > p {
        font-size: 1.4rem;
      }
      .home__top_head > h1 {
        font-size: 2.8rem;
      }
      .home__inputBox {
        height: 5.5rem;
        flex-flow: column nowrap;
        justify-content: center;
      }
}

@media screen and (max-width: 450px) {

    .home__left-bottom {
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: column nowrap;
        margin-top: 1.2rem;
        gap: 1rem;
    }

    .home__left-bottom>h1 {
        margin-right: 1.6rem;
        font-size: 4rem;
    }
}

@media screen and (max-width: 365px) {
    .home__left-head>h1 {
        font-size: 6.5rem;
    }

    .home__left-head>h1>p {
        left: 10rem;
        bottom: -.4rem;
    }
}







.Data {
    min-height: 100%;
    min-width: 100%;
}

.hero {
    display: inline-block;
    width: 100%;
    min-height: 100%;
    position: relative;
}

.diagonal-hero-bg {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    min-height: 100%;
    background: black;
    z-index: -1;
}

// creates randomized star sizes
@function stars($n) {
    $value: '#{random(2000)}px #{random(2000)}px #767676';

    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(2000)}px #767676';
    }

    @return unquote($value);
}

// store random stars
$stars-small: stars(700);
$stars-medium: stars(200);
$stars-big: stars(100);


.stars {
    z-index: -1;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;


    min-height: 100%;
    transition: opacity 1s ease-in-out;
}

.stars>.small {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $stars-small;
    animation: starsAnimation 50s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $stars-small;
    }
}

.stars>.medium {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $stars-medium;
    animation: starsAnimation 100s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $stars-medium;
    }
}

.stars>.big {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $stars-big;
    border-radius: 100%;
    animation: starsAnimation 150s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $stars-big;
        border-radius: 100%;
    }
}

// swap from/to values to reverse animation
@keyframes starsAnimation {
    from {
        transform: translateY(-2000px);
    }

    to {
        transform: translateY(0px);
    }
}