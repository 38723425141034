.toast__custom-alert {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #444;
    color: #fff;
    padding: 10px;
    width: 100%;
    text-align: center;
    display: none;
    transition: top 0.5s ease-in-out;
  }
  
  .toast__custom-alert.show {
    top: 40px;
  }
  
  .toast__message {
    font-size: 18px;
  }
/*   
  body {
    background-color: #333;
    color: #fff;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
   */