.mc__main {
    display: grid;
    grid-template-columns: 29rem 2fr 35rem;
    grid-template-rows: 1fr;
    min-height: 40rem;
    max-width: 128rem;
    padding: 2.8rem;
    gap: 2rem;
    border-radius: 2rem;
    /* background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%)); */
    /* background: linear-gradient(180deg, rgba(113, 79, 180, 0.60) 0%, rgba(28, 17, 32, 0.60) 100%); */
    background: linear-gradient(145deg, #2c2356, #582638);


    overflow: hidden;
}

/* ========================Left========================*/
.mc__l {
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
}

/* -------------------------Left image----------------------------*/
.mc__l-img {
    width: 100%;
    height: 27rem;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mc__l-img>img {
    min-width: 100%;
    min-height: 100%;
}

/* -------------------------Left company----------------------------*/
.mc__l-com-main {
    display: flex;
    flex-flow: column nowrap;
    margin-top: .4rem;
}

/* -------------------------Left company----------------------------*/
.mc__l-com {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;
}

.mc__l-com-img {
    max-width: 3.5rem;
    max-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D9D9D9;
    border-radius: .4rem;
    overflow: hidden;
}

.mc__l-com-img>img {
    min-width: 3.5rem;
    min-height: 3.5rem;
}

.mc__l-com-text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
}

.mc__l-com-text>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
}

.mc__l-com-text>p {
    color: #C1C1C1;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 500;
}

/* ========================Mid===========================*/
.mc__m {
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 1.6rem;
}

/* -------------------------Mid Bio------------------------- */
.mc__m-bio {
    display: flex;
    flex-flow: column nowrap;
}

.mc__m-bio>h1 {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 2.2rem;
    font-weight: 600;
}

.mc__m-bio>p {
    color: #D2D2D2;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.6rem;
}

.mc__m-bio>p>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.6rem;
    cursor: pointer;
}

.mc__m-bio>a>p {
    color: #D2D2D2;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.6rem;
}

.mc__m-bio>a>p>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2.6rem;
    cursor: pointer;
}

/* -------------------------Mid skill------------------------- */
.mc__m-skill {
    display: flex;
    flex-flow: column nowrap;
    gap: .8rem;
}

.mc__m-skill-h {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
}

.mc__m-skill-h-logo {
    height: 1.6rem;
    width: 1.6rem;
}

.mc__m-skill-h>h2 {
    color: #C1C1C1;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.6rem;
}

.mc__m-skill-container {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: .5rem;
}

.mc__m-skill-container>div {
    display: flex;
    padding: 0.4rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: rgba(46, 0, 76, 0.507);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.mc__m-skill-container-menu {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: .5rem;
}

.mc__m-skill-container-menu>div {
    display: flex;
    padding: 0.4rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: rgba(91, 14, 142, 0.507);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.mc__m-skill-container>span {
    color: #D2D2D2;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration-line: underline;
    margin-left: .4rem;
    cursor: pointer;
}

/* -------------------------Mid area------------------------- */
.mc__m-area-main {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;
}

.mc__m-area {
    display: flex;
    flex-flow: column nowrap;
    gap: .3rem;
}

.mc__m-area-h {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
}

.mc__m-area-h>div {
    height: 1.6rem;
    width: 1.6rem;
}

.mc__m-area-h>h2 {
    color: #C1C1C1;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.6rem;
    margin-bottom: .3rem;
}

.mc__m-area-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
}

/* ========================Right========================*/
.mc__r {
    min-height: 100%;
}

.mc__r-body {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
}

.mc__r-top {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 12rem;
    /* border: 1px solid saddlebrown; */
}

.mc__r-top>div {
    display: flex;
    flex-flow: row nowrap;
    gap: 3rem;
}


/* -------------------------Styling area chat/sessions etc------------------------- */
.mc__r-area-main {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.6rem;
}

.mc__r-area {
    display: flex;
    flex-flow: column nowrap;
    gap: .3rem;
}

.mc__r-area-h {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
}

.mc__r-area-h>div {
    height: 1.6rem;
    width: 1.6rem;
}

.mc__r-area-h>h2 {
    color: #C1C1C1;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
}

.mc__r-area-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
}



.mc__r-btm {
    flex-basis: 23%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 3rem;
}

.mc__r-btm>h1 {
    color: #FFF;
    font-family: Roboto;
    font-size: 3.6rem;
    font-weight: 800;
    line-height: 4rem;
}

.mc__viewprofile {
    display: flex;
    width: 100%;
    padding: 0.5rem 2.9rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: 1px solid white;
    border-radius: 0.625rem;
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 4rem;
}

.mc__viewprofile:hover {
    /* color: black; */
    background: linear-gradient(111deg, #6F57D8 14.06%, #D56A8F 93.75%);
}

.mc__booksession {
    display: flex;
    width: 100%;
    padding: 0.4rem 2.3rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    background: #FFF;
    cursor: pointer;
    color: #A560AE;
    border: 1px solid transparent;
    transition: backgound-color 3s;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 4rem;
    white-space: nowrap;
}

.mc__booksession:hover {
    color: white;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 0.625rem;
    transition: backgound-color 3s;
}





/* =======================Loading=========================== */
.mc__main-loading {
    display: grid;
    grid-template-columns: 26% 2fr 30%;
    grid-template-rows: 1fr;
    min-height: 50rem;
    min-width: 128rem;
    max-width: 128rem;
    padding: 2.8rem;
    gap: 2rem;
    border-radius: 2rem;
    overflow: hidden;
    background: #121212;
}

.mc__l-loading {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.mc__m-loading {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.mc__r-loading {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}


.mc__about-menu-main {
    max-height: 35rem;
    max-width: 50rem;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
}

.mc__about-menu {
    max-height: 100%;
    min-width: 100%;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    padding: 1.5rem;
}

.mc__about-menu>p {
    font-size: 1.4rem;
    font-weight: 100;
    white-space: wrap;
    /* color: rgb(40, 194, 176); */
    /* color: #7350B8; */
    /* color: rgb(249, 140, 178); */
    color: white;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.mc__about-menu>code {
    font-size: 1.4rem;
    white-space: wrap;
    color: white;
    overflow-wrap: break-word;
    word-wrap: break-word;
}


@media screen and (max-width: 700px) {
    .mc__main {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        max-width: 100%;
        padding: 1.6rem;
        gap: 1rem;
        border-radius: 4rem;
    }

    /* ========================Left========================*/
    .mc__l-com-main {
        display: flex;
        flex-flow: column nowrap;
        margin-top: .4rem;
    }

    .mc__l {
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0;
        gap: 2rem;
    }

    /* -------------------------Left image----------------------------*/
    .mc__l-img {
        width: 10rem;
        height: 10rem;
        border-radius: 50rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* -------------------------Mid Bio------------------------- */
    .mc__m-bio {
        flex-flow: column nowrap;
    }

    /* -------------------------Mid area------------------------- */
    .mc__m-skill {
        display: none;
    }

    /* ========================Right========================*/
    .mc__r {
        min-height: 100%;
    }

    .mc__r-body {
        min-height: 0;
        flex-flow: column nowrap;
        gap: 2rem;
        justify-content: space-between;
    }

    .mc__r-top {
        justify-content: space-between;
    }

    .mc__r-top>div {
        justify-content: space-between;
    }

    .mc__r-btm {
        gap: 1.3rem;
    }

    .mc__r-btm>h1 {
        font-size: 2.6rem;
    }

    /* =======================Loading=========================== */
    .mc__main-loading {
        display: grid;
        grid-template-rows: 26% 2fr 30%;
        grid-template-columns: 1fr;
        min-height: 50rem;
        min-width: 100%;
        max-width: 128rem;
        padding: 2.8rem;
        gap: 2rem;
        border-radius: 2rem;
        overflow: hidden;
        background: #121212;
    }

    .mc__l-loading {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    .mc__m-loading {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }

    .mc__r-loading {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
    }
}

@media screen and (max-width: 430px) {
    .mc__main {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        max-width: 100%;
        padding: 1.6rem;
        gap: 1rem;
        border-radius: 4rem;
    }

    /* ========================Left========================*/
    .mc__l-com-main {
        display: flex;
        flex-flow: column nowrap;
        margin-top: .4rem;
    }

    .mc__l {
        gap: 1rem;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    /* -------------------------Left image----------------------------*/
    .mc__l-img {
        width: 12rem;
        height: 12rem;
    }

    .mc__l-com {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
    }

    .mc__l-com-img {
        width: 3.5rem;
        height: 3.5rem;
    }

    .mc__l-com-img>img {
        width: 3.1rem;
        height: 3.1rem;
    }

    .mc__l-com-text>h3 {
        font-size: 1.5rem;
    }

    .mc__l-com-text>p {
        font-size: 1.4rem;
    }

    /* -------------------------Mid Bio------------------------- */
    .mc__m-bio {
        flex-flow: column nowrap;
    }

    /* -------------------------Mid area------------------------- */
    .mc__m-skill {
        display: none;
    }

    /* ========================Right========================*/
    .mc__r {
        min-height: 100%;
    }

    .mc__r-body {
        min-width: 100%;
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        gap: 1.5rem;
    }

    .mc__r-top {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: fit-content;
        gap: 1rem;
    }

    .mc__r-top>div {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
    }

    .mc__r-btm {
        gap: 1rem;
    }

    .mc__r-btm>h1 {
        font-size: 2.6rem;
    }
}