.card__main {
    flex-basis: 35rem;
    flex-grow: 1;
    max-height: 100%;
    border-radius: 3rem;
    background: linear-gradient(180deg, #6956DE 66.67%, #302C4B 100%);
    display: flex;
    flex-flow: column nowrap;
    padding: 2.6rem;
    gap: 2rem;
}

.card__number {
    width: 5rem;
    height: 5rem;
    background-color: #FFF;
    color: black;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: .2rem .3rem rgba(0, 0, 0, 0.30);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
}

.card__content {
    color: white;
    font-size: 2.4rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}


.card__bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.card__button {
    min-width: fit-content;
    height: 5.1rem;
    border-radius: 3rem;
    background-color: #FFF;
    color: black;
    box-shadow: -.6rem .6rem 2rem 0 rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 1.4rem 2.7rem;
}

.card__button>p {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: 1.5rem;
}

.card__button>img {
    width: 1.79rem;
}


@media screen and (max-width: 600px) {
    .card__number {
        font-size: 2.4rem;
    }

    .card__content {
        font-size: 2rem;
    }
  
    .card__button>p {
        font-size: 1.4rem;
    }

    .card__button>img {
        width: 1.4rem;
    }
}