  .calender {
    background: rgba(0, 0, 0, 0.70);
    border-radius: 2rem;
    padding: 2rem;
  }

  /* Meetings List */
  .calendar__meetings-list {
    margin-top: 0.5rem;
  }

  .calendar__meetings-list p {
    margin: 0;
  }

  /* No Meetings Message */
  .calendar__meetings-list p:first-child {
    color: #777;
  }


  .ct__time-container {
    display: flex;
    flex-flow: column nowrap;
    gap: .8rem;
    height: 24.5rem;
    overflow-y: scroll;
    padding: 0 1rem;
    margin-top: 1rem;
  }

  .ct__time-container::-webkit-scrollbar {
    width: .000001rem;
  }

  /* ----------------meet component---------------- */
  .ct__meeting {
    font-size: 1.4rem;
    padding: .5rem 1rem;
    border-radius: .5rem;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    width: 16rem;
  }

  .ct__meeting:hover {
    background-color: rgb(241, 241, 241);
    color: black;
  }

  .ct__meeting.selectedtime, .ct__selectedDate {
    /* background-color: rgb(238, 143, 143); */
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    color: white;
  }





