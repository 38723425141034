  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&family=REM&family=Roboto&family=Ropa+Sans&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

  /* font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'REM', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Ropa Sans', sans-serif; */
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  html {
    font-size: 62.5%;
    height: -webkit-fill-available;
  }

  body {
    pointer-events: fill;
    height: -webkit-fill-available;
  }


  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }


::-webkit-scrollbar {
  width: 1rem; 
}

::-webkit-scrollbar-track {
  background: #838383; 
}

::-webkit-scrollbar-thumb {
  background: #525252;
  border-radius: 5px; 
}
* {
  scrollbar-base-color: #f1f1f1;
  scrollbar-face-color: #888;
  scrollbar-track-color: #f1f1f1;
  scrollbar-highlight-color: #ccc;
  scrollbar-shadow-color: #ddd;
  scrollbar-arrow-color: #000;
}

/* * {
  scrollbar-color: #888 #f1f1f1;
} */

