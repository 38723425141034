/* ---------------Profile roadmap---------------- */
.profile__start-mentorship-main {
    max-width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 3rem 3rem;
    padding: 3rem 8rem;
}

.profile__start-mentorship {
    max-width: 135rem;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
}

.profile__start-mentorship>h1 {
    color: #000;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
}

.profile__start-mentorship-body {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 1.2rem;
}

.profile__start-mentorship-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.2rem;
}

.profile__start-mentorship-left-card {
    min-width: 30rem;
    min-height: 22.2rem;
    border-radius: 1.2rem;
    background: #0E0E0E;
    position: relative;
    overflow: hidden;
    padding: 1.5rem;
}

.profile__start-mentorship-left-card-float {
    position: absolute;
    top: 0;
    right: 0;
    width: 16rem;
    height: 3rem;
    flex-shrink: 0;
    border-radius: 0rem 1.2rem;
    background: #80CC28;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 600;
}

.profile__start-mentorship-left-card-img {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 100%;
    background-color: #7350B8;
    display: grid;
    place-items: center;
}

.profile__start-mentorship-left-card>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    margin: 1rem 0 .5rem 0;
}

.profile__start-mentorship-left-card>p {
    color: #EEE;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .04rem;
}

.profile__start-mentorship-right {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1.2rem;
    max-width: 100%;
    max-height: 100%;
}

.profile__start-mentorship-right-book {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: relative;
    min-width: 30rem;
    min-height: 100%;
    border-radius: 1.2rem;
    background-color: #7350B8;
    padding: 1.6rem;
    gap: .5rem;
}

.profile__start-mentorship-right-book>div>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.6rem;
    font-weight: 600;
}

.profile__start-mentorship-right-book-price {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
}

.profile__start-mentorship-right-book-price>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 600;
}

.profile__start-mentorship-right-book-price>h1>span {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-weight: 600;
}

.profile__start-mentorship-right-book-price>img {
    max-width: 70%;
    margin-left: .4rem;
}

.profile__start-mentorship-right-book-btn {
    position: relative;
    bottom: 1px;
    min-width: 100%;
    height: 4rem;
    border-radius: 5rem;
    background: #FFF;
    color: rgba(5, 5, 5, 0.80);
    padding: 0rem 1.6rem;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.profile__start-mentorship-right-book-btn>img {
    max-width: 1.9rem;
    margin-left: 1rem;
}

.profile__start-mentorship-right-trial {
    min-width: 100%;
    min-height: 100%;
    border-radius: 1.2rem;
    background: #000;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 1.6rem;
}

.profile__start-mentorship-right-trial>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.profile__start-mentorship-right-trial>h1 {
    color: #7757D0;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
}

.profile__start-mentorship-right-trial>h1>i {
    margin-left: 1rem;
}

@media screen and (max-width: 1100px) {
    .profile__start-mentorship-main {
        padding: 4rem;
    }
}

@media screen and (max-width: 992px) {
    .profile__start-mentorship-body {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 1.2rem;
    }

    .profile__start-mentorship-left {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1.2rem;
    }

    .profile__start-mentorship-right {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

}

@media screen and (max-width: 700px) {
    .profile__start-mentorship-body {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }

    .profile__start-mentorship>h1 {
        font-size: 2.6rem;
    }

    .profile__start-mentorship-left {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }


    .profile__start-mentorship-right {
        flex-basis: 30%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .profile__start-mentorship-main {
        padding: 3rem 2rem;
    }
}






/* =================================================================================== */
/* =================================================================================== */
/* ------------------network--------------------- */
.profile__network-main {
    padding: 3rem 8rem;
    max-width: 100%;
    min-height: 1vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile__network {
    max-width: 135rem;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.profile__network>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.profile__network>p {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 5rem;
}

.profile__network-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1rem;
}

.profile__network-card {
    min-height: 23.1rem;
    border-radius: 1.2rem;
    background: #0E0E0E;
    padding: 1.5rem;
}

.profile__network-card>div {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    display: grid;
    place-items: center;
}

.profile__network-card-div-br-purple {
    background-color: #7350B8;
}

.profile__network-card-div-bg {
    background-color: #80CC28;
}

.profile__network-card>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: .7rem;
    margin-top: 1rem;
}

.profile__network-card>p {
    color: #EEE;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.9rem;
}

.profile__network-bg-white {
    background: #5F5D5D;
    height: 15rem;
    min-width: 100%;
}


@media screen and (max-width: 992px) {
    .profile__network-main {
        padding: 3rem 4rem;
    }

    .profile__network>h1 {
        font-size: 2.5rem;
    }

    .profile__network>p {
        font-size: 1.6rem;
    }

    .profile__network-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 750px) {
    .profile__network>h1 {
        font-size: 2.5rem;
    }

    .profile__network>p {
        font-size: 1.6rem;
    }

    .profile__network-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .profile__network-main {
        padding: 3rem 2rem;
    }
}

/* =================================================================================== */
/* =================================================================================== */
/* ------------------More mentors-------------- */
.profile__moreMentor {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 8rem;
    max-width: 135rem;
    padding: 0 8rem;
}

.profile__moreMentor>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.profile__moreMentor-container {
    min-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 1.2rem;
    overflow-x: scroll;
    padding-bottom: .5rem;
}

.profile__moreMentor-container::-webkit-scrollbar {
    width: .5rem;
}

.profile__moreMentor-container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;

}

.profile__moreMentor-container::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

/* ----------------card------------------ */
.profile__moreMentor-card {
    height: 43rem;
    min-width: 32rem;
    max-width: 32rem;
    border-radius: 1.5rem;
    background: #F6F1F1;
    padding: 2rem;
    color: black;
}

.profile__moreMentor-card-img {
    width: 8.9rem;
    height: 9.423rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 70px 1px rgba(0, 0, 0, 0.50);
    overflow: hidden;
    margin-bottom: 1rem;
}

.profile__moreMentor-card-img>img {
    max-width: 100%;
}

.profile__moreMentor-card>h1 {
    color: #000;
    font-family: Poppins;
    font-size: 2.7rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.profile__moreMentor-card-company {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.profile__moreMentor-card-company-img {
    max-width: 4rem;
    max-height: 4rem;
    margin-right: 1rem;
}

.profile__moreMentor-card-company-img {
    max-width: 100%;
}

.profile__moreMentor-card-company-img-text {
    display: flex;
    flex-flow: column nowrap;
}

.profile__moreMentor-card-company-text>h2 {
    color: #000;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.profile__moreMentor-card-company-text>h1 {
    color: #000;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 600;
}

.profile__moreMentor-card-skills {
    display: flex;
    flex-flow: row wrap;
    gap: .5rem;
    margin-top: 1rem;
}

.profile__moreMentor-card-skills>div {
    width: fit-content;
    padding: .5rem 1rem;
    border-radius: .6rem;
    background: #000;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.profile__moreMentor-card-bottom {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: -3rem;
}

.profile__moreMentor-card-bottom>h1 {
    color: rgba(0, 0, 0, 0.80);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;

}

.profile__moreMentor-card-bottom>h1>span {
    color: #000;
    font-family: Poppins;
    font-size: 2.5rem;
    font-weight: 500;
}

.profile__moreMentor-card-bottom>a>div {
    width: 11.3rem;
    height: 4.0233rem;
    border-radius: 1rem;
    cursor: pointer;
    user-select: none;
    background: linear-gradient(#F6F1F1 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 0.625rem;
    color: #000;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    display: grid;
    place-items: center;
}


/* =================================================================================== */
/* =================================================================================== */










/*--------------------Events--------------------- */
.profile__events-main {
    min-width: 100%;
    min-height: 1vh;
    padding: 0 8rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border: 5px solid darkblue;

}

.profile__events-body {
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 135rem;
    gap: 1.75rem;
}

.profile__events-body>h1 {
    max-width: 135rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.profile__events-container {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.5rem;
    min-width: 100%;
    overflow-x: scroll;
    padding-bottom: .5rem;
}

.profile__events-container::-webkit-scrollbar {
    width: .5rem;
}

.profile__events-container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}

.profile__events-container::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}