/* --------------Container-------------- */
.event__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    background:
        linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to bottom, #714FB4, #714FB4);
    padding-bottom: 8rem;
}

.event__container {
    color: white;
    z-index: 1;
    max-width: 200rem;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: visible;
}

.event__body {
    max-width: 200rem;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
    padding: 0;
}

/* --------------evets main-------------- */
.events {
    color: white;
    max-width: 100%;
    min-height: 100%;
}

/* -------------Events Heading--------------- */
.events__head {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-height: 68rem;
    background: black;
}

.events__head>div {
    min-width: 100%;
    height: 40rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
}

.events__head>div::before {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-image: url(../../../public/assets/events-head-bg.svg);
    background-repeat: no-repeat;
    content: '';
    background-position: center;
}

.events__head>div>h1 {
    color: #FFF;
    text-align: center;
    text-shadow: 5px 5px 15px rgba(255, 255, 255, 0.25);
    font-family: Raleway;
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 7.2rem;
    font-family: Poppins;
    white-space: nowrap;
    margin-bottom: 5rem;
}

.events__head>div>p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 6.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 7.2rem;
    font-family: Poppins;
}

.events__create{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 1.9rem;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 0.625rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.875rem;
    margin-top: 1rem;
    cursor: pointer;
    z-index: 1;
}

/* -------------Events cards container--------------- */
.events__content {
    padding: 1.4rem 8rem;
}

.events__content>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 7.2rem;
    text-align: start;
    margin-top: 2.5rem;
}

.events__card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
    grid-gap: 5rem;
    justify-content: center;
    margin-top: 58px;
    margin-bottom: 4rem;
}

@media (max-width: 1440px) {
    .events__card-container {
        grid-template-columns: repeat(auto-fill, minmax(37rem, 1fr));
        grid-gap: 2rem;
    }
}

@media (max-width: 970px) {
    .events__content {
        padding: 1.4rem 6rem;
    }

    .events__card-container {
        grid-gap: 1.4rem;
    }

    .events__content>h1 {
        text-align: center;
    }

    .events__head>div::before {
        width: 90%;
        min-height: 100%;
        background-size: 70%;

    }

    .events__head>div>h1 {
        font-size: 4.4rem;
    }

    .events__head>div>p {
        font-size: 4.4rem;
    }

    .events__card-container {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        place-items: center;
    }
}

@media screen and (max-width: 600px) {
    .events__content {
        padding: 1.4rem 1rem;
    }

    .events__head>div::before {
        width: 90%;
        min-height: 100%;
        background-size: 80%;

    }

    .events__head>div>h1 {
        font-size: 4.4rem;
    }

    .events__head>div>p {
        font-size: 4.4rem;
    }
}

/* @media screen and (max-width: 360px) {
    .events__content {
        padding: 1.4rem 1rem;
    }
} */