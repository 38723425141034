.cp__main {
    display: flex;
    flex-flow: column nowrap;
    background: black;
    border-radius: 1rem;
    gap: .8rem;
    padding: 1rem 1.2rem;
    min-width: 100%;
}

.cp__top {
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    height: 4rem;
}

.cp__top-div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 1.5rem;
    gap: 1rem;
    background: white;
    border-radius: 5rem;
    flex: 1;
    font-size: 1.3rem;
    color: gray;
    cursor: pointer;
}

.cp__btm {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
    height: 4rem;
}

.cp__btm>div {
    flex-basis: 50%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
    font-size: 1.3rem;
    border-radius: 1rem;
    background: black;
    cursor: pointer;
    transition: background .1s ease-in-out;
}

.cp__btm>div:hover {
    transition: background .1s ease-in-out;
    background: #121212;
}







.ql-toolbar.ql-snow {
    color: white;
    padding: .5rem 1rem;
    margin: 0;
    border-radius: 1rem;
    border: 1px solid rgba(203, 203, 203, 0.15);
}

.ql-formats {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
}

.ql-header svg {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
    color: white;
}


/* Editor.css */

.custom-editor {
    height: 100%;
    width: 100%;
    color: white;
}

.custom-editor .ql-toolbar {
    background-color: #212121;
}

.custom-editor .ql-toolbar button {
    color: #ffffff;
}

.custom-editor .ql-editor {
    background-color: #212121;
    color: white;
}

.custom-editor .ql-editor::selection {
    background: #90caf9;
}


.custom-editor .ql-toolbar button {
    font-size: 54px;
}


.ql-container.ql-snow {
    border: none;
}
.ql-editor {
    border-radius: 1rem;
    border: 1px solid rgba(203, 203, 203, 0.15);
    margin-top: 1rem;
}