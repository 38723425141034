.booking_confirm_body {
  padding: 6rem 10rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle at center,
      #6e4fc9 16%,
      #000);
  overflow-y: scroll;
}

.booking_confirm_body::-webkit-scrollbar {
  width: 0.001px;
}

.main_details {
  display: flex;
  flex-flow: column nowrap;
  gap: 5rem;
  min-width: 100%;
}

.calender_icon {
  display: block;
  min-width: 100%;
}

.booking_heading {
  display: flex;
  flex-flow: column nowrap;
}

.booking_heading>div {
  display: flex;
  flex-flow: row nowrap;
  gap: 4rem;
}

.booking_heading>div>h3 {
  color: #ffffff;
  font-family: Poppins;
  font-size: 3.4rem;
  font-weight: 700;
}

.booking_heading>div>img {
  padding: 0.4rem;
  margin-left: 0.4rem;
  width: 7rem;
  height: 7rem;
}

.booking_heading>p {
  color: #a0a0a0;
  font-family: Poppins;
  font-size: 1.8rem;
  font-weight: 500;
}


/* booking details */
.booking_details {
  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;
}

.booking_details>div {
  display: flex;
  flex-flow: row nowrap;
  gap: 3rem;
}

.booking_details>div>h2 {
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 700;
  min-width: 6.5rem;
}

.booking_details>div>p {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
}

.booking_email_details {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.booking_email_details>p {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
}

.booking_email_details_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking_email_details_name>p {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 500;
}

.mentor_box_mentor {
  border-radius: 0.8rem;
  background: #560695;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 3rem;
}

.mentee_box_mentee {
  border-radius: 0.8rem;
  background: #069568;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 3rem;
}

.make_changes_cta {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

.make_changes_cta span {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-size: 1.5rem;
  font-weight: 500;
}


@media screen and (max-width: 1024px) {
  .booking_confirm_body {
    padding: 6rem 6rem;
    grid-template-columns: 1fr;
  }

  .calender_icon {
    display: none;
  }

  .booking_heading>div>h3 {
    font-size: 2.6em;
  }

  .booking_heading>div>img {
    width: 5.5rem;
    height: 5.5rem;
  }

  .booking_heading>p {
    font-size: 1.6rem;
  }


  /* booking details */
  .booking_details {
    display: flex;
    flex-flow: column nowrap;
    gap: 3rem;
  }
  
  .booking_details>div {
    display: flex;
    flex-flow: row nowrap;
    gap: 3rem;
  }
  
  .booking_details>div>h2 {
    font-size: 1.8rem;
  }

  .booking_details>div>p {
    font-size: 1.5rem;
  }


  .booking_email_details>p {
    font-size: 1.5rem;
  }

  .booking_email_details_name>p {
    font-size: 1.5rem;
  }

  .make_changes_cta {
    font-size: 1.5rem;
  }

  .make_changes_cta span {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .booking_confirm_body {
    padding: 2rem;
  }
  
  .booking_heading>div>h3 {
    font-size: 2.6em;
  }

  .booking_heading>div>img {
    width: 5.5rem;
    height: 5.5rem;
  }

  .booking_heading>p {
    font-size: 1.6rem;
  }


  /* booking details */
  .booking_details {
    flex-flow: column nowrap;
    gap: 2.4rem;
  }
  
  .booking_details>div {
    flex-flow: row nowrap;
    gap: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .booking_confirm_body {
    padding: 2rem;
  }
  
  .booking_heading>div>h3 {
    font-size: 2.6em;
  }

  .booking_heading>div>img {
    width: 5.5rem;
    height: 5.5rem;
  }

  .booking_heading>p {
    font-size: 1.6rem;
  }


  /* booking details */
  .booking_details {
    flex-flow: column nowrap;
    gap: 1.8rem;
  }
  
  .booking_details>div {
    flex-flow: column nowrap;
    gap: .2rem;
  }

  .booking_details>div>h2 {
    font-size: 1.6rem;
  }

  .booking_details>div>p {
    font-size: 1.4rem;
  }


  .booking_email_details>p {
    font-size: 1.2rem;
  }

  .booking_email_details_name>p {
    font-size: 1.2rem;
  }

  .make_changes_cta {
    font-size: 1.5rem;
  }

  .make_changes_cta span {
    font-size: 1.5rem;
  }
}




/* Loading screen css styles */
.bookingLoad_confirm_body {
  padding: 6rem 10rem;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle at center,
      #6e4fc9 16%,
      #000);
}

.mainLoad_details {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mainLoad_details>h3 {
  color: #FFF;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  margin-top: 1rem;
  text-align: center;
}

.mainLoad_details>p {
  color: #A0A0A0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3rem;
  text-align: center;
}

.bookingLoad__tick {
  width: 64px;
  height: 64px;
}

.bookingLoad__namaste {
  width: 30rem;
  height: 30rem;
}

@media screen and (max-width: 900px) {
  .bookingLoad_confirm_body {
    padding: 2rem 3rem;
  }

  .mainLoad_details>h3 {
    font-size: 2.4rem;
  }

  .mainLoad_details>p {
    font-size: 1.4rem;
    margin-bottom: 2.8rem;
  }

  .bookingLoad__tick {
    width: 5rem;
    height: 5rem;
  }

  .bookingLoad__namaste {
    width: 27rem;
    height: 27rem;
  }
}

@media screen and (max-width: 500px) {
  .bookingLoad_confirm_body {
    padding: 2rem;
  }

  .mainLoad_details>h3 {
    font-size: 2rem;
  }

  .mainLoad_details>p {
    font-size: 1.4rem;
    margin-bottom: 2.8rem;
  }

  .bookingLoad__tick {
    width: 5rem;
    height: 5rem;
  }

  .bookingLoad__namaste {
    width: 26rem;
    height: 26rem;
  }
}

@media screen and (max-width: 320px) {
  .mainLoad_details>h3 {
    font-size: 1.8rem;
  }

  .mainLoad_details>p {
    font-size: 1.2rem;
    margin-bottom: 2.4rem;
  }

  .bookingLoad__namaste {
    width: 24rem;
    height: 24rem;
  }
}