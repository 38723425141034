.login__wrapper-main {
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    background-color: #383737;
    overflow: hidden;
    border: 0;
}

.login__wrapper {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    background-color: black;
    overflow: hidden;
}

.login__body {
    position: relative;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-flow: row nowrap;
}

.login__back {
    position: absolute;
    left: 4rem;
    top: 3rem;
    display: inline-flex;
    padding: 0.4rem 1.6rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 0.8rem;
    border: 1px solid #FFF;
    color: #FFF;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.015rem;
    cursor: pointer;
}

/*----------------login left------------------*/

.login__left {
    flex-basis: 50%;
    background: url(../../../public/assets/login-left-bg.svg);
    background-repeat: none;
    background-size: cover;
    padding: 10rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__left>div {
    position: relative;
    height: 100%;
    width: 40rem;
    border: 1px solid white;
    border-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 6rem;
    overflow: hidden;
}

.login__left>div::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(154deg, rgba(255, 255, 255, 0.38) 0%, rgba(255, 255, 255, 0.10) 100%);
    opacity: 0.4;
    backdrop-filter: blur(20px);
}

.login__left>div>p {
    color: #FFF;
    font-family: Poppins;
    z-index: 1;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.036rem;
}

/* --------------------login right--------------------- */

.login__right {
    flex-basis: 50%;
    background: linear-gradient(180deg, rgba(111, 79, 176, 0.6) 0%, rgba(60, 43, 94, 0.6) 50%, rgba(26, 17, 29, 0.6) 100%);
    padding: 7rem 4rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

.login__right>div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}

/* --------------------login logo--------------------- */
.login__logo {
    height: 6rem;
}

.login__logo>img {
    max-height: 80%;
}

.login__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.login__content>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: 0.028rem;
    margin-bottom: 3.5rem;
}

.login__content>p {
    color: rgba(255, 255, 255, 0.35);
    text-align: center;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.012rem;
    width: 28rem;
}

.login__content>p>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.012rem;
}

.login__content>line {
    border: 1px solid rgba(255, 255, 255, 0.24);
    color: rgba(255, 255, 255, 0.24);
    width: 100%;
    margin: 2rem 0 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    height: 1px;
}

.login__content>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.login__content>div>span {
    color: rgba(255, 255, 255, 0.35);
    text-align: center;
    font-family: Poppins;
    font-size: 1.6rem;
    padding-top: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.012rem;
}

.login__roles {
    padding: .4rem 2.5rem;
    color: white;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: .8rem;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.016rem;
    margin-top: 1.2rem;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
}

.login__roles.selected {
    background: linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid white;
}

/* -------------------Google button-------------------- */
.login__google-button {
    position: relative;
    user-select: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: none;
    padding: .4rem 3.4rem;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
    color: white;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: .8rem;
}

.login__google-button:hover,
.login__content>div>div:hover {
    box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
}

.login__google-logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.login__google-logo>img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.login__google-button-text {
    display: inline-block;
}

#googlePop-up {
    position: absolute;
    opacity: .00000001;
    left: 2rem;
    top: 0;
    z-index: 1;
}




@media screen and (max-width: 840px) {
    .login__wrapper-main {
        max-width: 100vw;
        max-height: 100vh;
        position: relative;
        overflow: visible;
        box-sizing: border-box;
        color: white;
        background-color: #383737;
        overflow: hidden;
        border: 0;
    }

    .login__wrapper {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        background-color: black;
        overflow: hidden;
    }

    .login__body {
        position: relative;
        min-height: 100vh;
        max-width: 100vw;
        display: flex;
        flex-flow: row nowrap;
    }

    .login__back {
        position: absolute;
        left: 4rem;
        top: 3rem;
        display: inline-flex;
        padding: 0.4rem 1.6rem;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border-radius: 0.8rem;
        border: 1px solid #FFF;
        color: #FFF;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.015rem;
        cursor: pointer;
    }

    /*----------------login left------------------*/

    .login__left {
        display: none;
    }

    /* --------------------login right--------------------- */

    .login__right {
        flex-basis: 100%;
    }

    .login__right>div {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    /* --------------------login logo--------------------- */
    .login__logo {
        height: 6rem;
    }

    .login__logo>img {
        max-height: 80%;
    }

    .login__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    .login__content>h1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 2.8rem;
        font-weight: 500;
        letter-spacing: 0.028rem;
        margin-bottom: 3.5rem;
    }

    .login__content>p {
        color: rgba(255, 255, 255, 0.35);
        text-align: center;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.012rem;
        width: 28rem;
    }

    .login__content>p>span {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.012rem;
    }

    .login__content>line {
        border: 1px solid rgba(255, 255, 255, 0.24);
        color: rgba(255, 255, 255, 0.24);
        width: 100%;
        margin: 2rem 0 1rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.24);
        height: 1px;
    }

    .login__content>div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .login__content>div>span {
        color: rgba(255, 255, 255, 0.35);
        text-align: center;
        font-family: Poppins;
        font-size: 1.6rem;
        padding-top: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.012rem;
    }

    .login__roles {
        padding: .4rem 2.5rem;
        color: white;
        background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
        border: 1px solid transparent;
        border-radius: .8rem;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.016rem;
        margin-top: 1.2rem;
        transition: box-shadow 0.3s ease-in-out;
        cursor: pointer;
        user-select: none;
    }

    .login__roles.selected {
        background: linear-gradient(to top, #6F57D8, #D56A8F) border-box;
        border: 1px solid white;
    }

    /* -------------------Google button-------------------- */
    .login__google-button {
        position: relative;
        user-select: none;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border: none;
        padding: .4rem 3.4rem;
        width: 100%;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease-in-out;
        overflow: hidden;
        color: white;
        background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
        border: 1px solid transparent;
        border-radius: .8rem;
    }

    .login__google-button:hover,
    .login__content>div>div:hover {
        box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
    }

    .login__google-logo {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .login__google-logo>img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .login__google-button-text {
        display: inline-block;
    }

    #googlePop-up {
        position: absolute;
        opacity: .00000001;
        left: 2rem;
        top: 0;
        z-index: 1;
    }



}

@media screen and (max-width: 600px) {
    .login__back {
        left: 1.3rem;
        top: 1.3rem;
    }
}