.homecycle__main {
    padding: 0 20rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.t-section {
    padding-top: 10rem;
    padding-bottom: 10rem;
    overflow: hidden;
    display: block;
    position: relative;
    background-color: black;
    color: white;
    min-width: 100%;
}

.margin-bottom-64.side {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    color: white;
}

.skill-h3-max {
    max-width: 72rem;
}

.t-h2-heading {
    letter-spacing: -.04em;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 120%;
    color: #FFF;
    font-family: Poppins;
    font-size: 3.6rem;
    font-weight: 500;
}

.homecycle__headBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem 2rem;
    background:
        linear-gradient(#000000 0 0) padding-box,
        linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 2px solid transparent;
    border-radius: 1.2rem;
    cursor: pointer;
}

.homecycle__headBtn:hover {
    background: linear-gradient(111deg, #6F57D8 14.06%, #D56A8F 93.75%);
    border: 2px solid #D56A8F;
}

.homecycle__headBtn>p {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
}

.hiw-image {
    margin-top: -6rem;
    margin-bottom: 6rem;
    display: flex;
    max-width: 135rem;
    padding: 0 3.6rem;
}

.image-100--rel {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    object-fit: fill;
    display: block;
}


@media screen and (max-width: 1700px) {
    .homecycle__main {
        padding: 0 15rem;
    }
}

@media screen and (max-width: 1500px) {
    .homecycle__main {
        padding: 0 10rem;
    }
}

@media screen and (max-width: 1400px) {
    .homecycle__main {
        padding: 0 8rem;
    }
}

@media screen and (max-width: 992px) {
    .homecycle__main {
        padding: 0 4rem;
    }

    .t-h2-heading {
        font-size: 2.8rem;
    }

    .margin-bottom-64.side {
        flex-flow: row wrap;
        padding: 0;
        gap: 1.2rem;
    }

    .hiw-image {
        margin: -6rem 4rem 6rem 4rem;
        display: flex;
        align-items: center;
        max-width: 100vw;
        padding: 0rem;
        overflow-x: scroll;
    }

    .hiw-image::-webkit-scrollbar {
        width: 2px;
    }

    .hiw-image::-webkit-scrollbar-thumb {
        border-radius: 2.1875rem;
        background: rgba(255, 255, 255, 0.40);
    }

    .hiw-image::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    .image-100--rel {
        min-width: 135rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        object-fit: fill;
        display: block;
    }
}