.profile__fadq-main {
    min-width: 100%;
    min-height: 1vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile__faq {
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    max-width: 135rem;
    min-height: 19.6rem;
    gap: 1.75rem;
    padding: 4rem 8rem;
}

.profile__faq-body {
    max-width: 135rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 3rem;
}

.profile__faq-body-head {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
}

.profile__faq-body-head>h3 {
    display: grid;
    place-items: center;
    width: 18rem;
    height: 6rem;
    flex-shrink: 0;
    border-radius: 7.9rem;
    font-size: 3rem;
    font-weight: 600;
    background: #383838;
}

.profile__faq-body-head-ques {
    border: 2px solid white;
    border-radius: 2rem;
    padding: 4rem 5rem;
}

.profile__question {
    min-height: 5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0rem 1.5rem;
    background: #FFF;
    border-radius: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    user-select: none;
}

.profile__question h4 {
    text-align: center;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 2rem 4rem;
    background-image: linear-gradient(to bottom, #6F57D8, #D56A8F);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.profile__question p {
    color: green;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.profile__question div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile__question-ans {
    min-height: 2rem;
    border-radius: 1rem;
    background: rgb(27, 27, 27);
    color: #fff;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 400;
    margin-top: -.5rem;
    margin-bottom: 2rem;
    padding: 2rem 4rem;
}

.profile__question-ans.closed {
    display: none;
}

@media screen and (max-width: 1100px) {
    .profile__faq {
        padding: 4rem;
    }

    .profile__faq-body-head-ques {
        padding: 2rem 3rem;
    }

}

@media screen and (max-width: 992px) {
    .profile__faq-body {
        max-width: 135rem;
        display: flex;
        flex-flow: column nowrap;
        gap: 3rem;
    }

    .profile__faq-body-head>h3 {
        width: 15rem;
        height: 5rem;
        font-size: 2.5rem;
    }

    .profile__question h4 {
        font-size: 1.6rem;
        padding: 1rem 3rem;
    }

    .profile__question-ans {
        font-size: 1.6rem;
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 600px) {
    .profile__faq {
        padding: 3rem 2rem;
    }

    .profile__faq-body {
        gap: 2rem;
    }

    .profile__faq-body-head-ques {
        border: none;
        border-radius: 2rem;
        padding: 0;
    }

    .profile__faq-body-head>h3 {
        width: 13rem;
        height: 4.3rem;
        font-size: 2rem;
    }

    .profile__question h4 {
        font-size: 1.5rem;
        padding: 1rem 1.5rem;
    }

    .profile__question-ans {
        font-size: 1.5rem;
        padding: 1rem 1.5rem;
    }
}