.deleteChatModal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 4px;
    border-radius: 2rem;
    /* background: linear-gradient(347deg, #000 0%, #18151C 100%); */
    background: rgba(232, 232, 227);
    height: 20rem;
    width: 40rem;
}

.deleteChatModal__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 100%;
    padding: 2rem 4rem;
}

.deleteChatModal__main>h1 {
    color: #121830;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deleteChatModal__main>h1>span {
    cursor: pointer;
}


.deleteChatModal__save {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 2rem;
}

.deleteChatModal__chatBg>div {
    background: #e3e3dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.deleteChatModal__mentorBg>div {
    background: linear-gradient(112deg, #7C58C9 12.37%, #BD5E81 65.76%);
}

.deleteChatModal__save>div {
    display: grid;
    place-content: center;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    color: #121830;
    font-weight: 600;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}