.qcard__main {
    min-width: 100%;
    position: relative;
    display: flex;
    max-height: 100%;
    padding: 2.4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 2.1rem;
    border-radius: 2.1rem;
    background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
}

/* ---------------question name-----------------  */
.qcard__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 100%;
}

.qcard__name>h1 {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    /* line-height: 4rem; */
    margin-left: 1rem;
}

.qcard__ques {
    display: flex;
    width: 100%;
    padding: 0rem 0.7rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.12);
}

.qcard__ques>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    line-height: 4rem;
}

.qcard__ques::-webkit-scrollbar {
    width: .0000007rem;
}

.qcard__ques::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}

.qcard__ques::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

/* -----------------top answer------------------ */
.qcard__ans {}

.qcard__ans>h3 {
    color: rgba(255, 255, 255, 0.50);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
}

.qcard__ans>p {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
}

.qcard__ans>p>span {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
    cursor: pointer;
}

/* -----------------profile and other------------------ */
.qcard__profile-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}

.qcard__profile-main>h2 {
    color: rgba(255, 255, 255, 0.50);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
}

.qcard__profile {
    display: flex;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
}

.qcard__profile-view {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: .5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
    padding: 1rem 0;
}

.qcard__profile-view>div {
    /* border: 1px solid red; */
}

.qcard__profile-view>div>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
}

.qcard__profile-view>div>div {
    display: flex;
    flex-flow: row nowrap;
    gap: .6rem;
    align-items: center;
}

.qcard__profile-view>div>div>div>img {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
}

.qcard__profile-view>div>div>div>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.032rem;
}

.qcard__profile-view>div>div>div>p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.qcard__profile>h1 {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 1rem 0;
}

.qcard__profile-ans {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
}

/* -----------------reply------------------ */
.qcard__reply-main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}

.qcard__reply-btn {
    display: flex;
    width: 32.6rem;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    border-radius: 1rem;
    background: #FFF;
    cursor: pointer;
}

.qcard__reply-btn>p {
    font-size: 1.6rem;
    font-weight: 600;
    background: var(--colour, linear-gradient(180deg, #7057D7 0%, #DC6B89 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.qcard__reply {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 4.2rem;
    gap: 1.5rem;
}

.qcard__reply-like {
    cursor: pointer;
    margin-right: 1.8rem;
    display: flex;
    align-items: center;
}

.qcard__reply-like>span {
    margin-left: .4rem;
    font-size: 1.3rem;
}

.qcard__reply-dislike {
    cursor: pointer;
    margin-right: 2rem;
    display: flex;
    align-items: center;
}

.qcard__reply-dislike>span {
    margin-left: .4rem;
    font-size: 1.3rem;
}

.qcard__reply-ans {
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: .4rem;
}

/* --------------share buttons---------------- */
.qcard__reply-share {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}

.qcard__reply-share-items {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .4rem .7rem;
}

/* -----------------bottom------------------ */
.qcard__btm {
    /* border: 1px solid red; */
    width: 100%;
    min-height: 0rem;
}

.qcard__btm>p {
    color: #6F57D8;
    font-size: 1.1rem;
}

.qcard__hideComment {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem 1rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
    gap: .3rem;
}

.qcard__hideComment>p {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 500;
}

.qcard__comment-container {
    max-height: 100%;
    width: 100%;
    overflow-y: scroll;
    transition: max-height .2s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    margin: 2rem 0;
}

.qcard__comment-container.closed {
    transition: max-height .2s ease-in-out;
    max-height: 0rem;
}

.qcard__comment-container::-webkit-scrollbar {
    width: .000007rem;
}


@media screen and (max-width: 700px) {

    /* ---------------question name-----------------  */
    .qcard__name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        min-width: 100%;
    }

    .qcard__ques>p {
        font-size: 2rem;
    }

    /* -----------------top answer------------------ */
    .qcard__ans>h3 {
        font-size: 1.6rem;
    }

    .qcard__ans>p {
        font-size: 1.6rem;
    }

    .qcard__ans>p>span {
        font-size: 1.6rem;
    }

    /* -----------------profile and other------------------ */
    .qcard__profile-main {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: flex-start;
        gap: 1.4rem;
    }

    .qcard__profile-main>h2 {
        font-size: 1.6rem;
    }

    .qcard__profile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        border-radius: 1.2rem;
        background: rgba(255, 255, 255, 0.15);
        min-width: 100%;
    }

    .qcard__profile-view {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* -----------------reply------------------ */
    .qcard__reply-main {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: flex-start;
        gap: 1rem;
        justify-content: space-between;
        min-width: 100%;
    }
}

@media screen and (max-width: 450px) {

    /* ---------------question name-----------------  */
    .qcard__name {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        min-width: 100%;
    }

    .qcard__ques>p {
        font-size: 2rem;
    }

    /* -----------------top answer------------------ */
    .qcard__ans>h3 {
        font-size: 1.6rem;
    }

    .qcard__ans>p {
        font-size: 1.6rem;
    }

    .qcard__ans>p>span {
        font-size: 1.6rem;
    }

    /* -----------------profile and other------------------ */
    .qcard__profile-main {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: flex-start;
        gap: 1.4rem;
    }

    .qcard__profile-main>h2 {
        font-size: 1.6rem;
    }

    .qcard__profile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        border-radius: 1.2rem;
        background: rgba(255, 255, 255, 0.15);
        min-width: 100%;
    }

    .qcard__profile-view {
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* -----------------reply------------------ */
    .qcard__reply-main {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: flex-start;
        gap: 1rem;
        justify-content: space-between;
        min-width: 100%;
    }

    .qcard__reply-btn {
        display: flex;
        width: 100%;
        padding: 0.8rem 1.2rem;
        justify-content: center;
        align-items: center;
        gap: 1.6rem;
        border-radius: 1rem;
        background: #FFF;
        cursor: pointer;
    }

    .qcard__reply-btn>p {
        font-size: 1.6rem;
        font-weight: 600;
        background: var(--colour, linear-gradient(180deg, #7057D7 0%, #DC6B89 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}