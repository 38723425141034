.answers__main {
    position: relative;
    display: flex;
    min-height: fit-content;
    padding: 2.4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 2.1rem;
    border-radius: 2.1rem;
    background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
}

/* ---------------question name-----------------  */
.answers__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 100%;
}

.answers__name>h1 {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 4rem;
    margin-left: 1rem;
}

.answers__ques {
    display: flex;
    width: 100%;
    padding: 1rem 0.7rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.12);
}

.answers__ques>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 4rem;
}

.answers__ques::-webkit-scrollbar {
    width: .0000007rem;
}

.answers__ques::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}

.answers__ques::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

/* -----------------top answer------------------ */
.answers__ans {}

.answers__ans>h3 {
    color: rgba(255, 255, 255, 0.50);
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
}

.answers__ans>p,
.answers__ans>code {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
    font-family: 'Poppins', sans-serif;
}

.answers__ans>p>span, .answers__ans>code>span {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
    cursor: pointer;
}

/* -----------------profile and other------------------ */
.answers__profile-main {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem 1rem;
    gap: 1.2rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
}

.answers__profile-main>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.75);
}

.answers__profile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-between;
    align-items: center;
    gap: 0.4rem;
    width: 100%;
}

.answers__profile-view {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: .4rem;
    padding: 1rem 0;
    min-width: 100%;
}

.answers__profile-view {
    width: 50%;
}

.answers__profile-view>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 400;
}

.answers__profile-bio>p>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
}

.answers__profile-view>div {
    display: flex;
    flex-flow: column nowrap;
    gap: .1rem;
}

.answers__profile-view>div>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
}

.answers__profile-view>div>div {
    display: flex;
    flex-flow: row nowrap;
    gap: .4rem;
    align-items: center;
}

.answers__profile-view>div>div>div>img {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
}

.answers__profile-view>div>div>div>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.032rem;
}

.answers__profile-view>div>div>div>p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
}

.answers__profile>h1 {
    color: #FFF;
    font-size: 2rem;
    font-weight: 700;
    padding: 1rem 0;
}

.answers__profile-ans {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
}

/* -----------------reply------------------ */
.answers__reply-main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}

.answers__reply-btn {
    display: flex;
    width: 20rem;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    border-radius: 1rem;
    background: #FFF;
    cursor: pointer;
}

.answers__reply-btn>p {
    font-size: 1.6rem;
    font-weight: 600;
    background: var(--colour, linear-gradient(180deg, #7057D7 0%, #DC6B89 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.answers__reply {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 4.2rem;
    gap: 1.5rem;
}

.answers__reply-like {
    cursor: pointer;
    margin-right: 1.8rem;
    display: flex;
    align-items: center;
}

.answers__reply-like>span {
    margin-left: .4rem;
    font-size: 1.3rem;
}

.answers__reply-dislike {
    cursor: pointer;
    margin-right: 2rem;
    display: flex;
    align-items: center;
}

.answers__reply-dislike>span {
    margin-left: .4rem;
    font-size: 1.3rem;
}

.answers__reply-ans {
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: .4rem;
}


/* -----------------bottom------------------ */
.answers__btm {
    width: 100%;
    min-height: 0rem;
}

.answers__btm>p {
    color: #6F57D8;
    font-size: 1.1rem;
}

.answers__hideComment {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 0.8rem 1rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.15);
    gap: .3rem;

}

.answers__hideComment>p {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 500;
}

.answers__comment-container {
    max-height: 100%;
    width: 100%;
    overflow-y: scroll;
    transition: max-height .10ms ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0;
}

.answers__comment-container.closed {
    transition: max-height .10ms ease-in-out;
    max-height: 0rem;
    margin: 0;
}

.answers__comment-container::-webkit-scrollbar {
    width: .000007rem;
}

@media screen and (max-width: 700px) {

    /* -----------------profile and other------------------ */
    .answers__profile-main {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.2rem 1rem;
        gap: 1.2rem;
        border-radius: 1.2rem;
        background: rgba(255, 255, 255, 0.15);
    }

    .answers__profile-main>h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.6rem;
        font-weight: 700;
        width: 100%;
        text-align: center;
        padding-top: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.75);
    }

    .answers__profile {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        justify-content: space-between;
        align-items: center;
        gap: 0.4rem;
        width: 100%;
    }
}

@media screen and (max-width: 520px) {

    /* -----------------reply------------------ */
    .answers__reply-main {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        min-width: 100%;
    }
}