.footer2__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    background: #0E0E0E;
    ;
}

.footer2__container {
    min-width: 100%;
    min-height: 43rem;
    position: relative;
}

.footer2__body {
    min-height: 43rem;
    max-width: 135rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
}

.footer2__top {
    padding: 0 8rem;
    min-height: 10rem;
    min-width: 100%;
    display: flex;
    flex-flow: column nowrap;
}


/* -----------footer links---------- */
.footer2__top-linking {
    min-height: 20rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.footer2__top-links-logo {
    width: 19rem;
}

.footer2__top-links-logo>img {
    max-width: 100%;
}

.footer2__top-link {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.5rem;
}

.footer2__top-link>div {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}

.footer2__top-link>div>a {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 500;
}


/* -----------footer Social---------- */

.footer2__top-social {
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10rem;
}

.footer2__top-social>a{
    margin-left: 2rem;
}

.footer2__top-social>a>div {
    display: flex;
    padding: 2rem;
    border-radius: 100%;
    background: #000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


/* -----------footer bottom---------- */
.footer2__bottom {
    padding: 0 8rem;
    margin-top: auto;
    height: 8rem;
    min-width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.70);
}

.footer2__bottom>h1 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 400;
}

.footer2__bottom h1 span {
    margin-right: 2rem;
}