.ccard__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .2rem;
    min-width: 100%;
    min-height: 1rem;
    background: transparent;
}

/* ---------------question name-----------------  */
.ccard__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 100%;
    gap: 1.6rem;
}

.ccard__name>p {
    display: flex;
    padding: 0rem 0.6rem;
    justify-content: center;
    align-items: center;
    border-radius: 2.1rem;
    background: rgba(255, 255, 255, 0.15);
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    /* font-weight: 600; */
    /* text-decoration-line: underline; */
}

/* ---------------question ans-----------------  */
.ccard__ans {
    padding-left: 5rem;
}

.ccard__ans>p {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
}

/* -----------------ccard__reply----------------- */
.ccard__reply {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
    min-width: 100%;
}

.ccard_reply-input {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 0.2rem;

}

.ccard_reply-input>input {
    background: transparent;
    outline: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.60);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 3rem;
    color: rgba(255, 255, 255, 0.661);
    font-size: 1.6rem;
    font-weight: 400;
}

.ccard_reply-input>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;
}

.ccard_reply-input>div>span {
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;
}