.slider__sliding {
    background: #000;
}

.slider__slide__main {
    width: 100%;
    min-height: 37.5rem;
    background: #0B0B0B;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0rem 20rem;
    border-radius: 3rem 3rem 0px 0px;
}

.slider__slide__main>h1 {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 3.6rem;
    font-weight: 600;
    margin: 4.9rem 0;
}

.slider__slide__company {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 5.7rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 13.7rem;
    background:
        linear-gradient(#000000 0 0) padding-box,
        linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 3px solid transparent;
    border-radius: 8rem;
}

.slider__slide-logos {
    background-color: black;
}

@keyframes slider__sliding {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.slider__slide-logos-main {
    overflow: hidden;
}

.slider__slide-logos {
    position: relative;
    display: flex;
    width: 280rem;
}

.slider__slide-logos:before,
.slider__slide-logos:after {
    position: absolute;
    top: 0;
    width: 20rem;
    height: 100%;
    content: '';
    z-index: 2;
}

.slider__slide-logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
}

.slider__slide-logos::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
}

.slider__slide-logos:hover {
    animation-play-state: paused;
}


.slider__slide-logos-slide {
    position: relative;
    display: flex;
    width: 120rem;
    animation: 11s slider__sliding infinite linear;
}

.slider__slide-logos-slide img {
    height: 5rem;
    margin: 0 2rem;
}

.slider__slide__company:last-child {
    margin-top: 3rem;
}


/* mentors__stats  */
.mentors__statsMain {
    max-width: 100%;
    min-height: 18.6rem;
    background: #EAEAEA;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.mentors__stats {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 9rem;
}

.mentors__stats>img {
    max-width: 8rem;
    max-height: 8rem;
    margin-right: 2.7rem;
}

.mentors__stats>div>h1 {
    color: #000;
    font-size: 4.8rem;
    font-weight: 600;
    /* font-family: 'Poppins', sans-serif; */
    line-height: 5rem;
}

.mentors__stats>div>p {
    color: #000;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    /* font-family: 'Poppins', sans-serif; */
    margin-right: 10rem;
}

@media screen and (max-width: 1700px) {
    .slider__slide__main {
        padding: 0rem 15rem;
    }
}

@media screen and (max-width: 1500px) {
    .slider__slide__main {
        padding: 0rem 10rem;
    }
}

@media screen and (max-width: 1400px) {
    .slider__slide__main {
        padding: 0rem 8rem;
    }
}

@media screen and (max-width: 992px) {
    .slider__slide__main {
        padding: 0rem 4rem;
    }

    .slide__main {
        padding: 0rem;
        border-radius: 3rem 3rem 0px 0px;
    }

    .slider__slide__main>h1 {
        font-size: 2.8rem;
        margin: 4.9rem 0;
        padding: 0 2rem;
    }

    .slider__slide__main {
        padding: 0;
    }

    .slider__slide__company {
        padding: 0;
        align-items: center;
        border: transparent;
        width: 100%;
        border-radius: 0;
    }

    /* mentors__stats  */
    .mentors__statsMain {
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 4rem;
        gap: 1.5rem;
    }

    .mentors__stats>img {
        max-width: 7.5rem;
        max-height: 7.5rem;
    }

    .mentors__stats>div>h1 {
        font-size: 3.8rem;
    }

    .mentors__stats>div>p {
        text-align: center;
        font-size: 1.6rem;
        margin-right: 0;
    }

}

@media screen and (max-width: 600px) {
    .slider__slide__main>h1 {
        font-size: 2.4rem;
    }
}