.homefeedback__main {
    height: 52.9rem;
    width: 100%;
    background: #000;
    padding: 4.5rem 20rem;
}

.homefeedback {
    width: 100%;
    height: 100%;
    background:
        linear-gradient(#000000 0 0) padding-box,
        linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 3px solid transparent;
    border-radius: 2.4rem;
    box-shadow: 3px 2px 2px 0px #000;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 3.5rem 6.1rem 3.9rem 6.4rem;
}

.homefeedback__left {
    max-width: 50rem;
    min-height: 37.5rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.homefeedback__left>h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
}

.homefeedback__left>input {
    min-width: 100%;
    height: 4.3rem;
    border-radius: 1rem;
    border: 1px solid #000;
    background: #EAEAEA;
    margin-bottom: 1.4rem;
    outline: none;
    font-family: 'Inter', sans-serif;
    padding: 1.1rem 2rem;
    color: black;
    font-size: 1.5rem;
}

.homefeedback__left>textarea {
    min-width: 100%;
    height: 14rem;
    border-radius: 10px;
    border: 1px solid #000;
    font-family: 'Inter', sans-serif;
    background: #EAEAEA;
    outline: none;
    padding: 1.1rem 2rem;
    color: black;
    font-size: 1.5rem;
}

.homefeedback__submit {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 50rem;
}

.homefeedback__submit>p {
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 1.7rem;
}

.homefeedback__submit>div {
    height: 4.3rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.homefeedback__submit>div>p {
    color: black;
    background: linear-gradient(111deg, #6F57D8 14.06%, #D56A8F 93.75%);
    width: 14.7rem;
    height: 4rem;
    border-radius: 4rem;
    background: linear-gradient(180deg, #7A59CD 0%, #BB65A0 96.88%);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    cursor: pointer;
}


.homefeedback__right {
    min-width: 30rem;
}


@media screen and (max-width: 1700px) {
    .homefeedback__main {
        padding: 4.5rem 15rem;
    }
}

@media screen and (max-width: 1500px) {
    .homefeedback__main {
        padding: 4.5rem 10rem;
    }
}

@media screen and (max-width: 1400px) {
    .homefeedback__main {
        padding: 4.5rem 8rem;
    }
}

@media screen and (max-width: 1050px) {
    .homefeedback__main {
        max-height: 45rem;
        width: 100%;
        background: #000;
        padding: 2rem 4rem;
    }

    .homefeedback {
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        padding: 2rem 2.5rem;
    }

    .homefeedback__left {
        max-width: 50rem;
        min-height: 32rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        overflow: hidden;
    }

    .homefeedback__left>h1 {
        font-size: 3rem;
    }

    .homefeedback__left>input {
        max-width: 100%;
    }

    .homefeedback__left>textarea {
        max-width: 100%;

    }

    .homefeedback__submit {
        max-width: 100%;
    }

    .homefeedback__right {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .homefeedback__main {
        padding: 2rem 4rem;
    }
}