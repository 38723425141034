/* -----------------startup__upload------------------- */
.file-dropzone>i {
    color: #fff;
    font-size: 3rem;
}

.file-dropzone>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 300;
    margin: 0.5rem 0 0.1rem 0;
}

.file-dropzone>p {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 300;
}

.file-dropzone>div {
    padding: .8rem 1.5rem;
    border-radius: 2.5rem;
    background: linear-gradient(126deg, #8059C9 0%, #C66697 100%);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: .5rem;
}

.file-dropzone>div:hover {
    background: linear-gradient(111deg, #6F57D8 30.06%, #D56A8F 93.75%);
    transition: background-color 0.5s;
}

.file-dropzone>input {
    display: none;
}

.file-upload-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    min-height: 20rem;
    position: relative;
    /* background: url(../../../../public/assets/border-input.svg);
    background-repeat: no-repeat;
    background-size: 100%; */
    border: 2px dashed #7D59CC;
}

.file-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 135rem;
    height: 100%;
    background-color: #000000;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.file-dropzone span {
    margin-bottom: 1.5rem;
}

.file-dropzone.loading {
    background-color: #444;
}

.file-dropzone.uploaded {
    background-color: rgb(10, 10, 10);
}

.file-dropzone.error {
    background-color: rgb(88, 8, 8);
}

.loading-indicator,
.uploaded-message,
.error-message {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

.dragdrop__message {
    position: absolute;
    bottom: 1rem;
}

.loading-indicator {
    color: #fff;
    white-space: nowrap;
}

.uploaded-message {
    position: absolute;
    color: rgb(19, 142, 19);
    white-space: nowrap;
}

.error-message {
    color: rgb(161, 34, 34);
    white-space: nowrap;
}

/* -----------------startup__upload------------------- */
.dragddrop__uploaded {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dragddrop__uploaded>h1 {
    color: #ffffff;
    font-family: Poppins;
    font-size: 2.1rem;
    font-weight: 600;
}

.dragddrop__uploaded>h1>span {
    color: #7152B7;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 500;
    margin-left: .4rem;
}

.dragddrop__uploaded>p {
    color: rgba(255, 255, 255, 0.60);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
    margin: .5rem 0 1rem 0;
}

.dragddrop__uploaded>div {
    padding: .8rem 1.5rem;
    border-radius: 2.5rem;
    background: linear-gradient(126deg, #8059C9 0%, #C66697 100%);
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.dragddrop__uploaded>div>i {
    margin-right: .5rem;
}

.dragddrop__uploaded>button {
    border: 2.5rem;
    background-color: transparent;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}