.sq__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    padding-bottom: 8rem;
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(33, 28, 43), rgb(0, 0, 0));
    overflow: hidden;
}

.sq__container {
    color: white;
    z-index: 1;
    max-width: 144rem;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    padding: 0 8rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 5rem;
}

/* ----------main content------------ */
.sq__main {
    display: grid;
    grid-template-columns: 1fr 30rem;
    grid-template-rows: 100%;
    gap: 3rem;
    padding: 3.5rem 0;
}

/* ----------main content comment------------ */
.sq__main-com {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

/* ---------------question-----------------  */
.sq__question {
    min-width: 100%;
    position: relative;
    display: flex;
    max-height: 100%;
    padding: 2.4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    border-radius: 2.1rem;
    border-radius: 2.1rem;
    background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
}

.sq__question-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 100%;
}

.sq__question-name>h1 {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
    /* line-height: 4rem; */
    margin-left: 1rem;
}

.sq__question-ques {
    display: flex;
    width: 100%;
    padding: 1rem 0.7rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 1.2rem;
    background: rgba(255, 255, 255, 0.12);
}

.sq__question-ques>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 600;
    /* line-height: 4rem; */
}

.sq__reply-main {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
}

.sq__reply-btn {
    display: flex;
    width: 32.6rem;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    border-radius: 1rem;
    background: #FFF;
    cursor: pointer;
}

.sq__reply-btn>p {
    font-size: 1.6rem;
    font-weight: 600;
    background: var(--colour, linear-gradient(180deg, #7057D7 0%, #DC6B89 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* ----------main content search------------ */
.sq__ques-count {
    margin: 2.5rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 100%;
    justify-content: space-between;
    gap: 1rem;
}

.sq__ques-count>h5 {
    color: rgba(255, 255, 255, 0.50);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.9rem;
    letter-spacing: -0.08rem;
    white-space: nowrap;
}

.sq__ques-count>h5>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.9rem;
    letter-spacing: -0.09rem;
}

.sq__ques-count>span {
    width: 88%;
    height: .1rem;
    background: rgba(255, 255, 255, 0.40);
}

/* ----------main content search------------ */
.sq__main-com-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    overflow-y: scroll;
    max-height: 198vh;
    width: 100%;
    padding-right: .5rem;
}

.sq__main-com-container::-webkit-scrollbar {
    width: .008rem;
}

.sq__main-com-container::-webkit-scrollbar-thumb {
    border-radius: 2.1875rem;
    background: rgba(255, 255, 255, 0.40);
}

.sq__main-com-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


/* -----------------Answer reply----------------- */
.sq___reply {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.6rem;
    min-width: 100%;
}

.sq__reply-input {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 0.2rem;

}

.sq__reply-input>textarea {
    background: transparent;
    outline: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.60);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 2rem;
    color: rgba(255, 255, 255, 0.661);
    font-size: 1.6rem;
    font-weight: 400;
    resize: none;
}

.sq__reply-input>textarea::-webkit-scrollbar {
    width: .7rem;
}

.sq__reply-input>textarea::-webkit-scrollbar-thumb {
    background-color: rgba(224, 224, 224, 0.9);
    border-radius: 6px;
    cursor: pointer;
}

.sq__reply-input>textarea::-webkit-scrollbar-thumb:hover {
    background-color: #8c8c8c;
}

.sq__reply-input>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;
}

.sq__reply-input>div>span {
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.6rem;
    font-weight: 400;
    cursor: pointer;
}

/* ----------main content sidebar------------ */
.sq__side {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.8rem;
    flex-shrink: 0;
}

.sq__side-ask {
    display: flex;
    min-width: 100%;
    max-height: 100%;
    padding: 1.6rem 1.4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    border-radius: 2rem;
    background: #FFF;
}

.sq__side-ask-head>h2 {
    color: #000;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 600;
}

.sq__side-ask>p {
    color: rgba(0, 0, 0, 0.75);
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.sq__side-ask-btn {
    display: flex;
    width: 25rem;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 1rem;
    background: linear-gradient(180deg, #7A56C3 0%, #ED6F7E 100%);
    cursor: pointer;
    user-select: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.9rem;
}

.side__side-mentor {
    display: flex;
    width: 100%;
    padding: 3.4rem 2.4rem 4rem 2.4rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.8rem;
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.35);
}

.side__side-mentor>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.side__side-mentor-btn {
    display: flex;
    width: 25rem;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 1rem;
    background: var(--new-grad, linear-gradient(66deg, #3140C1 43.85%, #A254E8 99.8%));
    cursor: pointer;
    user-select: none;
}

.side__side-mentor-btn>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.9rem;
}


@media screen and (max-width: 1150px) {
    .sq__container {
        padding: 0 4rem;
    }

    /* ----------main content------------ */
    .sq__main {
        grid-template-columns: 1fr 25rem;
        gap: 3rem;
        padding: 3.5rem 0;
    }

    /* ---------------question-----------------  */
    .sq__question {
        padding: 2.4rem;
        gap: 2rem;
    }

    .sq__question-name>h1 {
        font-size: 1.6rem;
    }

    .sq__question-ques {
        padding: 0 0.7rem;
    }

    .sq__question-ques>p {
        font-size: 2rem;
    }

    /* ----------main content search------------ */
    .sq__ques-count {
        margin: 2.5rem 0;
    }

    .sq__ques-count>h5 {
        font-size: 1.6rem;
    }

    .sq__ques-count>h5>span {
        font-size: 1.8rem;
    }

    .sq__ques-count>span {
        width: 88%;
        height: .1rem;
        background: rgba(255, 255, 255, 0.40);
    }

    /* -----------------Answer reply----------------- */
    .sq__reply-input>div {
        gap: 1.6rem;
    }

    .sq__reply-input>div>span {
        font-size: 1.6rem;
    }

    /* ----------main content sidebar------------ */
    .sq__side {
        gap: 2.8rem;
    }

    .sq__side-ask {
        padding: 1.6rem 1.4rem;
    }

    .sq__side-ask-head>h2 {
        font-size: 1.8rem;
    }

    .sq__side-ask>p {
        font-size: 1.4rem;
    }

    .sq__side-ask-btn {
        width: 100%;
        padding: 0.8rem 1rem;
        font-size: 1.6rem;
    }

    .side__side-mentor {
        padding: 3.4rem 1.4rem 4rem 1.4rem;
    }

    .side__side-mentor>p {
        font-size: 1.6rem;
    }

    .side__side-mentor-btn {
        width: 100%;
        padding: 0.8rem 1.2rem;
    }

    .side__side-mentor-btn>p {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 990px) {
    .sq__container {
        padding: 0 4rem;
    }

    /* ----------main content------------ */
    .sq__main-com-container {
        display: flex;
        flex-flow: column nowrap;
        gap: 2rem;
        overflow-y: scroll;
        height: 100%;
        width: 100%;
        padding-right: .5rem;
    }

    .sq__main {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 100%;
        gap: 3rem;
        padding: 3.5rem 0 0 0;
    }

    /* ---------------question-----------------  */
    .sq__question {
        padding: 2.4rem;
        gap: 2rem;
    }

    .sq__question-name>h1 {
        font-size: 1.6rem;
    }

    .sq__question-ques {
        padding: 0 0.7rem;
    }

    .sq__question-ques>p {
        font-size: 2rem;
    }



    /* ----------main content search------------ */
    .sq__ques-count {
        margin: 2.5rem 0;
    }

    .sq__ques-count>h5 {
        font-size: 1.6rem;
    }

    .sq__ques-count>h5>span {
        font-size: 1.8rem;
    }

    .sq__ques-count>span {
        width: 88%;
        height: .1rem;
        background: rgba(255, 255, 255, 0.40);
    }

    /* -----------------Answer reply----------------- */
    .sq__reply-input>div {
        gap: 1.6rem;
    }

    .sq__reply-input>div>span {
        font-size: 1.6rem;
    }

    /* ----------main content sidebar------------ */
    .sq__side {
        gap: 2.8rem;
    }

    .sq__side-ask {
        padding: 2.4rem;
    }

    .sq__side-ask-head>h2 {
        font-size: 1.8rem;
    }

    .sq__side-ask>p {
        font-size: 1.4rem;
    }

    .sq__side-ask-btn {
        width: 25rem;
        padding: 0.8rem 1rem;
    }

    .sq__side-ask-btn {
        font-size: 1.6rem;
    }

    .side__side-mentor {
        padding: 2.4rem;
    }

    .side__side-mentor>p {
        font-size: 1.6rem;
    }

    .side__side-mentor-btn {
        width: 25rem;
        padding: 0.8rem 1.2rem;
    }

    .side__side-mentor-btn>p {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 670px) {
    .sq__reply-main {
        display: flex;
        flex-flow: column-reverse nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .sq__reply-btn {
        width: 20rem;
    }
}