.feed__main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    background:
        linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to bottom, #714FB4, #714FB4);
}

.feed__container {
    color: white;
    z-index: 1;
    max-width: 200rem;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    padding: 0 8rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 5rem;
}

.feed__body {
    max-width: 50rem;
    min-width: 50rem;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    /* overflow-y: scroll; */
    padding: 1rem 0;
}

.feed__body::-webkit-scrollbar {
    width: .0001rem;
}