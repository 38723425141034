.addExperience__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 80%;
    height: 80%;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    overflow-y: scroll;
}


.addExperience__container::-webkit-scrollbar {
    width: .000007rem;
}

.addExperience__container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;

}

.addExperience__container::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}



.addExperience__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    padding: 2rem 3rem;
}

.addExperience__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addExperience__main>h1>span{
    cursor: pointer;
}

.addExperience__main>div {
    display: flex;
    flex-flow: column nowrap;
}

.addExperience__formcol {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 100%;
}

.addExperience__formcol>div {
    width: 100%;
}

.addExperience__formcol>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
}

.addExperience__formcol>div>input {
    min-width: 100%;
    height: 3.5rem;
    outline: none;
    background-color: black;
    color: white;
    font-size: 1.2rem;
    padding: 0.6rem 1.7rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}

.addExperience__upload {
    min-width: 100%;
    min-height: 3.5rem;
    padding: 0.6rem 1.7rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
    background-color: black;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}
.addExperience__upload-img{
    position: relative;
    height: 2rem;
    width: 10rem;
    overflow: hidden;
    color: white;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .8rem;
    cursor: pointer;
    font-size: 1.1rem;
    z-index: 1;
}
.addExperience__upload-img>input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.addExperience__upload-viewimg {
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addExperience__upload-viewimg>img {
    max-height: 100%;
}

.addExperience__formcol>div>textarea {
    width: 100%;
    height: 10rem;
    outline: none;
    background-color: black;
    color: white;
    padding: 0.6rem 1.7rem;
    font-size: 1.2rem;
    margin: 0 0 1.8rem 0;
    border-radius: 0.7rem;
    border: 1px solid #7D59CC;
}


.addExperience__form-margin {
    margin-right: 5%;
}

.addExperience__save {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addExperience__save>div {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 3.5rem;
    width: 8rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .addExperience__container {
        border-radius: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
    }
}

@media screen and (max-width: 650px) {
    .addExperience__main {
        padding: 1rem 1.5rem;
    }
    .addExperience__main>h1{
        padding: 0;
    }
    .addExperience__map {
        padding: 0;
    }

    .addExperience__formcol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-width: 100%;
    }

    .addExperience__form-margin {
        margin-right: 0;
    }
}