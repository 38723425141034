/*-------------------startup__mid;---------------------- */
.startup__min-img {
    width: fit-content;
    height: fit-content;
    display: grid;
    place-items: center;
    width: 16rem;
}

.startup__min-img>div {
    display: grid;
    place-items: center;
    width: 10rem;
    height: 10rem;
    flex-shrink: 0;
    background-color: #6956DE;
    border-radius: 100%;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    transform: scale(1);
}

.startup__min-img.selected>div {
    background: linear-gradient(135deg, #C049D4 50%, #6956DE00 100%);
    transition: background-color 0.3s, transform 0.3s;
    transform: scale(1.1);
}

.startup__min-img>div>img {
    min-width: 80%;
    display: inline-block;
    position: static;
    object-fit: fill;
}

.startup__min-img>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.93rem;
}

.startup__min-img-size {
    height: 70%;
}