.success {
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: grid;
    place-items: center;
    padding: 0 18rem;
}

.success__img {
    height: 45rem;
    width: 100%;
    background:
        linear-gradient(#000000 0 0) padding-box,
        linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
    border: 2px solid transparent;
    border-radius: 1rem;
    padding: 2rem 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success__img>img {
    max-height: 100%;
}

.success__btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.success__btn-home {
    width: 12.6rem;
    height: 3.6rem;
    border-radius: 1.5rem;
    background: #6E56D9;
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 600;
    display: grid;
    place-items: center;
}
.success__btn-home:hover{
    background: #ffffff;
    color: #151212;
}