.footer__main {
    max-width: 100%;
    min-height: 70rem;
    background: #000;
    padding: 5.5rem 8rem 0 8rem;
}

.footer {
    height: 100%;
    max-width: 135rem;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    color: white;
}

.footer__text {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 5rem;
}

/* ------------- left side -------------  */
.footer__left {
    width: 49.3rem;
    height: 100%;
    margin-right: 7rem;
}

.footer__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.3rem;
}

.footer__logo>img {
    height: 4rem;
}

.footer__left>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3.7rem;
}

.footer_social {
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.footer_social>p {
    color: #FFF;
    font-family: Inter;
    font-size: 1.8rem;
    font-weight: 500;
}

.footer_social>a {
    /* margin-right: 3.2rem; */
}

.footer_social>a>img {
    height: 2.5rem;
}

.footer__left>h1 {
    color: #FFF;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 400;
}

/* -------------- right side -------------- */
.footer__right {
    flex-basis: 65%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 4rem;
}

.footer__links {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 20rem;

}

.footer__links>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.footer__links>a {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.8rem;
}

/* ------------------ bottom --------------- */
.footer__mainImage {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .footer__main {
        padding: 5.5rem 8rem 8rem 8rem;
    }
  }

@media screen and (max-width: 992px) {
    .footer__main {
        padding: 5.5rem 4rem 8rem 4rem;
    }

    .footer {
        height: 100%;
        min-width: 100%;
    }
    
    .footer__text {
        flex-flow: row wrap;
        margin-bottom: 5rem;
        gap: 2rem;
    }

    /* ------------- left side -------------  */
    .footer__left {
        min-width: 100%;
        height: 100%;
        margin-right: 7rem;
    }

    .footer__logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.3rem;
    }

    .footer__logo>img {
        height: 4rem;
    }

    .footer__left>p {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 3.7rem;
    }

    .footer_social {
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .footer_social>p {
        color: #FFF;
        font-family: Inter;
        font-size: 1.8rem;
        font-weight: 500;
        margin-right: 2.6rem;
    }

    .footer_social>a {
        margin-right: 3.2rem;
    }

    .footer_social>a>img {
        height: 2.5rem;
    }

    .footer__left>h1 {
        color: #FFF;
        font-family: Inter;
        font-size: 1.5rem;
        font-weight: 400;
    }

    /* -------------- right side -------------- */
    .footer__right {
        gap: 4rem;
        min-width: 100%;
        margin-top: 2rem;
    }

    .footer__links {
        width: 19rem;
    }

    .footer__links>h1 {
        font-size: 1.8rem;
    }

    .footer__links>a {
        font-size: 1.4rem;
    }

    /* ------------------ bottom --------------- */
    .footer__mainImage {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .footer__mainImage>img{
        max-width: 100%;
    }

}


@media screen and (max-width: 600px) {
  
}