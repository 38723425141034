.oneM {
    margin-right: .5rem;
    margin-left: .5rem;
}

/* -------------Prompt--------------- */
.oneM__prompt {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 2rem;
}

.oneM__prompt>code {
    color: #424141;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 500;
}

/* -------------Sources Head--------------- */
.oneM__sourcesHead {
    padding: 0 2rem;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
}

.oneM__sourcesHead>span {
    height: 100%;
    min-width: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.oneM__sourcesHead>p {
    color: #1E1E1E;
    font-size: 2rem;
    font-weight: 500;
}

/* -------------Sources--------------- */
.oneM__sources {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 9rem;
    gap: 1rem
}

.oneM__sources-item {
    background: #EEF0ED;
    padding: .6rem 1rem;
    border-radius: .8rem;
    height: 100%;
    transition: background .2s ease-in-out;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.oneM__sources-item:hover {
    background: #e2e3e0;
    transition: background .2s ease-in-out;
}

.oneM__sources-item>div {
    color: #1E1E1E;
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
}

.oneM__sources-item>div>span {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #1E1E1E;
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
}

/* //Give link color  */
.oneM__sources-item>p {
    font-size: 1.1rem;
    color: rgb(52, 116, 219);
    text-decoration: underline;
    display: flex;
    flex-flow: row nowrap;
    align-items: start;
}


/* -------------Text--------------- */
.oneM-res {
    width: 100%;
    padding: 0 2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-radius: 0 0 2rem 2rem;
    overflow: hidden;
}

.oneM-text {
    max-height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    border-radius: 1.2rem;
    /* background: linear-gradient(117deg, rgba(255, 255, 255, 0.19) 11.68%, rgba(255, 255, 255, 0.10) 77.34%); */
    /* border: 2px solid #404040; */
}

.oneM-rcom {
    max-height: 100%;
    width: 100%;
    padding: 0 0 1.6rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    /* border-top: 1px solid rgba(255, 255, 255, 0.40); */

}

/* ---------------------Chat ui How to display------------------------ */

.oneM-display {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

/* CodeStyles.css */

.oneM-code-container {
    color: #1E1E1E;
    border-radius: 1.4rem;
    margin: 1rem 0;
    /* box-shadow: 0 .2rem .4rem rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
    font-weight: 400;
}

.oneM-language {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #1E1E1E;
}

pre {
    margin: 0;
    overflow-x: auto;
    background-color: rgb(30, 30, 30);
    color: #1E1E1E;
}

code {
    display: block;
    font-size: 1.1rem;
    white-space: pre-wrap;
}

.oneM-non-code-text {
    font-size: 1.6rem;
    line-height: 1.5;
    color: #1E1E1E;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}

/* ------------recomendation------------- */
.oneM-rcom {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}



.oneM-rmap>h1 {
    color: #1E1E1E;
    text-align: justify;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: .7rem;
}

.oneM-rmap>div {
    display: flex;
    flex-flow: row wrap;
    gap: 1.3rem;
}

.oneM-ritem {
    display: flex;
    padding: 0.3rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    border-radius: 2rem;
}

.oneM-ritem>p {
    color: #1E1E1E;
    text-align: justify;
    font-size: 1.35rem;
    font-weight: 500;
    /* white-space: nowrap; */
}

.oneM-ritem>p>span {
    color: #1E1E1E;
}









.block {
    position: relative;
    background: linear-gradient(0deg, #000, #272727);
}

.block:before,
.block:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
            #0000ff, #00ff00, #ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 10s linear infinite;
}

@keyframes steam {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.block:after {
    filter: blur(50px);
}


.oneM-k {
    /* padding: .5rem 0; */
    display: flex;
    flex-flow: column nowrap;
    gap: .6rem;
}

.oneM-k>h1 {
    background-image: linear-gradient(to bottom, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: justify;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
}

.oneM-k-img-main {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
}

.oneM-k-img {
    height: 2.4rem;
    width: 2.4rem;
}


@media screen and (max-width: 768px) {
    .oneM {
        margin-right: 0;
        margin-left: 0;
        border-top: 1px solid rgba(0, 0, 0, .1);
    }
    
    .oneM__prompt {
        padding: 0;
    }

    .oneM__sourcesHead {
        padding: 0;
    }

    .oneM__sources {
        padding: 0;
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 9rem 9rem;
        gap: 1rem
    }

    .ai__chat-input {
        max-width: 70rem;
        padding: 0 .7rem;
    }

    .ai__message {
        flex-basis: 90%;
        z-index: 1;
        padding: 1rem;
        padding-right: 1rem;
        overflow: hidden;
    }

    .oneM-res {
        width: 100%;
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
    }
}