.dialog__main {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3rem;
}

.dialog__cancel {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 2rem;
    background-color: white;
    border-radius: 100px;
    cursor: pointer;
}

.dialog {
    position: relative;
    z-index: 21;
    max-width: 80rem;
}

.dialog>h3 {
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.dialog>p {
    color: #A0A0A0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3rem;
    text-align: center;
}

.dialog>h6 {
    color: #A0A0A0;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: .4rem;
    text-align: center;
    cursor: pointer;
    /* text-decoration: underline; */
}

.dialog__cancel span {
    color: var(--AI-Body-text-color, #a0a0a0);
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dialogBox__form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: .4rem;
}

.input-style {
    color: #ccc;
    background-color: #333;
    height: 4rem;
    width: 35rem;
    padding: 0 1.4rem;
    font-size: 1.2rem;
    border: none;
    outline: none;
    border-radius: 2rem 0 0 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.input-style:focus {
    background-color: #444;
}


.dialogBox__form>button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .4rem;
    padding: 0 1rem;
    width: 14rem;
    height: 4rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    background-color: #2196F3;
    border: none;
    border-radius: 0 2rem 2rem 0;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    white-space: nowrap;
    cursor: pointer;
}

.dialogBox__form button svg {
    height: 2rem;
}

.dialogBox__form>button:hover {
    background-color: #1e88e5;
}

.dialogBox__form>button:active {
    background-color: #1976D2;
}

@media screen and (max-width: 600px) {
    .dialogBox__form {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-end;
        justify-content: center;
        gap: .4rem;
    }

    .input-style {
        width: 100%;
        border-radius: 2rem 2rem 0 2rem;
    }

    .dialogBox__form>button {
        width: 14rem;
        height: 4rem;
        border-radius: 2rem 0 2rem 2rem;
    }

    .dialog>h3 {
        font-size: 3.5rem;
    }
    
    .dialog>p {
        font-size: 1.4rem;
    }

    .dialog>h6 {
        text-align: right;
        padding-right: 1.5rem;
    }
}
@media screen and (max-width: 450px) {
    .dialog>h3 {
        font-size: 3rem;
    }
    
    .dialog>p {
        font-size: 1.3rem;
    }
}