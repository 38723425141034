.mentor {
    background-color: black;
    color: white;
    padding: 1.4rem 8rem;
    max-width: 100%;
    max-height: 100vh;
    background-image: url(../../../public/assets/mentor-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* -------------mentor nav--------------- */
.mentor__nav-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.mentor__nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.mentor__nav>a {
    height: 4.9rem;
}

.mentor__nav>a>img {
    max-height: 100%;
}

.mentor__nav-become>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    border-radius: 0.9375rem;
    border: 1px solid #FFF;
    transition: background-color ease-in-out .3s;
    cursor: pointer;
    width: 16rem;
}

.mentor__nav-become>div:hover {
    transition: background-color ease-in-out .3s;
    background-color: #363636;
}

.mentor__nav-become>div>p {
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-right: .4rem;
    white-space: nowrap;
}

/* -------------mentor Heading--------------- */
.mentor__head {
    display: grid;
    place-items: center;
    max-width: 100vw;
    min-height: 90vh;
    z-index: 1;
}

.mentor__head>div {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.mentor__head>div>h1 {
    color: #FFF;
    text-align: center;
    text-shadow: 5px 5px 15px 0px rgba(255, 255, 255, 0.25);
    font-family: Poppins;
    font-size: 4.4rem;
    font-weight: 700;
    line-height: 4.5rem;
    margin-bottom: 1.7rem;
}

.mentor__head>div>p {
    color: rgba(255, 255, 255, 0.90);
    text-align: center;
    font-family: Poppins;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: normal;
    max-width: 135rem;
}

.mentor__head>div>a>div {
    border: 1px solid #DE6C87;
    display: flex;
    width: 16rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    font-size: 1.6rem;
    margin-top: 1.7rem;
    transition: background-color ease-in-out .5s;
    transition: color ease-in .3s;
    cursor: pointer;
    white-space: nowrap;
}

.mentor__head>div>a>div:hover {
    background-color: white;
    border: 1px solid #DE6C87;
    color: #DE6C87;
    transition: color ease-in .3s;
}

/* ---------------body----------------- */
.mentor__body {
    background: linear-gradient(to right, rgb(0, 0, 0), rgb(33, 28, 43), rgb(0, 0, 0));
    min-height: 100%;
    padding: 1.4rem 8rem;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;

}

/* ----------------Mentor What---------------- */
.mentor__what {
    margin-bottom: 1.25rem;
    margin-top: 3rem;
    z-index: 1;

}

.mentor__what>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
    line-height: 4rem;
    margin-bottom: 4rem;
}

.mentor__what-img {
    margin-bottom: 1.25rem;
    max-width: 135rem;
}

.mentor__what-img>img {
    max-width: 100%;
}

/* ----------------Mentor Impact---------------- */
.mentor__impact-container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    z-index: 1;
}

.mentor__impact {
    max-width: 135rem;
    margin-top: 3.3rem;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
}

.mentor__impact-head {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 6rem;
}

.mentor__impact-head>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
}

.mentor__impact-head>p {
    color: #9798A0;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 400;
}

.mentor__impact>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.mentor__impact-card {
    max-width: 100%;
    height: 30rem;
    border-radius: 0.9375rem;
    border: 1px solid #FFF;
    margin-bottom: 5rem;
    display: flex;
    flex-flow: row nowrap;
    max-width: 135rem;
    overflow: hidden;
}

.mentor__impact-card-left {
    border-right: 1px solid white;
    flex-basis: 50%;
    display: flex;
    flex-flow: column nowrap;
}

.mentor__impact-card-left>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: .8rem;
}

.mentor__impact-card-left>div>p {
    color: #9798A0;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 400;
}

.mentor__impact-card-left1 {
    flex-basis: 33.33%;
    border-bottom: 1px solid white;
    padding: 1.5rem 2.94rem;
}

.mentor__impact-card-left2 {
    flex-basis: 33.33%;
    border-bottom: 1px solid white;
    padding: 1.5rem 2.94rem;
}

.mentor__impact-card-left3 {
    flex-basis: 33.33%;
    padding: 1.5rem 2.94rem;
}

.mentor__impact-card-right {
    flex-basis: 50%;
    background: linear-gradient(180deg, #6E56D9 0%, #DE6C87 100%);
    display: grid;
    place-items: center;
}

/* roap of what mentors have to do */
.mentor__map-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.mentor__map {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    min-height: 100vh;
    padding: 0 8rem;
    background-image: url('../../../public/assets/mentor-community.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.mentor__map::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    opacity: .6;
}

.mentor__map-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    z-index: 1;
}

.mentor__map-head>h1 {
    max-width: 59rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 700;
}

.mentor__map-head>a>div {
    padding: .8rem 1.2rem;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.85);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    transition: background-color ease-in .7s;
}

.mentor__map-head>a>div:hover {
    transition: background-color ease-out .7s;
    background: #000000;
    color: rgba(255, 255, 255, 0.85);
}

.mentor__map-content {
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 5rem 0;
    max-width: 100%;
}

.mentor__map-content>div {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    width: 40rem;
}

.mentor__map-content>div>div {
    width: 18rem;
    height: 9rem;
    flex-shrink: 0;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    background: #000;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.mentor__map-content>div>div:nth-child(1) {
    margin-right: 20rem;
}

.mentor__map-content>div>div:nth-child(2) {
    margin-right: 10rem;
}

/* ----------------Mentor Card---------------- */
.mentor__card-container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    min-height: 60vh;
    z-index: 1;
    background-color: black;
    color: white;
}

.mentor__card {
    max-width: 135rem;
    margin-top: 3.3rem;
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
    z-index: 1;
    padding: 4rem 8rem;
    margin-bottom: 4rem;
}

.mentor__card>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 600;
    max-width: 70rem;
    margin-bottom: 2.5rem;
}

.mentor__card>div {
    max-width: 128rem;
    min-height: 36rem;
    border-radius: 1.5rem;
    background: #0E0E0E;
    padding: 5rem 8rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 10rem;
    box-shadow: 0px 0px 1rem -.1rem rgba(255, 255, 255, 0.764);
}

.mentor__card-img {
    max-width: 23rem;
    max-height: 25rem;
    border-radius: 2rem;
    overflow: hidden;
    flex-basis: 30%;
}

.mentor__card-img>img {
    max-width: 100%;
}

.mentor__card-text {
    flex-basis: 70%;
    display: flex;
    flex-flow: column nowrap;
}

.mentor__card-text>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.mentor__card-text>p {
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
}