.sendQ__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 90%;
    max-height: 80%;
    overflow-y: scroll;
    border-radius: 1rem;
    background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
    padding: 2rem;
}

.sendQ__container::-webkit-scrollbar {
    width: .000007rem;
}

.sendQ__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    min-width: 100%;
    padding: 2rem 3rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.35);
    position: relative;
}

.sendQ__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    border: 1px solid white;
    padding: .2rem;
    border-radius: 50%;
    display: grid;
    cursor: pointer;
}

/* -----------------heading---------------- */
.sendQ__main>h1 {
    width: 100%;
    color: #FFF;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 4rem;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
}

/* -----------------comment box---------------- */
.sendQ__box {
    display: flex;
    flex-flow: column nowrap;
    min-width: 100%;
}

/* -----------------comment box profile---------------- */
.sendQ__box-text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    min-width: 100%;
}

.sendQ__box-text>p {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    display: flex;
    padding: 0.6rem 0.8rem;
    align-items: center;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
    gap: .7rem;
}

.sendQ__box>textarea {
    color: rgba(255, 255, 255, 0.90);
    font-size: 1.5rem;
    font-weight: 400;
    outline: none;
    resize: none;
    height: 21.1rem;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 2.3rem;
    border: 1px solid rgba(255, 255, 255, 0.35);
    background: rgba(255, 255, 255, 0.15);
    margin: 1rem 0;
    outline: none;
}

.sendQ__cbox-input>textarea::-webkit-scrollbar {
    width: .7rem;
}

/* -------------------input container----------------------- */
.sendQ__category-chips-container {
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 2rem;
    max-width: 80rem;
}

.sendQ__category-chips-container>h2 {
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 400;
}

.sendQ__search-input-container {
    display: flex;
    min-width: 30rem;
    align-items: center;
    position: relative;
    margin: .4rem 0;
    position: relative;
}

.sendQ__search-input-container>div {
    position: absolute;
    left: 1.3rem;
}

.sendQ__search-input-container>button {
    position: absolute;
    right: 1.3rem;
    font-size: 1.4rem;
}

.sendQ__search-input-container>input {
    display: flex;
    min-width: 30rem;
    padding: .8rem 3.3rem;
    align-items: center;
    border-radius: 4rem;
    border: 1px solid #FFF;
    outline: none;
    background: transparent;
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
}

/* -------------------Submit----------------------- */
.sendQ__submit {
    min-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 3rem;
}

.sendQ__submit>span {
    color: #FFF;
    font-size: 1.8rem;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
}

.sendQ__submit>div {
    display: flex;
    width: 24.2rem;
    height: 4rem;
    padding: 0.6rem 3rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1.5rem;
    background: var(--new-grad, linear-gradient(66deg, #3140C1 43.85%, #A254E8 99.8%));
    color: #FFF;
    font-size: 1.8rem;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
}

/* -------------------chips container----------------------- */

.category-dropdown {
    background-color: #444;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
}

.sendQ__category-list {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.sendQ__category-list>span {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 4rem;
    text-decoration-line: underline;
    cursor: pointer;
}

.sendQ__chip {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    padding: 0.6rem 0.8rem;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
    gap: .7rem;
}

.sendQ__chip>img {
    width: 1.3rem;
    height: 1.4444rem;
}

.sendQ__chip.selected {
    background: linear-gradient(91deg, #BF5F80 0.63%, #6F57D8 100%);
}



@media screen and (max-width: 830px) {
    .sendQ__container {
        padding: 1rem;
    }

    .sendQ__main {
        padding: 1rem 1.5rem;
    }

    .sendQ__close {
        top: 1.7rem;
    }

    /* -----------------heading---------------- */
    .sendQ__main>h1 {
        font-size: 2rem;
    }

    /* -----------------comment box profile---------------- */
    .sendQ__box>textarea {
        height: 16rem;
        width: 100%;
        padding: 1rem;
    }

    /* -------------------input container----------------------- */
    .sendQ__category-chips-container {
        padding: 1px;
        margin-top: 1rem;
    }

    .sendQ__search-input-container {
        min-width: 100%;
    }


    .sendQ__search-input-container>input {
        min-width: 100%;
    }

    /* -------------------Submit----------------------- */
    .sendQ__submit {
        gap: 2rem;
    }

    .sendQ__submit>span {
        display: none;
    }

    .sendQ__submit>div {
        flex-basis: 50%;
        width: fit-content;
        font-size: 1.6rem;
    }

    /* -------------------chips container----------------------- */

    .category-dropdown {
        margin-bottom: .7rem;
    }

    .sendQ__category-list {
        flex-flow: row wrap;
        gap: .7rem;
        margin: 1rem 0;
    }

    .sendQ__category-list>span {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .sendQ__chip {
        padding: 0.4rem 0.5rem;
        font-size: 1.2rem;
    }
}