.homesuccess__main {
    max-width: 100%;
    min-height: 55rem;
    background: #EAEAEA;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 8rem;
}

.homesuccess__main>h1 {
    text-align: center;
    margin-top: 2.5rem;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 4rem;
    font-weight: 500;
    line-height: 5rem;
    max-width: 70rem;
}

.homesuccess__foot {
    position: relative;
    margin: 8rem 0 10rem 0;
    padding: 0 2rem;
    display: flex;
    flex-flow: row nowrap;
    height: fit-content;
    width: 120rem;
    height: 23.2rem;
    justify-content: space-between;
    align-items: flex-start;
}

.homesuccess_img {
    width: 25rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
}

.homesuccess_img>div {
    width: 11rem;
    height: 11rem;
    background-color: #6956DE;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homesuccess_img>div>img {
    height: 5.7rem;
    max-width: 100%;
}

.homesuccess_img>h1 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    font-weight: 700;
    margin-top: 2.1rem;
    margin-bottom: .4rem;
}

.homesuccess_img>p {
    color: #424242;
    text-align: center;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 22px;
}

.homesuccess__wave {
    position: absolute;
    top: 1rem;
    left: 18rem;
}

.homesuccess__wave>img {
    width: 94%;
}


@media screen and (max-width: 1210px) {
    .homesuccess__main {
        max-width: 100%;
        min-height: 55rem;
        padding: 0 8rem;
        min-height: 100%;
    }

    .homesuccess__main>h1 {
        font-size: 3rem;
        line-height: 3rem;
        max-width: 100%;
    }

    .homesuccess__foot {
        margin: 2rem 0;
        padding: 2rem;
        flex-flow: row wrap;
        width: 100%;
        height: 100%;
        gap: 3rem;
        justify-content: center;
        align-items: flex-start;
    }

    .homesuccess_img>div>img {
        height: 4.7rem;
    }

    .homesuccess__wave {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .homesuccess__main {
        padding: 0 4rem;
    }
}
@media screen and (max-width: 600px) {
    .homesuccess__main {
        padding: 2rem;
    }
}