.bft__wrapper-main {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    overflow: hidden;
    border: 0;
}

.bft__wrapper {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    background: rgb(38, 30, 63);
    overflow: hidden;
}

.bft__body {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-template-rows: 100%;
    overflow: hidden;
}

/*----------------Navigation menu------------------*/

.bft__form {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    height: 100%;
    background-color: #100916;
    background: url(../../../public/assets/basicD-bg-1.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.bft__form::-webkit-scrollbar {
    width: 0.0000000001em;
    /* Adjust the width as needed */
}

.bft__form::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Make the scrollbar thumb transparent */
}

/*----------------Navigation menu------------------*/
.bft__intruct {
    user-select: none;
    min-height: 100%;
    min-width: 100%;
    background-color: #100916;
    background: url(../../../public/assets/basicD-bg-2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2rem 0 0 2rem;
    margin-left: -2rem;
}

.bft__intruct>img {
    user-select: none;
    height: 100%;
    /* width: 100; */
    background-color: rgb(38, 30, 63);
}

/* ------------------rigth content-------------------- */
.bft__r-main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
    padding: 4rem 2rem;
    min-height: 100%;

}

.bft__r-main>h1 {
    color: #FFF;
    text-align: center;
    /* font-family: Gilroy; */
    font-size: 2.8rem;
    font-weight: 800;
}

.bft__r-main>div {
    display: flex;
    flex-flow: column nowrap;
    align-content: space-evenly;
    gap: 2rem;
    min-height: 100%;
}

.bft__r-main>div>div {
    display: flex;
    padding: 1.2rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.4rem;
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.40);
    background-color: linear-gradient(117deg, rgba(255, 255, 255, 0.19) 11.68%, rgba(255, 255, 255, 0.10) 77.34%);
}

.bft__r-main>div>div>h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
}

.bft__r-main>div>div>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}



@media screen and (max-width: 1030px) {
    .bft__body {
        position: relative;
        height: 100vh;
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 100%;
        overflow: hidden;
    }
}