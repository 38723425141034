.post__main {
    min-height: 2rem;
    min-width: 100%;
    background: black;
    border-radius: 1rem;
    padding: 1rem 0;
}

.post__head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    padding: 0 1.4rem;
    height: 4rem;
}

.post__head-text>h3 {
    font-size: 1.4rem;
    color: white;
    line-height: 1.4rem;
}

.post__head-text {
    display: flex;
    flex-flow: column nowrap;
}

.post__head-text>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: .3rem;
    color: #a7a7a7;
}

.post__head-text>div>span {
    font-size: 1rem;
}

.post__des {
    display: flex;
}

.post__des {
    color: #a7a7a7;
    font-size: 1.3rem;
    padding: 1.5rem 1.4rem 1rem 1.4rem;
}

.post__img {
    max-height: 60rem;
    overflow: hidden;
}

.post__img>img {
    min-width: 100%;
    height: auto;
}

.post__likes-count {
    color: #a7a7a7;
    padding: 1rem 2rem .2rem 2rem;
}

.post__likes-count:hover {
    color: #a7a7a7;
}

.post__likes-count>p {
    font-size: 1.2rem;
}

.post__likes {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
    padding: 0 1.4rem;
    height: 4rem;
    border-top: 1px solid#a7a7a7;
    padding-top: 1rem;
}

.post__likes-item {
    border-radius: 1rem;
    flex: 1;
    display: flex;
    align-items: center;
    transition: background .1s ease-in-out;
    cursor: pointer;
}

.post__likes-item:hover {
    transition: background .1s ease-in-out;
    background: #121212;
}

.post__likes-item>p {
    font-size: 1.6rem;
}











.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.article-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.article-content {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 20px;
}
.article-content li{
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 20px;
  list-style: unset;
}

.article-author {
  font-style: italic;
}
