.payment_body {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  overflow: visible;
}
.payment_body_left {
  background: linear-gradient(135deg, #6e4fc9, #1c103b, #000000);
  width: 75%;
  padding-left: 7rem;
  padding-top: 4rem;
}

.mentor_details {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.mentor_name {
  color: #fff;
  text-shadow: 0px 0.3rem 0.3rem rgba(0, 0, 0, 0.25);
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 1.6rem;
}

.mentor_payment_about {
  color: var(--AI-Body-text-color, #a0a0a0);
  padding: 1rem 0rem;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 162.5% */
  width: 90%;
}
.mentor_payment_about span {
  color: #fff;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
}

.choose_plan {
  color: #fff;
  font-family: Poppins;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 1rem 0rem;
}

.choose_plan_desc {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment_monthly_plans {
  display: flex;
  width: 90%;
  padding: 1.2rem;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  background: linear-gradient(
    117deg,
    rgba(255, 255, 255, 0.329) 11.68%,
    rgba(255, 255, 255, 0.068) 35.68%
  );
}

.payment_monthly_plans_left_month {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.payment_monthly_plans_left_month_desc {
  color: var(--AI-Body-text-color, #a0a0a0);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.payment_monthly_plans {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  margin: 3rem 0rem;
}
.payment_monthly_plans:hover {
  background: radial-gradient(
    circle farthest-side at 100% 50%,

    #d26a91 30%,
    #7258d5
  );
  cursor: pointer;
}
.payment_monthly_plans_right span {
  color: #fff;
  font-family: Roboto;
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3rem; /* 100% */
}
.payment_monthly_plans_right {
  color: #fff;
  font-size: 1.4rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
}
.payment_body_right {
  padding: 4rem 3rem;
  width: 40%;
  background: radial-gradient(
    circle farthest-side at 100% 50%,

    #d26a91 30%,
    #7258d5
  );
}
.payment_body_info {
  color: rgba(0, 0, 0, 0.62);
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 0rem 0rem 1.5rem 0rem;
}
.payment_body_text1 {
  color: #ffb6c1;
  font-family: Poppins;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem; /* 125% */
  padding: 1.5rem 0rem;
}

.payment_body_text2 {
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0rem 0rem 1.5rem 0rem;
}

.payment_body_session_price span {
  color: #fff;
  font-family: Roboto;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 3.5rem; /* 100% */
}

.payment_body_session_price {
  color: #fff;
  font-family: Roboto;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.5rem;
  padding: 0rem 0rem 1.5rem 0rem;
}
.payment_body_coupon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.payment_coupon_code {
  border: 1px solid rgba(255, 255, 255, 0.65);
  display: flex;
  width: 85%;
  padding: 0.5rem;
  align-items: center;
  background-color: transparent;
}

.payment_body_coupon_btn {
  display: flex;
  width: 12%;
  padding: 1.2rem;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  background: #fff;
}

.payment_details_order_summany {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 1.2rem 0rem;
}
.payment_details_first {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0rem;
}
.payment_details_first_long_term {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment_details_first_price {
  color: #fff;
  font-family: Roboto;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.payment_details_final {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0rem;
}

.payment_details_final_long_term {
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.payment_details_final_price {
  color: #fff;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.payment_final_button {
  display: flex;
  width: 100%;
  height: 4rem;
  padding: 1rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  background: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: rgb(140, 0, 153);
}
.payment_final_button:hover {
  background: linear-gradient(135deg, #6e4fc9, #1c103b, #000000);
  color: white;
}
.phonepe_secured_div {
  display: flex;
  width: 11rem;
  margin-top: 1rem;
  padding: 0.5rem 1.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
  border-radius: 1rem;
  background: linear-gradient(
    117deg,
    rgba(255, 255, 255, 0.3) 11.68%,
    rgba(255, 255, 255, 0.3) 77.34%
  );
}
.spaceing_for_mobile {
  display: none;
}
.payment_monthly_plans_left {
  width: 70%;
}
.payment_monthly_plans_right {
  text-align: right;
}
#negotiable_equity_price {
  font-size: 2rem;
}
/* Phones (Portrait) */
@media screen and (max-width: 767px) {
  .payment_body {
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
  }

  .payment_body_left {
    background: #000000;
    width: 100%;

    padding: 6rem 4rem;
  }
  .payment_monthly_plans {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  .payment_monthly_plans_left {
    width: 120%;
  }
  .payment_monthly_plans_right span {
    font-size: 2.5rem;
  }
  .payment_monthly_plans_right {
    width: 80%;
  }
  .payment_body_right {
    width: 100%;
  }

  .payment_final_div {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    /* box-shadow: 0px -42px 100px 0px rgba(0, 0, 0, 0.8); */
    width: 100%;
    margin-left: -3rem;
    padding: 3rem;
  }
  .spaceing_for_mobile {
    display: block;
    height: 20rem;
  }
  .phonepe_secured_div {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 2rem;
  }
  .payment_coupon_code {
    height: 6rem;
    width: 70%;
    font-size: 2rem;
    outline: none;
  }
  .payment_body_coupon_btn {
    font-size: 2rem;
    width: 25%;
  }
  /* fixing font size for all in mobile */
  .payment_body_coupon_btn {
    font-size: 2rem;
  }
  .mentor_name {
    font-size: 3rem;
  }

  .mentor_payment_about {
    font-size: 1.8rem;
  }
  .mentor_payment_about span {
    font-size: 1.8rem;
  }

  .choose_plan {
    font-size: 6rem;
  }

  .choose_plan_desc {
    font-size: 1.7rem;
  }

  .payment_monthly_plans_left_month {
    font-size: 2.1rem;
  }

  .payment_monthly_plans_left_month_desc {
    font-size: 1.5rem;
  }

  .payment_monthly_plans_right span {
    font-size: 3.2rem;
  }
  .payment_monthly_plans_right {
    font-size: 1.7rem;
  }

  .payment_body_info {
    font-size: 3rem;
  }
  .payment_body_text1 {
    font-size: 4rem;
  }

  .payment_body_text2 {
    font-size: 3rem;
  }

  .payment_body_session_price span {
    font-size: 5.4rem;
  }

  .payment_body_session_price {
    font-size: 2rem;
  }

  .payment_details_order_summany {
    font-size: 2.4rem;
  }

  .payment_details_first_long_term {
    font-size: 2.4rem;
  }

  .payment_details_first_price {
    font-size: 2.4rem;
  }

  .payment_details_final_long_term {
    font-size: 3rem;
  }

  .payment_details_final_price {
    font-size: 3rem;
  }

  .payment_final_button {
    font-size: 2.3rem;
  }
  .phonepe_secured_by_word {
    font-size: 1.5rem;
    font-weight: 800;
  }
}

/* Tablets (Portrait and Landscape) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .payment_body {
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
  }
  .payment_body_left {
    width: 100%;

    padding: 6rem 4rem;
  }
  .payment_monthly_plans {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  .payment_monthly_plans_left {
    width: 120%;
  }
  .payment_monthly_plans_right span {
    font-size: 3rem;
  }
  .payment_monthly_plans_right {
    width: 60%;
  }
  .payment_body_right {
    width: 100%;
  }

  .payment_final_div {
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    /* box-shadow: 0px -42px 100px 0px rgba(0, 0, 0, 0.8); */
    width: 100%;
    margin-left: -3rem;
    padding: 3rem;
  }
  .spaceing_for_mobile {
    display: block;
    height: 20rem;
  }
}
