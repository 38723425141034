.ai__wrapper-main {
    max-width: 100vw;
    max-height: 100lvh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    background: #f6f6f4;
    overflow: hidden;
    border: 0;
}

.ai__wrapper {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

.ai__body {
    min-height: 100lvh;
    max-width: 100vw;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    overflow: hidden;
}

/*----------------Navigation menu------------------*/

.ai__navbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100%;
    height: 6rem;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

/*----------------Navigation left side------------------*/
.ai__left-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    grid-column: 1;
    color: #595959;
}

.ai__left-item>i {
    font-size: 2.3rem;
    cursor: pointer;
    padding-bottom: .3rem;
}

.ai__left-item>a {
    flex: 1;
}

.ai-main-logo {
    height: fit-content;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .7rem;
    flex: 1;
}

.ai-main-logo>div {
    height: 2.6rem;
    width: 2.6rem;
}

.ai-main-logo>p {
    color: #121830;
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 600;
}

/*----------------Navigation mid------------------*/
.ai__mid-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
    grid-column: 2;

    border: 3px solid saddlebrown;
}

.ai__mid-item>div {
    margin-bottom: .5rem;
    height: 3.5rem;
}

.ai__mid-item>div>img {
    max-height: 100%;
}

.ai__mid-item>h5 {
    color: rgba(255, 255, 255, 0.65);
    font-family: Roboto;
    font-size: 1.2rem;
    font-weight: 500;
}

/*----------------Navigation right side------------------*/
.ai__right-item {
    grid-column: 3;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0 4rem;
    overflow: hidden;
}

/*----------------Navigation right side------------------*/
.ai__container {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

/* --------------------Sidebar--------------------- */
.ai__sidebar {
    width: 25rem;
    background: rgba(232, 232, 227);
    position: absolute;
    height: 100%;
    padding: 1rem 1.7rem;
    left: 0rem;
    transition: left .1s ease-in-out;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
}

.ai__sidebar.closed {
    left: -25rem;
}

/* --------------------sidebar bottom--------------------- */
.ai__sidebar-bottom {
    height: calc(100% - 8rem);
    display: flex;
    flex-flow: column nowrap;
    gap: .3rem;
    justify-content: space-between;
    padding-top: 3rem;
}

.ai__sidebar-item {
    width: 100%;
    padding: .8rem 1rem;
    border-radius: .8rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #1E1E1E;
    font-size: 1.6rem;
    font-weight: 500;
}

.ai__sidebar-item:hover {
    background: #edecec;
}

.ai__sidebar-item>span {
    height: 1.8rem;
}


/* --------------------Recent chats--------------------- */
.ai__sidebar-recent {
    height: calc(100% - 5rem);
    overflow-y: scroll;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    gap: .4rem;
    padding-right: .5rem;
}

.ai__sidebar-recent::-webkit-scrollbar {
    width: .0000007rem;
}

.ai__sidebar-recent::-webkit-scrollbar-thumb {
    background-color: rgba(224, 224, 224, 0.9);
    border-radius: 6px;
    cursor: pointer;
}

.ai__sidebar-recent::-webkit-scrollbar-thumb:hover {
    background-color: #edecec;
}

.ai__sidebar-recent-items {
    border-radius: .8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .4rem 1rem;
    cursor: pointer;
    color: #1E1E1E;
    border-radius: .8rem;
    transition: all .1s ease-in-out;
}

.ai__sidebar-recent-items>span {
    font-size: .9rem;
    color: #3d3d3d;
}

.ai__sidebar-recent-items:hover {
    background: #dddddd;
    transition: all ease-in-out;
}

.ai__sidebar-recent-items.selectedchat {
    background: #d6d5d5;
    border-radius: 0.8rem;
}

.ai__sidebar-recent-items>div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
}

.ai__sidebar-recent-items>div>p {
    font-size: 1.5rem;
    margin-left: 1rem;
    white-space: nowrap;
}

.ai__sidebar-recent-items>div>button {
    font-size: 1.8rem;
    color: #3b3b3b;
    padding: .2rem;
    border-radius: .4rem;
    transition: all .3s ease-in-out;
}

.ai__sidebar-recent-items>div>button:hover {
    background: #c5c2c2;
    transition: all .3s ease-in-out;
}

/* --------------------Location--------------------- */
.ai__sidebar-home {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai__sidebar-back {
    padding: 0.4rem 1.6rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: 0.8rem;
    border: 1px solid #1E1E1E;
    color: #1E1E1E;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.015rem;
    cursor: pointer;
}

.ai__sidebar-back:hover {
    background: #edecec;
}



/* --------------------Main--------------------- */
.ai__main {
    max-height: 100vh;
    overflow: hidden;
    min-width: calc(100vw - 25rem);
    flex-grow: 1;
    margin-left: 25rem;
    transition: margin .1s ease-in-out;
    z-index: 1;
    overflow: hidden;
}

.ai__main.closed {
    margin-left: 0;
}

.ai__main-body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6rem 1fr;
    height: 100%;
}

.ai__sidebarClose {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    grid-column: 1;
    padding: 0 0 0 4rem;
}

.ai__sidebarClose>i {
    font-size: 2.3rem;
    cursor: pointer;
    transition: all 3s ease-in-out;
    color: #3d3d3d;
}

/*----------------Main Chat box------------------*/

.ai__main-content {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.ai__sidebar-hidden {
    display: none;
}

@media screen and (max-width: 990px) {
    .ai__container {
        display: flex;
        flex-flow: row nowrap;
        position: block;
    }

    .ai__sidebar {
        width: 25rem;
        position: fixed;
        height: 100%;
        padding: 1rem 1.7rem;
        left: -25rem;
        z-index: 6;
        transition: left .1s ease-in-out;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .ai__sidebar-closer {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 5;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .ai__sidebar.closed {
        left: 0rem;
    }

    .ai__sidebar-closer.closed {
        display: none;
    }

    .ai__sidebar-hidden {
        display: flex;
        min-height: 100%;
    }

    .ai__main {
        max-height: 100vh;
        overflow: hidden;
        min-width: 100vw;
        flex-grow: 1;
        margin-left: 0;
        transition: margin .1s ease-in-out;
        z-index: 1;
        overflow: hidden;
    }

    .ai__wrapper {
        position: relative;
    }

    .ai__sidebar-recent-items>div>p {
        font-size: 1.8rem;
    }
}


@media screen and (max-width: 700px) {
    .ai__right-item {
        padding: 0 .4rem;
    }

    .ai__sidebarClose {
        padding: 0 0 0 1.2rem;
    }
}

/* ----------------Menu Item-Profile------------------- */
.nav__menu-profile-main {
    display: flex;
    flex-flow: column nowrap;
}

.nav__menu-profile {
    min-width: 15rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    color: white;
}

.nav__menu-profile>p {
    color: #DEDEDE;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
}

.nav__menu-profile-border-btm {
    border-bottom: 1px solid gray;
}