.ai__singleMessage {
    margin-right: .5rem;
    margin-left: .5rem;
}

/* -------------Text--------------- */
.ai__singleMessage-prompt {
    border-radius: 2rem 2rem 0rem 0rem;
    /* background: #404040; */
    max-height: 100%;
    width: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 2rem 2rem 0rem 0rem;
}

.ai__singleMessage-prompt>span {
    height: 100%;
    min-width: 4rem;
    margin-right: .5rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.ai__singleMessage-prompt>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ai__singleMessage-prompt>div>code {
    color: #1E1E1E;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2rem;
}

/* -------------Text--------------- */
.ai__singleMessage-res {
    /* background: color(display-p3 0 0 0 / 0.70); */
    /* background: rgba(0, 0, 0, 0.70); */
    width: 100%;
    padding: 0 2rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    border-radius: 0 0 2rem 2rem;
}

.ai__singleMessage-text {
    max-height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    border-radius: 1.2rem;
    /* background: linear-gradient(117deg, rgba(255, 255, 255, 0.19) 11.68%, rgba(255, 255, 255, 0.10) 77.34%); */
    /* border: 2px solid #404040; */
}

.ai__singleMessage-rcom {
    max-height: 100%;
    width: 100%;
    padding: 0 0 1.6rem 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    /* border-top: 1px solid rgba(255, 255, 255, 0.40); */

}

/* ---------------------Chat ui How to display------------------------ */

.ai__singleMessage-display {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

/* CodeStyles.css */

.ai__singleMessage-code-container {
    color: #1E1E1E;
    border-radius: 1.4rem;
    margin: 1rem 0;
    /* box-shadow: 0 .2rem .4rem rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
    font-weight: 400;
}

.ai__singleMessage-language {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #1E1E1E;
}

pre {
    margin: 0;
    overflow-x: auto;
    background-color: rgb(30, 30, 30);
    color: #1E1E1E;
}

code {
    display: block;
    font-size: 1.1rem;
    white-space: pre-wrap;
}

.ai__singleMessage-non-code-text {
    font-size: 1.6rem;
    line-height: 1.7;
    color: #1E1E1E;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}

/* ------------recomendation------------- */
.ai__singleMessage-rcom {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}



.ai__singleMessage-rmap>h1 {
    color: #1E1E1E;
    text-align: justify;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: .7rem;
}

.ai__singleMessage-rmap>div {
    display: flex;
    flex-flow: row wrap;
    gap: 1.3rem;
}

.ai__singleMessage-ritem {
    display: flex;
    padding: 0.3rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    border-radius: 2rem;
}

.ai__singleMessage-ritem>p {
    color: #1E1E1E;
    text-align: justify;
    font-size: 1.35rem;
    font-weight: 500;
    /* white-space: nowrap; */
}
.ai__singleMessage-ritem>p>span {
    color: #1E1E1E;
}









.block {
    position: relative;
    background: linear-gradient(0deg, #000, #272727);
}

.block:before,
.block:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
            #0000ff, #00ff00, #ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 10s linear infinite;
}

@keyframes steam {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.block:after {
    filter: blur(50px);
}


.ai__singleMessage-k {
    /* padding: .5rem 0; */
    display: flex;
    flex-flow: column nowrap;
    gap: .6rem;
}

.ai__singleMessage-k>h1 {
    background-image: linear-gradient(to bottom, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: justify;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
}

.ai__singleMessage-k-img-main {
    display: flex;
    flex-flow: row nowrap;
    gap: .5rem;
}

.ai__singleMessage-k-img {
    height: 2.4rem;
    width: 2.4rem;
}