.cEvent_Wrapper {
    max-height: 100vh;
    overflow-y: scroll;
    padding: 4rem;
}
.cEvent_Wrapper::-webkit-scrollbar {
    width: 0;
}

.cEvent__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 2rem;
}

.cEvent__l {
    max-width: 40rem;
    min-width: 40rem;
}

.cEvent__r {
    max-width: 40rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}

.cEvent__r>label {
    font-size: 1.6rem;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
}

.cEvent__r>label>div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
}

.cEvent_input,
.cEvent_textarea {
    flex: 1;
    color: #ccc;
    background-color: #333;
    height: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    border: none;
    outline: none;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.cEvent_textarea {
    resize: none;
    padding: 1rem;
    height: 8rem;
}

.cEvent_colorInput {
    height: 4rem;
    width: 4rem;
    background: #333;
    cursor: pointer;
    border-radius: 1rem;
    padding: .2rem;
}

.cEvent_input:focus,
.cEvent_textarea:focus {
    background-color: #444;
}


.cEvent_Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .4rem;
    padding: 0 1rem;
    width: 14rem;
    height: 4rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    background-color: #2196F3;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    white-space: nowrap;
    cursor: pointer;

    /* background: #333; */
}

.cEvent_Btn:focus,
.cEvent_Btn:hover {
    background-color: #108ef6;
}



@media screen and (max-width: 800px) {
    .cEvent_Wrapper {
        max-height: 100vh;
        overflow-y: scroll;
        padding: 6rem 2rem;
    }

    .cEvent__main {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .cEvent_Wrapper {
        padding: 6rem .7rem;
    }

    .cEvent__l {
        max-width: 40rem;
        min-width: 100%;
    }
    
    .cEvent__r {
        max-width: 40rem;
    }
    
}