.BookCall__container-main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 2rem;
  width: 90%;
  background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
  height: 90%;
  overflow-y: scroll;
  color: white;
}

.BookCall_closeIcon {
  display: none;
}


.BookCall__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  background: url(../../../public/assets/bookCall-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll;
  color: white;
}

.BookCall__container-main::-webkit-scrollbar {
  width: .0000007rem;
}

.BookCall__container::-webkit-scrollbar {
  width: .0000007rem;
}

.BookCall__main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  min-height: 100%;
  padding: 4.5rem 15rem;
}

.BookCall__main>h1 {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  font-size: 3.6rem;
  font-weight: 700;
}


.BookCall__main>h1>span {
  cursor: pointer;
}

.BookCall_label {
  font-size: 1.6rem;
  font-weight: 400;
  display: flex;
  flex-flow: column nowrap;
}

.BookCall_label-input {
  margin-top: 1.2rem;
  background-color: transparent;
  outline: none;
  display: flex;
  min-width: 50%;
  padding: 1.4rem 1.2rem;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  color: white;
  border: 1px solid #FFF;
  margin-bottom: 2.4rem;
  font-size: 1.6rem;
}

/* .BookCall_label>input::placeholder {
  color: rgba(255, 255, 255, 0.6);
} */

.BookCall_label-textarea {
  margin-top: 1.2rem;
  background: transparent;
  outline: none;
  display: flex;
  height: 14.8rem;
  color: white;
  min-width: 100%;
  padding: 1.4rem 1.2rem;
  align-items: center;
  gap: 1rem;
  border-radius: 1rem;
  border: 1px solid #FFF;
  resize: none;
  margin-bottom: 2.4rem;
  font-size: 1.6rem;
}

.BookCall_label-textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.BookCall_submit {
  display: flex;
  padding: 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  max-width: fit-content;
  font-weight: 600;
  border-radius: 1rem;
  background-color: white;
  cursor: pointer;
  background: #fff;
}

.BookCall_submit>p {
  background: var(--stroke, linear-gradient(180deg, #BF5F80 0%, #6F57D8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* ---------------input chips----------------- */
.interestDomain__chip-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
}

@media (max-width: 768px) {
  .interestDomain__chip-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1168px) {
  .interestDomain__chip-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.interestDomain__chip-container>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
}

.interestDomain__chip.selected {
  background: linear-gradient(91deg, #BF5F80 0.63%, #6F57D8 100%);
  cursor: pointer;
}

.interestDomain__chip:hover.selected {
  background: linear-gradient(91deg, #BF5F80 0.63%, #6F57D8 100%);
  cursor: pointer;
}

.interestDomain__chip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #FFF;
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1.2rem 1.1rem;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.1s;
}

.interestDomain__chip:hover {
  background-color: #6f57d8;
  transition: background-color 0.1s;
}

.interestDomain__close-icon {
  margin-left: 8px;
  cursor: pointer;
}

.interestDomain__area-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.interestDomain__area-list>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
}



@media screen and (max-width: 992px) {
  .BookCall__container-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 0;
    width: 100%;
    background: var(--ask-mentor-grad, linear-gradient(180deg, #7E59CB 0%, #170D14 100%));
    height: 100%;
    overflow-y: scroll;
    color: white;
  }

  .BookCall__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    overflow-y: scroll;
    color: white;
    padding-bottom: 6rem;
  }

  .BookCall__main {
    padding: 4.5rem 4.5rem;
  }

  .BookCall_label-input {
    width: 100%;
  }

  .BookCall_label-textarea {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .BookCall__container-main {
    position: relative;
  }

  .BookCall_closeIcon {
    display: block;
    position: fixed;
    z-index: 3;
    top: 14px;
    right: -10px;
    height: 4rem;
    width: 4rem;
    font-size: 2.6rem;
  }

  .BookCall__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 90%;
    overflow-y: scroll;
    color: white;
  }

  .BookCall__main {
    padding: 3rem 2rem;
  }

  .BookCall__main>h1 {
    font-size: 3rem;
  }

  .BookCall__main>h1>span {
    display: none;
    cursor: pointer;
  }


  .BookCall_label {
    font-size: 1.6rem;
  }

  .BookCall_label-input {
    width: 100%;
  }

  .BookCall_label-textarea {
    width: 100%;
  }

  .BookCall_submit {
    font-size: 1.6rem;
  }

  .interestDomain__chip-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .interestDomain__chip-container>h2 {
    font-size: 1.6rem;
  }

  .interestDomain__chip {
    font-size: 1.6rem;
  }
}