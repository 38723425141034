.profileIntro__body {
    padding: 0 8rem;
    min-height: 100%;
    max-width: 150rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
}

/* ----------------------Main profile-------------------- */
.profileIntro__main {
    width: 100%;
    margin-bottom: 3rem;
    padding: auto;
}

.profileIntro__main-top {
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    max-width: 100%;
    min-height: 19.6rem;
    gap: 1.75rem;
    padding-right: 2rem;
    padding-top: 2rem;
}

/* ----------------------top left-------------------- */
.profileIntro__main-top-left-main {
    height: 30rem;
    width: 30rem;
}

.profileIntro__main-top-left {
    height: 30rem;
    width: 30rem;
    background-color: rgb(238, 222, 222);
    border-radius: 1rem;
    box-shadow: 0px 0px 8px 1px rgba(246, 246, 246, 0.783);
    overflow: hidden;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileIntro__main-top-left>img {
    min-width: 100%;
    min-height: 100%;
}

/* ----------------------top right-------------------- */
.profileIntro__main-top-right {
    min-width: calc(100% - 30rem);
    min-height: 19.6875rem;
    border-radius: 0.625rem;
    border: 1px solid #FFF;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
}

.profileIntro__main-top-right1 {
    flex-basis: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1rem 1.5rem;
    border-right: 1px solid white;
}

.profileIntro__main-top-right1>h3 {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.02rem;
    text-decoration-line: underline;
    margin-bottom: 1.3rem;
}

.profileIntro__main-top-right1>h1 {
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Poppins;
    font-size: 3.3rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.2rem;
}

.profileIntro__main-top-right1>p {
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    max-width: 36rem;
}

.profileIntro__main-top-company {
    width: fit-content;
    min-height: 6rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid #FFF;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: .9rem 1.5rem;
    margin-bottom: 2.5rem;
}

.profileIntro__main-top-company>h1 {
    color: #FFF;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0.0225rem;
}

.profileIntro__main-top-company>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .5rem
}

.profileIntro__main-top-company>div>img {
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
}

.profileIntro__main-top-company>div>span {
    font-size: 1.4rem;
}


/* ----------------------------------- */
.profileIntro__main-top-right2 {
    flex-basis: 50%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1.5rem 3rem;
}

.profileIntro__main-top-right2>div>p {
    color: rgba(255, 255, 255, 0.55);
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.09rem;
    margin-bottom: 1.5rem;
}

.profileIntro__main-top-right2>div>div {
    display: flex;
    align-items: center;
    margin-bottom: .7rem;
}

.profileIntro__main-top-right2>div>div>img {
    max-height: 2.6rem;
}

.profileIntro__main-top-right2>div>div>span {
    color: #FFF;
    font-family: Roboto;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin-left: 1.2rem;
}


/* ----------------------Main profile bottom-------------------- */
.profileIntro__main-bottom {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    max-width: 100%;
    min-height: 19.6rem;
    gap: 1.75rem;
    margin-top: 2.6rem;
    flex-shrink: 2;
}

.profileIntro__main-bottom-left {
    flex-basis: 25%;
    height: 100%;
    max-width: 40rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 5rem 5rem 5rem 5rem;
    gap: 1rem;
    flex-basis: 30rem;
}

.profileIntro__main-bottom-left>div {
    width: 100%;
    height: 5rem;
    border-radius: 0.5rem;
    background: #EDEDED;
    color: black;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: .5rem 1.3rem;
}

.profileIntro__main-bottom-left-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: grid;
    place-items: center;
    margin-right: 1.2rem;
    background-color: #7350B8;
}

.profileIntro__main-bottom-left-text>h4 {
    color: rgba(0, 0, 0, .8);
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 600;
}

.profileIntro__main-bottom-left-text>p {
    color: rgba(0, 0, 0, 0.99);
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
}

.profileIntro__main-bottom-left-text>span {
    color: rgba(0, 0, 0, 0.90);
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
}

/* ----------------- botttom right ------------------ */
.profileIntro__main-bottom-right {
    flex-grow: 3;
    flex-basis: 75%;
    display: flex;
    flex-flow: column nowrap;
    align-content: space-between;
    width: 100%;

}

.profileIntro__main-bottom-right-img {
    max-width: 100%;
    height: 6.3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.profileIntro__main-bottom-right-imgstack {
    display: flex;
    flex-flow: row nowrap;
    padding: .5rem 1rem;
    margin-right: .5rem;
}

.profileIntro__main-bottom-right-imgstack>div {
    max-width: 4rem;
    max-height: 4rem;
    border-radius: 100%;
    margin-left: -0.8rem;
    background-color: #7350B8;
    overflow: hidden;
}

.profileIntro__main-bottom-right-imgstack>div>img {
    width: 100%;
}

.profileIntro__main-bottom-right-imgtext {
    padding: .8rem 1.8rem;
    border: 2px solid #6F57D8;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    white-space: nowrap;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 2.4375rem;
}

.profileIntro__main-bottom-right-trial {
    max-width: 100%;
    min-height: 6.3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.9rem;
    cursor: pointer;
    user-select: none;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 0.625rem;
}

.profileIntro__main-bottom-right-trial>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.875rem;
}

.profileIntro__main-bottom-right-trial>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.5rem;
}

.profileIntro__main-bottom-right-trial>h1>i {
    margin-left: .7rem;
}

.profileIntro__main-bottom-right-paid {
    min-height: 6.3rem;
    max-width: 100%;
    flex-shrink: 0;
    /* background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent; */
    background: #EDEDED;
    border-radius: 0.625rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1.9rem;
    cursor: pointer;
    user-select: none;
}

.profileIntro__main-bottom-right-paid>h1 {
    /* color: #FFF; */
    font-family: Roboto;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 2.5rem;
    color: #A560AE;
}

.profileIntro__main-bottom-right-paid>p {
    /* color: #FFF; */
    font-family: Poppins;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: #A560AE;
}

.profileIntro__main-bottom-right-paid>p>i {
    font-size: 1.5625rem;
    margin-left: .7rem;
}

.profileIntro__experties {
    min-height: 0rem;
    padding: 1rem 0;
}

.profileIntro__experties>div {
    display: flex;
    gap: 1rem;
}

.profileIntro__experties>div>div {
    min-height: 6.3rem;
    width: 100%;
    border-radius: 0.625rem;
    background: linear-gradient(133deg, #BF5F80 0%, #6F57D8 100%);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 0.5rem 1.9rem;
}

.profileIntro__experties>div>div>h1 {
    color: #FCFCFC;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    line-height: 4rem;
    display: flex;
    align-items: center;
}

.profileIntro__experties>div>div>h1>span {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: grid;
    place-items: center;
    margin-right: 1.2rem;
    background-color: #ffffff;
}

.profileIntro__experties>div>div>p {
    color: rgba(255, 255, 255, 0.90);
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.6rem;
}

.profileIntro__arrow-down {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.profileIntro__arrow-down>img {
    height: 2rem;
}

@media (max-width: 1100px) {
    .profileIntro__body {
        padding: 2rem 4rem;
    }

    .profileIntro__main-top {
        margin: auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        max-width: 100%;
        min-height: 1rem;
        gap: 1.75rem;
    }

    /* ----------------------top left-------------------- */
    .profileIntro__main-top-left {
        background-color: rgb(238, 222, 222);
        height: 30rem;
        width: 30rem;
        border-radius: 1rem;
        box-shadow: 0px 0px 8px 1px rgba(246, 246, 246, 0.783);
        overflow: hidden;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
    }

    .profileIntro__main-top-left>img {
        min-width: 100%;
        min-height: 100%;
    }

    /* ----------------------top right-------------------- */

    .profileIntro__main-top-right1 {
        flex-basis: 60%;
    }

    .profileIntro__main-top-right1>h3 {
        font-size: 1.4rem;
    }

    .profileIntro__main-top-right1>h1 {
        font-size: 2.8rem;
    }

    .profileIntro__main-top-right1>p {
        font-size: 1.4rem;
    }

    .profileIntro__main-top-company>h1 {
        font-size: 1.6rem;
    }

    /* ----------------------------------- */
    .profileIntro__main-top-right2 {
        flex-basis: 40%;
        padding: 1.5rem 1rem;
    }

    .profileIntro__main-top-right2>div>p {
        font-size: 1.4rem;
    }

    .profileIntro__main-top-right2>div>div>img {
        max-height: 2rem;
    }

    .profileIntro__main-top-right2>div>div>span {
        font-size: 1.6rem;
    }


    /* ----------------------Main profile bottom-------------------- */
    .profileIntro__main-bottom {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 100%;
        min-height: 1rem;
        gap: 1.75rem;
        margin-top: 2.6rem;
        flex-shrink: 2;
    }

    .profileIntro__main-bottom-left {
        flex-basis: 100%;
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 5rem 5rem;
        gap: 1rem;
    }

    .profileIntro__experties>div {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
    }
}

@media screen and (max-width: 880px) {
    .profileIntro__main-top {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 100%;
        min-height: 1rem;
        gap: 0rem;
    }

    /* ----------------------top left-------------------- */
    .profileIntro__main-top-left-main {
        height: fit-content;
        width: 100%;
    }

    .profileIntro__main-top-left {
        background-color: rgb(238, 222, 222);
        max-height: 14rem;
        max-width: 14rem;
        border-radius: 50rem;
        box-shadow: 0px 0px 8px 1px rgba(246, 246, 246, 0.783);
        overflow: hidden;
        flex-shrink: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* ---------------------------------------------------- */
    .profileIntro__main-top-right {
        min-width: 100%;
        min-height: 1rem;
        border-radius: 0.625rem;
        border: none;
        flex-flow: column nowrap;
        overflow: hidden;
        gap: 0.5rem;
    }

    .profileIntro__main-top-right1 {
        flex-basis: 100%;
        flex-flow: column;
        justify-content: center;
        padding: 1rem 0;
        border-right: none;
    }

    .profileIntro__main-top-right1>h3 {
        display: none;
    }

    .profileIntro__main-top-right1>h1 {
        margin-bottom: 0.4rem;
        font-size: 2.8rem;
    }

    .profileIntro__main-top-right1>p {
        font-size: 1.4rem;
        max-width: 100%;
    }

    .profileIntro__main-top-company {
        display: none;
    }

    /* ----------------------------------- */
    .profileIntro__main-top-right2 {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        gap: 2rem;
        padding: 0;
        flex-basis: 100%;
    }

    .profileIntro__main-top-right2>div>p {
        font-size: 1.4rem;
    }

    .profileIntro__main-top-right2>div>div>img {
        max-height: 2rem;
    }

    .profileIntro__main-top-right2>div>div>span {
        font-size: 1.3rem;
    }

    /* ----------------------Main profile bottom-------------------- */
    .profileIntro__main-bottom {
        margin-top: 0rem;
    }

    .profileIntro__main-bottom-right-trial {
        gap: 1rem
    }

    .profileIntro__main-bottom-right-paid {
        gap: 1rem
    }

    .profileIntro__main-bottom-right-trial>h1 {
        font-size: 1.4rem;
    }

    .profileIntro__main-bottom-right-paid>h1 {
        font-size: 2.1rem;
    }
}

@media screen and (max-width: 680px) {
    .profileIntro__body {
        padding: 2rem;
    }

    .profileIntro__main-top-right2 {
        flex-flow: row wrap;
        column-gap: 2rem;
        row-gap: 0;
        padding: 0;
        flex-basis: 100%;
    }

    .profileIntro__main-bottom-right-trial {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem .9rem;
    }
    .profileIntro__main-bottom-right-trial>p>br{
        display: none;
    }

    .profileIntro__main-bottom-right-paid {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.5rem .9rem;
    }

    .profileIntro__experties>div>div {
        padding: 0.3rem .9rem;
    }

    .profileIntro__experties>div>div>h1 {
        font-size: 1.8rem;
    }

    .profileIntro__experties>div>div>p {
        font-size: 1.6rem;
    }

    .profileIntro__arrow-down>img {
        height: 1.6rem;
    }
}

@media screen and (max-width: 500px)    {
    .profileIntro__main-bottom-left {
        grid-template-columns: 1fr;
        grid-template-rows: 5rem 5rem 5rem 5rem;
    }
}