.sem__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    width: 60%;
    height: 100%;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    overflow-y: scroll;
}


.sem__container::-webkit-scrollbar {
    margin-top: 1rem;
    width: .000007rem;
}

.sem__container::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;

}

.sem__container::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

.sem__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    padding: 2rem 3rem;
}

.sem__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sem__main>h1>span {
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .sem__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: none;
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
        background: linear-gradient(347deg, #000 0%, #18151C 100%);
        overflow-y: scroll;
    }
}