.cd__main {
  padding: 3rem 5rem;
  width: 100%;
  min-height: 100%;
}

.cd__body {
  min-height: 100%;
}

.cd__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-height: 36rem;
}

/* -------------calender-------------- */
.cd__cal {
  margin: 0 0 6rem 0;
}

/* ---------------submit button----------------- */
.cd__submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cd__submit.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cd__submit>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39.4rem;
  height: 5rem;
  flex-shrink: 0;
  border-radius: 9.1rem;
  background: #4F408B;
  cursor: pointer;
}

.cd__submit>div>p {
  font-size: 2rem;
  font-weight: 700;
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .cd__main {
    padding: 2rem 1.5rem;
  }

  /* -------------calender-------------- */
  .cd__cal {
    margin: 0 0 1.5rem 0;
  }

  .cd__submit>div {
    width: 25rem;
    height: 4rem;
  }

  .cd__submit>div>p {
    font-size: 1.5rem;
  }

}