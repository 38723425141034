/* ------------------profile__desciption----------------- */
.profile__desciption {
    min-height: 20rem;
    width: 100%;
    background-color: white;
    padding: 10rem 8rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.profile__desciption>span {
    height: 4rem;
    width: 4rem;
}

.profile__desciption>span>img {
    height: auto;
    max-width: 100%;
}

.profile__desciption-text {
    max-width: 135rem;
}

.profile__desciption-text>p {
    color: #000;
    font-family: Poppins;
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 3rem;
}

.profile__desciption-text>code {
    color: #000;
    font-family: Poppins;
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 3rem;
}

.profile__desciption-text>p>span {
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .profile__desciption {
        min-height: 2rem;
        padding: 4rem;
    }

    .profile__desciption-text>p {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
}

@media screen and (max-width: 600px) {
    .profile__desciption {
        min-height: 2rem;
        padding: 2rem;
    }

    .profile__desciption-text>p {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    .profile__desciption-text>p>span {
        font-size: 1.4rem;
        font-weight: 600;
        text-decoration: underline;
    }
}