.personalStep2__main {
  min-height: 100vh;
  min-width: 100%;
  border-radius: 50px 0px 0px 50px;
  background: #000;
  box-shadow: -5px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 4.5rem 8rem;
  position: relative;
  background-image: url("../../../../public/assets/world-map.svg");
  background-repeat: no-repeat;
}

.personalStep2 {
  height: 100%;
  width: 100%;
}

/* ----------------- top heading --------------- */
.personalStep2__head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.personalStep2__head>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 2.6rem;
  font-weight: 700;
}

.personalStep2__head>img {
  width: 5rem;
  height: 5rem;
}

/* ----------------- top para --------------- */

.personalStep2>p {
  color: #fff;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0.6rem;
}

/* ----------------- Steps --------------- */
.personalStep2__step {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.12rem;
}

/* Step 1 */
.personalStep2__step1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 5rem;
}

.personalStep2__step1>p {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  display: grid;
  place-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 400;
  margin-right: 0.95rem;
  background: linear-gradient(#000000 0 0) padding-box,
    linear-gradient(to bottom, #6f57d8, #d56a8f) border-box;
  border: 2px solid transparent;
}

.personalStep2__step1>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Step 2 */
.personalStep2__step2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.personalStep2__step2>p {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  background: linear-gradient(to top, #6f57d8 14.06%, #d56a8f 93.75%);
  display: grid;
  place-items: center;
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 400;
  margin-right: 0.95rem;
}

.personalStep2__step2>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* --------------personalStep2__form--------------- */
.personalStep2__form {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  color: white;
  position: relative;
}

.personalStep2__form>h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.4rem;
  font-weight: 500;
}

.personalStep2__form>input {
  width: 100%;
  height: 5rem;
  flex-shrink: 0;
  border-radius: 0.9375rem;
  border: 2px solid #7d59cc;
  outline: none;
  background-color: black;
  color: white;
  padding: 0.6rem 1.7rem;
  font-size: 1.4rem;
  margin: 0.87rem 0 2.5rem 0;
}

/* ---------------input chips----------------- */
.personalStep2__chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.6rem;
}

.personalStep2__chip-container>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
}

.personalStep2__chip {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 0.6rem 1.2rem;
  height: 2.7rem;
  border-radius: 20px;
  cursor: pointer;
  color: #e5e3e3;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 400;
  transition: background-color 0.5s;
}

.personalStep2__chip.selected {
  /* background-color: #6f57d8; */
  background: linear-gradient(91deg, #BF5F80 0.63%, #6F57D8 100%);

}

.personalStep2__chip:hover {
  background-color: #6f57d8;
  transition: background-color 0.5s;
}

.personalStep2__close-icon {
  margin-left: 8px;
  cursor: pointer;
}

.personalStep2__area-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.personalStep2__area-list>h2 {
  color: rgba(255, 255, 255, 0.83);
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
}

/* -------------------Buttons-------------------- */

.personalStep2__form-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 4rem;
}

.personalStep2__form-btn>div {
  width: 12rem;
  height: 4rem;
  border-radius: 0.9375rem;
  background: linear-gradient(180deg, #7557d2 0%, #b363a5 100%);
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
}

.personalStep2__form-btn>div {
  width: 12rem;
  height: 4rem;
  border-radius: 0.9375rem;
  background: linear-gradient(180deg, #7557d2 0%, #b363a5 100%);
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  color: #000;
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
}



@media screen and (max-width: 990px) {
  .personalStep2__main {
    border-radius: 0 0 0 0;
    padding: 4.5rem 4rem;
  }

}

@media screen and (max-width: 990px) {
  .personalStep2__main {
    border-radius: 0 0 0 0;
    padding: 4.5rem 2rem;
  }
}