.r_modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 2rem;
    width: 80%;
    height: 80%;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    overflow-y: scroll;
}

.r_modal__container::-webkit-scrollbar {
    margin-top: 1rem;
    width: .000007rem;
}

.r_modal__main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    min-height: 100%;
    padding: 2rem 3rem;
}

.r_modal__main>h1 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.r_modal__main>h1>span{
    cursor: pointer;
}


.r_modal__main>div {
    display: flex;
    flex-flow: column nowrap;
}

.r_modal__save {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
}

.r_modal__save>div {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 3.5rem;
    width: 8rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .r_modal__container {
        border-radius: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
    }
}