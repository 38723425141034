.aiLanding__wrapper-main {
    max-width: 100vw;
    min-height: 100vh;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    color: white;
    padding: 0 0 0 0;
}

.aiLanding__wrapper-image {
    max-height: 100vh;
    min-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aiLanding__wrapper-image>img {
    height: auto;
    max-width: 100%;
}

.aiLanding__content {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 5rem 1fr;
}

.ail__top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8rem;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

.ail__top-span {
    display: flex;
    width: fit-content;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    border-radius: 0.8rem;
    border: 1px solid var(--g2, rgba(255, 255, 255, 0.80));
    color: #FFF;
    color: color(display-p3 1 1 1);
    text-align: center;
    font-family: Poppins;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.012rem;
    cursor: pointer;
}

.ail__btm {
    display: flex;
    justify-content: center;
    padding-top: 15rem;
    padding-right: 8rem;
}

.ail__btm-start {
    display: flex;
    align-items: center;
    background: #D9D9D9;
    width: fit-content;
    height: fit-content;
    padding: .6rem 2rem;
    border-radius: 2.5rem;
    filter: drop-shadow(6px 6px 0px color(display-p3 0.7491 0.4694 1 / 0.85));
    gap: .4rem;
    cursor: pointer;
}

.ail__btm-start>p {
    color: #000;
    color: color(display-p3 0 0 0);
    text-align: center;
    font-family: Roboto;
    font-size: 1.7501rem;
    font-weight: 700;
}