/* ---------------what mentees say about---------------- */
.profile__says-main {
    padding: 5rem 8rem 8rem 8rem;
    max-width: 100%;
    min-height: 0vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile__says {
    max-width: 135rem;
    min-height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 4rem;
}

/* ---------------what mentees say about left---------------- */

.profile__says-left {
    flex-basis: 50%;
}

.profile__says-left>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 3.5rem;
    letter-spacing: 0.03rem;
}

.profile__says-left>h1>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.66rem;
    font-weight: 600;
    line-height: 3.5rem;
    letter-spacing: 0.03125rem;
}

.profile__says-left>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin-bottom: 3rem;
}

.profile__says-left-stats {
    max-width: 100%;
    min-height: 7.4rem;
    border-radius: 0.625rem;
    background: linear-gradient(180deg, #7A59D0 0%, #DC6B89 100%);
    display: flex;
    flex-flow: row nowrap;
    margin-top: 4.5rem;
    margin-bottom: 3.3rem;
}

.profile__says-left-stats>div {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex-basis: 33.33%;
}

.profile__says-left-stats>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.profile__says-left-stats>div>p {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 400;
}

.profile__says-left-stats-border {
    border-right: 1px solid white;
}

.profile__says-left-trial {
    display: flex;
    width: 19rem;
    padding: 1rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.9rem;
    cursor: pointer;
    user-select: none;
    background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
    border: 1px solid transparent;
    border-radius: 0.625rem;
    color: #FFF;
    font-family: Poppins;
    font-size: 1.7rem;
    font-weight: 500;
}

/* ---------------what mentees say about right---------------- */
.profile__says-right {
    max-height: 35rem;
    flex-basis: 50%;
    overflow-y: scroll;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    padding-right: 0.5rem;
}

.profile__says-right::-webkit-scrollbar {
    width: 1rem;
}

.profile__says-right::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 6px;
    cursor: pointer;

}

.profile__says-right::-webkit-scrollbar-thumb:hover {
    background-color: #b3b2b2;
}

.profile__says-right>div {
    max-width: 100%;
    min-height: fit-content;
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem 2.5rem;
}

.profile__says-right>div>p {
    color: #000;
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.9375rem;
    margin-bottom: 1.5rem;
}

.profile__says-right>div>h3 {
    color: #000;
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.9375rem;
}


@media screen and (max-width: 1100px) {
    .profile__says-main {
        padding: 4rem;
    }

    .profile__says {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 992px) {

    .profile__says {
        max-width: 135rem;
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        gap: 1rem;
    }

    /* ---------------what mentees say about left---------------- */

    .profile__says-left {
        flex-basis: 50%;
    }

    .profile__says-left>h1 {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .profile__says-left>h1>span {
        font-size: 2rem;
        font-weight: 600;
    }

    .profile__says-left>p {
        font-size: 1.6rem;
        margin-bottom: 2rem;
    }

    .profile__says-left-stats {
        margin-top: 2.5rem;
        margin-bottom: 1.3rem;
    }


    .profile__says-left-trial {
        width: 19rem;
        padding: 1rem 1rem;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .profile__says-main {
        padding: 2rem;
    }

    .profile__says-left-stats {
        max-width: 100%;
        min-height: 7.4rem;
        flex-flow: column nowrap;
    }

    .profile__says-left-stats>div {
        padding: 2rem;
    }

    .profile__says-left-stats>div>p {
        font-weight: 400;
    }

    .profile__says-left-stats-border {
        border-bottom: 1px solid white;
        border-right: none;
    }
}