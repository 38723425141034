.ai__message-chatbox {
    position: relative;
    flex-grow: 1;
    max-width: 110rem;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

/* -------------Ai Message-------------- */
.ai__message {
    flex-basis: 90%;
    z-index: 1;
    padding: 1rem;
    padding-right: 3rem;
    overflow: hidden;
}

/* -------------Ai Messages Conatainer-------------- */
.ai__message-container {
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
}

.ai__message-container::-webkit-scrollbar {
    width: .0000007rem;
}

.ai__message-container::-webkit-scrollbar-thumb {
    background-color: rgba(224, 224, 224, 0.9);
    border-radius: 6px;
    cursor: pointer;
}

.ai__message-container::-webkit-scrollbar-thumb:hover {
    background-color: #8c8c8c;
}

/* -------------New Chat Ai Messages-------------- */
.ai__message-new {
    position: relative;
    scroll-behavior: smooth;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.ai__message-new-logo {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    /* gap: 2rem; */
}


.ai_message-main-logo {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai_message-main-logo>div {
    height: 2.4rem;
    width: 2.4rem;
}

.ai_message-main-logo>p {
    color: #121830;
    font-family: Poppins;
    font-size: 5rem;
    font-weight: 600;
    text-align: center;
}

/* .ai_message-main-logo>p>span {
    background-image: linear-gradient(100deg, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
} */

.ai_message-main-logo>p>span {
    background-image: linear-gradient(100deg, #6f57d8, #d56a8f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: Poppins;
    font-weight: 600;
}

.ai__message-new>div>h1 {
    text-align: center;
    color: #121830;
    text-align: center;
    font-size: 2.6rem;
    font-weight: 500;
}

.ai__message-new>div>h1>span {
    text-align: center;
    font-weight: 500;
    text-align: center;
}

.ai__message-new-m {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    min-width: 100%;
}

.ai__message-new-m>div {
    background: linear-gradient(to bottom, #6f57d8, #d56a8f);
    position: relative;
    min-width: 100%;
    height: 8rem;
    display: grid;
    place-items: center;
    border-radius: .8rem;
}

.ai__message-new-m>div>textarea {
    min-width: 99.2%;
    min-height: 94%;
    resize: none;
    border-radius: .8rem;
    background: #EEF0ED;
    outline: none;
    color: #121830;
    padding: 1.2rem 1.6rem;
    font-size: 1.6rem;
}

.ai__message-new-m>div>button {
    position: absolute;
    right: 1.8rem;
    background: linear-gradient(to bottom, #6f57d8, #d56a8f);
    border-radius: 100%;
    padding: .6rem;
    font-size: 3.5rem;
}

.ai__message-suggestions {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
}

.ai__message-suggestions>li {
    background: #EEF0ED;
    border-radius: .5rem;
    color: #121830;
    padding: .5rem 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
}

/* -------------Ai Input Field-------------- */
.ai__chat-input {
    flex-basis: 10%;
    max-width: 70rem;
    padding: 0 0 1rem 3rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.ai__chat-input>div {
    display: flex;
    align-items: center;
    margin-left: .3rem;
    min-height: 6rem;
    width: 100%;
    border-radius: 1.2rem;
    background: #f3f3ed;
    padding-right: 1rem;
    box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.5);
}

.ai__chat-input>div>div>div {
    right: 2rem;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1rem;
}

.ai__textarea-wrapper {
    width: 100%;
}

.ai__textarea-wrapper>textarea {
    color: #121830;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 400;
    background: #f3f3ed;
    /* background: rgba(18, 25, 47, 1); */
    border: transparent;
    outline: none;
    resize: none;
    height: 4.8rem;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    transition: height 0.3s ease;
    border-radius: 1rem;
}

.ai__textarea-wrapper>textarea:focus {
    height: 13rem;
    overflow: auto;
}

.ai__textarea-wrapper>textarea::-webkit-scrollbar {
    width: .7rem;
}

.ai__textarea-wrapper>textarea::-webkit-scrollbar-thumb {
    background-color: rgba(224, 224, 224, 0.9);
    border-radius: 6px;
    cursor: pointer;
}

.ai__textarea-wrapper>textarea::-webkit-scrollbar-thumb:hover {
    background-color: #8c8c8c;
}

.ai__chat-input-mic {
    cursor: pointer;
}

.ai__chat-input-send {
    border-radius: 100%;
    background-color: #121830a1;
    width: 4rem;
    height: 4rem;
    place-items: center;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.ai__message-res {
    background: color(display-p3 0 0 0 / 0.70);
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    border-radius: 0 0 2rem 2rem;
    padding: .4rem 1rem;
}

.ai__message-res>span {
    width: 6rem;
}

.ai__message-text {
    max-height: 100%;
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.4rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.ai__message-loading {
    display: flex;
    justify-content: center;
    padding: .6rem 0 0 0;
}

.ai__message-loading>img {
    width: 3.3rem;
}

@keyframes vibrate {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        transform: translateX(3px) rotate(2deg);
    }

    75% {
        transform: translateX(-3px) rotate(-2deg);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* @media screen and (max-width: 992px) {
    .ai__message-new-m {
        flex-flow: row wrap;
    }

    .ai__message-new-m>div {
        display: flex;
        flex-grow: 1;
        flex-basis: auto;
    }
} */

@media screen and (max-width: 600px) {

    .ai__message-new {
        position: relative;
        scroll-behavior: smooth;
        min-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding-top: 6rem;
    }

    .ai__message-new-m {
        flex-flow: column-reverse nowrap;
    }

    .ai__message-new-m>div:nth-child(3) {
        display: none;
    }

    .ai__message-new-m>div:nth-child(4) {
        display: none;
    }

    .ai_message-main-logo>p {
        font-size: 4rem;
        text-align: center;
    }    
}