/* main css */
.mentorlist__container-main {
  max-width: 100%;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: visible;
  margin: 0 auto;
  box-sizing: border-box;
  color: white;
  background-color: black;
  padding-bottom: 8rem;
}

.mentorlist__container {
  background-color: black;
  color: white;
  z-index: 1;
  max-width: 180rem;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0 8rem;
}

.mentorlist__nav-item {
  margin-right: 2.94rem;
}

/* Scrollbar.css */
.mentorlist__scrollable-tabs-container {
  max-width: 100%;
  margin: auto;
  height: 160px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 0 2rem;
}

.mentorlist__left-arrow {
  position: absolute;
  height: 100%;
  width: 100px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.mentorlist__left-arrow>svg {
  cursor: pointer;
  height: 3rem;
  margin-left: .8rem;
}

.mentorlist__right-arrow {
  position: absolute;
  height: 100%;
  width: 100px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mentorlist__right-arrow>svg {
  cursor: pointer;
  height: 3rem;
  margin-right: .8rem;
}

.mentorlist__scrollable-tabs-container>ul {
  display: flex;
  gap: 1.5rem;
  padding: 0.5rem 1.5rem;
  margin: 0 4rem;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mentorlist__scrollable-tabs-container>ul::-webkit-scrollbar {
  display: none;
}

.mentorlist__scrollable-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.3rem;
  padding: 0.8rem 1.5rem;
  margin: 0 0.2rem;
  width: fit-content;
  cursor: pointer;
  border: 2px solid black;
  user-select: none;
}

.mentorlist__scrollable-item.selected {
  background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to bottom, #6F57D8, #D56A8F) border-box;
  border: 2px solid transparent;
  border-radius: 1rem;
}

.mentorlist__scrollable-item>div {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  background-color: black;
}

.mentorlist__scrollable-item>h3 {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 500;
  white-space: nowrap;
}

/* PriceRangeSelector.css */
.price-range-selector {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-left: 5px;
}

.slider-container {
  position: absolute;
  top: 25px;
  left: -10px;
  width: 120px;
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

input[type='range'] {
  width: 100%;
}

/* Hide the default input slider styles */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

input[type='range']::-moz-range-thumb {
  width: 0;
  height: 0;
}

input[type='range']::-ms-thumb {
  width: 0;
  height: 0;
}


/* ---------mentorlist__card-container--------- */
.mentorlist__card-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 1.87rem;
}


/*------------------mentorlist__filter-----------------------*/

.mentorlist__filter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: fit-content;
  max-width: 100%;
  min-height: 5rem;
  gap: 2rem;
  margin-bottom: 1rem;
}

.mentorlist__filter-search {
  flex-basis: 55%;
  max-width: 100%;
  height: 5rem;
  background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to right, #BD5E81, #7057D6) border-box;
  border: 1px solid transparent;
  border-radius: 80px;
  overflow: hidden;
}

.mentorlist__filter-search>input {
  min-height: 100%;
  width: 100%;
  padding: 0 2rem;
  color: white;
  outline: none;
  border: none;
  background: transparent;
  font-size: 1.4rem;
}

.mentorlist__filter-right {
  flex-basis: 45%;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 2rem;
}

.mentorlist__filter-price {
  height: 5rem;
  min-width: 45%;
  background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to right, #BD5E81, #7057D6) border-box;
  border: 2px solid transparent;
  border-radius: 80px;
  overflow: hidden;
}

.mentorlist__filter-price>select {
  height: 100%;
  width: 97%;
  outline: none;
  border: none;
  background: transparent;
  color: white;
  padding: 0 1.4rem;
  cursor: pointer;
  font-size: 1.4rem;
}

.mentorlist__filter-area {
  height: 5rem;
  min-width: 45%;
  background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to right, #BD5E81, #7057D6) border-box;
  border: 2px solid transparent;
  border-radius: 80px;
}

.mentorlist__filter-area>select {
  height: 100%;
  width: 97%;
  outline: none;
  border: none;
  background: transparent;
  color: white;
  padding: 0 1.4rem;
  cursor: pointer;
  font-size: 1.4rem;
}

option {
  height: 16rem;
  width: 100%;
  outline: none;
  margin-top: .4rem;
  background-color: #232323;
  color: white;
  cursor: pointer;
}

/* -------------mentorlist__contact------------------ */
.mentorlist__contact {
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.mentorlist__contact>span {
  font-weight: 800;
  margin-left: .3rem;
  text-decoration: underline;
  cursor: pointer;
}

.mentorlist__pageBtn_Con {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  margin-top: 2rem;
}

.mentorlist__pageBtn {
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out;
  padding: .5rem 1rem;
  color: white;
  background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
  border: 1px solid transparent;
  border-radius: .8rem;
  color: #FFF;
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;
}

.mentorlist__pageBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}






@media screen and (max-width: 970px) {

  /* main css */
  .mentorlist__container-main {
    padding-bottom: 8rem;
  }

  .mentorlist__container {
    padding: 0 4rem;
  }

  /* Scrollbar.css */
  .mentorlist__scrollable-tabs-container {
    height: 10rem;
    margin-bottom: 2rem;
  }

  .mentorlist__scrollable-tabs-container>ul {
    gap: 1rem;
    padding: 0.5rem 1.5rem;
    margin: 0 1rem;
  }

  /*------------------mentorlist__filter-----------------------*/
  .mentorlist__filter {
    flex-flow: column nowrap;
    align-items: center;
  }

  .mentorlist__filter-search {
    min-width: 100%;
  }

  .mentorlist__filter-search>input {
    min-height: 100%;
    width: 100%;
    padding: 0 2rem;
    color: white;
    outline: none;
    border: none;
    background: transparent;
  }

  .mentorlist__filter-right {
    flex-basis: 50%;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 2rem;
  }

  .mentorlist__filter-price {
    height: 5rem;
    min-width: 48%;
    background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to right, #BD5E81, #7057D6) border-box;
    border: 2px solid transparent;
    border-radius: 80px;
    overflow: hidden;
  }

  .mentorlist__filter-area {
    height: 5rem;
    min-width: 48%;
    background: linear-gradient(#000000 0 0) padding-box, linear-gradient(to right, #BD5E81, #7057D6) border-box;
    border: 2px solid transparent;
    border-radius: 80px;
  }
}

@media screen and (max-width: 600px) {
  .mentorlist__container {
    padding: 0 2rem;
  }

  .mentorlist__scrollable-tabs-container>ul {
    gap: .4rem;
  }

  .mentorlist__scrollable-item {
    gap: .7rem;
    padding: 0.4rem 1rem;
    margin: 0;
  }

  .mentorlist__scrollable-item>div {
    width: 2.5rem;
    height: 2.rem;
  }

  .mentorlist__scrollable-item>h3 {
    font-weight: 400;
    white-space: nowrap;
  }
}