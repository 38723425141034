.scroll__button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border-radius: 50%;
  padding: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  transition: all 1s ease-in-out;
  z-index: 5;
  transition: box-shadow 0.3s ease-in-out;
  color: white;
  background: linear-gradient(rgba(30, 19, 33, 0.82) 0 0) padding-box, linear-gradient(to top, #6F57D8, #D56A8F) border-box;
  border: 1px solid transparent;
  border-radius: 4rem;
}

.scroll__button:hover {
  box-shadow: .2rem .2rem 1rem rgba(255, 255, 255, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.scroll__button>i {
  color: black;
}

@media screen and (max-width: 1200px) {
  .scroll__button {
    position: fixed;
    bottom: 8rem;
    right: 1.5rem;
    border-radius: 50%;
    padding: 1.5rem;
  }
}