/* main css */
.askmentors__container-main {
    max-width: 100%;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: visible;
    margin: 0 auto;
    box-sizing: border-box;
    color: white;
    padding-bottom: 8rem;
    background:
        linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%),
        linear-gradient(to bottom, #714FB4, #714FB4);
}

.askmentors__container {
    color: white;
    z-index: 1;
    max-width: 200rem;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    padding: 0 8rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 5rem;
}

/* ---------------heading----------------- */
.askmentors__heading {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 0;
}

.askmentors__heading>h1 {
    font-size: 5.8rem;
    font-weight: 700;
    background: linear-gradient(90deg, #FFF 49.88%, rgba(255, 255, 255, 0.00) 106.18%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.askmentors__heading>p {
    color: rgba(255, 255, 255, 0.65);
    font-size: 2rem;
    font-weight: 600;
    width: 34rem;
    flex-shrink: 0;
}

/* ---------------head card----------------- */
.askmentors__head-card {
    display: flex;
    min-width: 100%;
    padding: 4rem 9rem;
    align-items: center;
    gap: 3.7rem;
    border-radius: 2rem;
    background: #FFF;
}

/* ---------------head card text----------------- */
.askmentors__head-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3.6rem;
    flex: 1 0 0;
}

.askmentors__head-card-text>p {
    color: #0B2A6B;
    font-size: 3rem;
    font-weight: 600;
}

.askmentors__head-card-text>div {
    display: flex;
    max-width: 100%;
    padding: 1rem 1.6rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 1rem;
    background: #56A3F3;
    cursor: pointer;
    user-select: none;
}

.askmentors__head-card-text>div>img {
    height: 3rem;
    width: 3rem;
}

.askmentors__head-card-text>div>span {
    color: #FFF;
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.9rem;
}

/* ---------------head card img----------------- */
.askmentors__head-card-text-img {
    width: 29.7rem;
    height: 29.7rem;
}

/* ----------main content------------ */
.askmentors__main {
    display: grid;
    grid-template-columns: 1fr 40rem;
    grid-template-rows: 100%;
    gap: 2rem;
}

/* ----------main content comment------------ */
.askmentors__main-com {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    height: 200vh;
}

.askmentors__filter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    min-width: 100%;
    padding: 0 2.4rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.askmentors__filter-items {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.60);
    font-size: 2.5rem;
    font-weight: 400;
    padding: 1rem 0;
}

.askmentors__filter-items.selected {
    color: #FFF;
    border-bottom: 1px solid white;
}

.askmentors__filter-mobile {
    display: none;
}

/* ----------main content search------------ */
.askmentors__main-com-container {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
    overflow-y: scroll;
    height: 198vh;
    padding-right: .5rem;
    min-width: 100%;
}

.askmentors__main-com-container::-webkit-scrollbar {
    width: .8rem;
}

.askmentors__main-com-container::-webkit-scrollbar-thumb {
    border-radius: 2.1875rem;
    background: rgba(255, 255, 255, 0.40);
}

.askmentors__main-com-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* ----------askmentors comment box------------ */
.askmentors__cbox-main {
    padding: 5rem 8rem;
    background-color: black;
    padding-bottom: 3.38rem;
    min-height: 100%;
    background: linear-gradient(to top, rgba(26, 19, 40, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
}

/* ----------main content search------------ */
.askmentors__main-search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex-shrink: 0;
    min-width: 100%;
    height: 200vh;
}

/* -----------Sidebar and filter ---------- */
.askmentors__sidebar {
    height: 200vh;
    max-width: 100%;

}

.askmentors__sidebar-input {
    min-width: 100%;
    height: 5.5rem;
    flex-shrink: 0;
    border-radius: 5.75rem;
    border: 1px solid #FFF;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.7rem;
    display: flex;
    align-items: center;
}

.askmentors__sidebar-input>span {
    position: absolute;
    font-size: 2rem;
    right: 2rem;
    color: gray;
    cursor: pointer;
}

.askmentors__sidebar-input>input {
    background-color: transparent;
    border-radius: 5.75rem;
    border: 1px solid transparent;
    color: white;
    outline: none;
    min-width: 100%;
    height: 100%;
    flex-basis: 30%;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 400;
    padding: 0 5rem;
}

.askmentors__sidebar-input>img {
    position: absolute;
    left: 2rem;
}

.askmentors__sidebar-filter {
    display: flex;
    flex-flow: column nowrap;
}

.askmentors__sidebar-filter>h1 {
    color: #D56A8E;
    font-family: Poppins;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 2.5rem;
    padding-left: 1rem;
    border-bottom: 1px solid #D56A8E;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

.askmentors__sidebar-filter-scroll {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.6rem;
    max-height: 100%;
    overflow-y: scroll;
    height: calc(200vh - 12rem);
}

.askmentors__sidebar-filter-scroll::-webkit-scrollbar {
    width: .00000007rem;
}

.askmentors__sidebar-filter-scroll__option {
    max-width: 100%;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: rgba(255, 255, 255, 0.90);
    font-size: 2.2rem;
    font-weight: 400;
}

.askmentors__sidebar-filter-scroll__option>img {
    height: 2rem;
}

.askmentors__sidebar-filter-scroll__option:hover {
    color: #A560AE;
    transition: backgound-color 3s;
}

.askmentors__sidebar-filter-scroll__option.selected {
    padding: 0.8rem;
    border-radius: 0.8rem;
    background: rgba(255, 255, 255, 0.19);
}

.askmentors__sidebar-filter-scroll__option.selected:hover {
    background: rgba(229, 229, 229, 0.19);
}

/* -----------Comments ---------- */
.askmentors__comments {
    flex-basis: 80%;
    max-height: 150vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 2rem;
    overflow-y: scroll;
    padding: .4rem 0.5rem 1rem 0.5rem;
}

.askmentors__comments::-webkit-scrollbar {
    width: 0.0000009rem;

}

.askmentors__comments::-webkit-scrollbar-thumb {
    border-radius: 2.1875rem;
    background: rgba(255, 255, 255, 0.40);
}

.askmentors__comments::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

@media (max-width: 1800px) {
    .askmentors__heading>h1 {
        font-size: 5rem;
    }

    .askmentors__heading>p {
        font-size: 2rem;
        width: 45rem;
    }

    /* ---------------head card text----------------- */
    .askmentors__head-card-text>p {
        font-size: 3rem;
    }

    .askmentors__head-card-text>div {
        padding: 1rem 1.6rem;
    }



    /* ----------main content------------ */
    .askmentors__main {
        grid-template-columns: 1fr 34rem;
        gap: 2rem;
    }

    /* ----------main content comment------------ */
    .askmentors__filter-items {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input {
        width: 100%;
        height: 4.8rem;
        flex-shrink: 0;
        border-radius: 5.75rem;
        border: 1px solid #FFF;
        overflow: hidden;
        position: relative;
        margin-bottom: 1.7rem;
        display: flex;
        align-items: center;
    }

    .askmentors__sidebar-input>span {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input>input {
        font-size: 1.8rem;
        font-weight: 400;
        padding: 0 5rem;
    }

    .askmentors__sidebar-input>img {
        position: absolute;
        left: 2rem;
    }

    .askmentors__sidebar-filter {
        display: flex;
        flex-flow: column nowrap;
    }

    .askmentors__sidebar-filter>h1 {
        font-size: 2.2rem;
    }

    .askmentors__sidebar-filter-scroll__option {
        font-size: 2rem;
    }
}

@media (max-width: 1600px) {

    /* ---------------heading----------------- */
    .askmentors__heading>h1 {
        font-size: 4rem;
    }

    .askmentors__heading>p {
        font-size: 2rem;
        width: 40rem;
    }

    /* ---------------head card text----------------- */
    .askmentors__head-card-text>p {
        font-size: 2.7rem;
    }

    .askmentors__head-card-text>div {
        padding: 1rem 1.6rem;
    }

    /* ----------main content------------ */
    .askmentors__main {
        grid-template-columns: 1fr 30rem;
        gap: 2rem;
    }

    /* ----------main content comment------------ */
    .askmentors__filter-items {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input {
        width: 100%;
        height: 4.2rem;
        flex-shrink: 0;
        border-radius: 5.75rem;
        border: 1px solid #FFF;
        overflow: hidden;
        position: relative;
        margin-bottom: 1.7rem;
        display: flex;
        align-items: center;
    }

    .askmentors__sidebar-input>span {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input>input {
        font-size: 1.6rem;
        font-weight: 400;
        padding: 0 5rem;
    }

    .askmentors__sidebar-input>img {
        position: absolute;
        left: 2rem;
    }

    .askmentors__sidebar-filter {
        display: flex;
        flex-flow: column nowrap;
    }

    .askmentors__sidebar-filter>h1 {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-filter-scroll__option {
        font-size: 1.7rem;
    }
}

@media (max-width: 1230px) {

    /* ---------------heading----------------- */
    .askmentors__heading>h1 {
        font-size: 3rem;
    }

    .askmentors__heading>p {
        font-size: 1.6rem;
        width: 32rem;
    }

    /* ---------------head card text----------------- */
    .askmentors__head-card-text>p {
        font-size: 2.7rem;
    }

    .askmentors__head-card-text>div {
        padding: 1rem 1.6rem;
    }

    /* ----------main content------------ */
    .askmentors__main {
        grid-template-columns: 1fr 26rem;
        gap: 2rem;
    }

    /* ----------main content comment------------ */
    .askmentors__filter-items {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input {
        width: 100%;
        height: 4.2rem;
        flex-shrink: 0;
        border-radius: 5.75rem;
        border: 1px solid #FFF;
        overflow: hidden;
        position: relative;
        margin-bottom: 1.7rem;
        display: flex;
        align-items: center;
    }

    .askmentors__sidebar-input>span {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-input>input {
        font-size: 1.6rem;
        font-weight: 400;
        padding: 0 5rem;
    }

    .askmentors__sidebar-input>img {
        position: absolute;
        left: 2rem;
    }

    .askmentors__sidebar-filter {
        display: flex;
        flex-flow: column nowrap;
    }

    .askmentors__sidebar-filter>h1 {
        font-size: 1.8rem;
    }

    .askmentors__sidebar-filter-scroll__option {
        font-size: 1.7rem;
    }
}

@media (max-width: 992px) {
    .askmentors__filter-items {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.60);
        font-size: 1.6rem;
        font-weight: 400;
        padding: 1rem 0;
    }

    .askmentors__container {
        padding: 0 2rem;
    }
    /* ---------------heading----------------- */
    .askmentors__heading {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        padding: 5rem 0 3rem 0;
    }

    .askmentors__heading>h1 {
        font-size: 3.5rem;
        background: white;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .askmentors__heading>p {
        font-size: 2rem;
        width: 100%;
    }

    /* ---------------head card----------------- */
    .askmentors__head-card {
        padding: 2rem;
        gap: 1rem;
        height: 100%;
    }

    /* ---------------head card text----------------- */
    .askmentors__head-card-text {
        gap: 1.6rem;
    }

    .askmentors__head-card-text>p {
        font-size: 2.4rem;
    }

    .askmentors__head-card-text>div {
        display: flex;
        max-width: 100%;
        padding: 1rem 1.4rem;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border-radius: 1rem;
        background: #56A3F3;
        cursor: pointer;
        user-select: none;
    }

    .askmentors__head-card-text>div>img {
        height: 2.6rem;
        width: 2.6rem;
    }

    .askmentors__head-card-text>div>span {
        font-size: 1.6rem;
        font-weight: 400;
    }

    .askmentors__head-card-img {
        display: none;
    }

    /* ----------main content------------ */
    .askmentors__main {
        grid-template-columns: 1fr 0;
        gap: 0;
    }

    /* ----------main content comment------------ */

    .askmentors__main-com-container::-webkit-scrollbar {
        width: .0002rem;
    }

    .askmentors__main-com-container {
        padding-right: 0;
    }

    /* ----------main content comment------------ */
    .askmentors__main-search {
        display: none
    }
}

@media (max-width: 600px) {
    .askmentors__filter {
        display: none;
    }

    .askmentors__filter-mobile {
        display: flex;
    }

    /* Styles for the mobile filters dropdown */
    .askmentors__filter-mobile {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        min-width: 100%;
    }

    .askmentors__filter-mobile>div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: .2rem;
        min-width: calc(100% - 12rem);
    }

    .askmentors__filter-mobile>div>li {
        flex-grow: 1;
        list-style: none;
        background: rgba(255, 255, 255, 0.55);
        height: .1rem;
    }

    .askmentors__filter-mobile>div>span {
        color: rgba(255, 255, 255, 0.55);
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 400;
        margin-right: .3rem;
    }

    .askmentors__filter-mobile select {
        /* appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none; */
        background: rgb(74, 74, 74);
        border: none;
        padding: .4rem;
        width: 12rem;
        border-radius: 1.2rem;
        cursor: pointer;
        outline: none;
        color: white;
        font-family: Poppins;
        font-size: 1.2rem;
        font-weight: 400;
    }

}