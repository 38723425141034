.startup__main {
    max-height: 100vh;
    min-width: 98.9vw;
    background: #000;
    box-shadow: -5px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 4.5rem 8rem 0 8rem;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    overflow-y: scroll;
}

.startup {
    height: 100%;
    width: 100%;
    max-width: 135rem;
}

/* ----------------- top heading --------------- */
.startup__head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.startup__head>div {
    width: 18rem;
    border-width: 10rem;
    height: 2.7rem;
}

.startup__head>h1 {
    background-repeat: no-repeat;
    background-position: 1;
    z-index: 1;
    position: relative;
    padding-left: 12px;
    color: #FFF;
    font-family: Ponnala;
    font-size: 2.6rem;
    font-weight: 500;
}

.startup__head>div>h1>img {
    height: 50px;
    position: absolute;
    top: -.9rem;
    left: 0;
    z-index: -1;
}

.startup__head>img {
    max-width: 18rem;
}

/* ----------------- top para --------------- */

.startup>p {
    color: rgba(216, 216, 216, 0.80);
    font-family: Poppins;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* ----------------- Steps --------------- */
.startup__step {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    min-width: 100%;
}

.startup__step>img {
    max-height: 5.8rem;
}

/* ---------------------------------------------- */
.startup>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 3.7rem;
}

/* --------------startup__form--------------- */
.startup__form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3rem;
    color: white;
}

.startup__formcol {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 100%;
}

.startup__formcol>div {
    width: 100%;
}

.startup__form-margin {
    margin-right: 5%;
}

.startup__formcol>div>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
}

.startup__formcol>div>input {
    min-width: 100%;
    height: 5rem;
    outline: none;
    background-color: black;
    color: white;
    padding: 0.6rem 1.7rem;
    font-size: 1.6rem;
    margin: 0 0 2.19rem 0;
    border-radius: 0.7rem;
    border: 2px solid #7D59CC;
}

.startup__formcol>div>textarea {
    width: 100%;
    height: 14rem;
    flex-shrink: 0;
    border-radius: 0.7rem;
    border: 2px solid #7D59CC;
    outline: none;
    background-color: black;
    color: white;
    padding: 0.6rem 1.7rem;
    font-size: 1.6rem;
    margin: 0 0 2.19rem 0;
}

/*-------------------startup__mid;---------------------- */
.startup__mid {
    min-height: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    padding: 1.5rem 0;
}

/* --------------------checkbox ------------------- */
.startup__form-checkbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 100%;
}

.startup__form-checkbox>input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 15rem;
    flex-shrink: 0;
    margin: 1rem;
}

.startup__form-checkbox>p {
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 1.rem;
    font-weight: 400;
}

.startup__checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Hide the browser's default checkbox */
.startup__checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.startup__checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.startup__checkbox-container:hover input~.startup__checkbox-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.startup__checkbox-container input:checked~.startup__checkbox-checkmark {
    background-color: #7D59CC;
}

/* Create the checkmark/indicator (hidden when not checked) */
.startup__checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.startup__checkbox-container input:checked~.startup__checkbox-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.startup__checkbox-container .startup__checkbox-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* --------------------submit button ------------------- */

.startup__submit {
    max-width: 135rem;
    height: 5rem;
    box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
    display: grid;
    place-items: center;
    color: #000;
    cursor: pointer;
    border-radius: 0.9375rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: 2rem;
    transition: background-color 0.5s;
}

.startup__submit:hover {
    color: #ccc;
    background: black;
    border: 2px solid #ccc;
    transition: background-color 0.5s;
}

.startup__bottom-img {
    margin-top: 4rem;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.startup__bottom-img>img {
    display: inline-block;
    max-width: 100%;
    object-fit: fill;
}



@media screen and (max-width: 990px) {
    .startup__main {
        max-height: 100vh;
        min-width: 98.9vw;
        background: #000;
        box-shadow: -5px 4px 10px 0px rgba(0, 0, 0, 0.25);
        padding: 4.5rem 4rem 0 4rem;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        overflow-y: scroll;
        border: 1px solid saddlebrown;
    }

    .startup {
        height: 100%;
        width: 100%;
        max-width: 135rem;
    }




    .startup__formcol {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        min-width: 100%;
    }

    .startup__formcol>div {
        width: 100%;
    }

    .startup__form-margin {
        margin-right: 0;
    }

    .startup__formcol>div>h1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.7rem;
    }

    .startup__formcol>div>input {
        min-width: 100%;
        height: 5rem;
        outline: none;
        background-color: black;
        color: white;
        padding: 0.6rem 1.7rem;
        font-size: 1.6rem;
        margin: 0 0 2.19rem 0;
        border-radius: 0.7rem;
        border: 2px solid #7D59CC;
    }

    .startup__formcol>div>textarea {
        width: 100%;
        height: 14rem;
        flex-shrink: 0;
        border-radius: 0.7rem;
        border: 2px solid #7D59CC;
        outline: none;
        background-color: black;
        color: white;
        padding: 0.6rem 1.7rem;
        font-size: 1.6rem;
        margin: 0 0 2.19rem 0;
    }


}

@media screen and (max-width: 600px) {
    .startup__main {
        padding: 4.5rem 2rem 0 2rem;
    }

    .startup {
        height: 100%;
        width: 100%;
        max-width: 135rem;
    }

    .startup__formcol {
        flex-flow: column nowrap;
    }

    .startup__formcol>div {
        width: 100%;
    }

}