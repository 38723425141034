.notFound{
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
    height: 100vh;
    width: 100vw;
    color: white;
    display: grid;
    place-items: center;
}
.notFound>div{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.notFound>div>div{
    margin-left: 1rem;
}
.notFound>div>div>h1{
   font-size: 3rem;
   font-weight: 300;
}
.notFound>div>div>p{
   font-size: 1.4rem;
}
.notFound>div>div>a{
   font-size: 1.4rem;
}
.notFound>div>div>a>span{
   text-decoration: underline;
   font-weight: bold;
}