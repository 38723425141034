/* ---------------Profile roadmap---------------- */
.profile__roadmap-main {
    padding: 3rem 8rem;
    max-width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile__roadmap {
    max-width: 135rem;
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 3rem;
}

.profile_roadmap-text {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.profile_roadmap-text>h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3rem;
    font-weight: 500;
}

.profile_roadmap-img {
    display: flex;
    max-width: 135rem;
}

.profile_roadmap-img>img {
    max-width: 100%;
}




@media screen and (max-width: 1100px) {

    /* ---------------Profile roadmap---------------- */
    .profile__roadmap-main {
        padding: 3rem 4rem;
    }

    .profile__roadmap {
        max-width: 100%;
    }
    
    .profile_roadmap-text>h1 {
        font-size: 2.4rem;
    }

    .profile_roadmap-img {
        display: flex;
        align-items: center;
        max-width: 100vw;
        overflow-x: scroll;
    }

    .profile_roadmap-img::-webkit-scrollbar {
        width: 2px;
    }

    .profile_roadmap-img::-webkit-scrollbar-thumb {
        border-radius: 2.1875rem;
        background: rgba(255, 255, 255, 0.40);
    }

    .profile_roadmap-img::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    .profile_roadmap-img>img {
        min-width: 135rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        object-fit: fill;
        display: block;
    }
}

@media screen and (max-width: 750px) {
    .profile_roadmap-text {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .profile__roadmap-main {
        padding: 2rem;
    }
}