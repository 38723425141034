.editAvatar__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    outline: none;
    border-radius: 4px;
    border-radius: 2rem;
    width: 55rem;
    height: 45rem;
    background: linear-gradient(347deg, #000 0%, #18151C 100%);
}

.editAvatar__head {
    color: rgba(255, 255, 255, 0.95);
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 500;
    padding: 2rem 3rem 1.7rem 3rem;
    height: 6rem;
    border-bottom: 1px solid gainsboro;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.editAvatar__head>span{
    cursor: pointer;
}

.editAvatar__main{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 100%;
}

.editAvatar__image {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

.editAvatar__image>h1 {
    font-family: Poppins;
    color: rgba(255, 255, 255, 0.95);
    font-size: 1.8rem;
    font-weight: 500;
    padding: 2rem 3rem .6rem 3rem;
    margin-bottom: 1rem;
   
}

.editAvatar__image>div {
    width: 17rem;
    height: 17rem;
    border-radius: 15.75rem;
    border: 3px solid #FFF;
    box-shadow: 4px 4px 70px 1px rgba(0, 0, 0, 0.50);
    overflow: hidden;
}

.editAvatar__image>div>img {
    width: 100%;
    height: 100%;
}

.editAvatar__image>p {
    margin-top: .7rem;
    font-size: 15px;
    color: rgb(88, 88, 88);
}


.editAvatar__upload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 6rem;
    border-top: 1px solid gainsboro;
    padding: 0 3rem;
}

.editAvatar__input {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 4rem;
    width: 18rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 600;
}

.editAvatar__input>input {
    position: absolute;
    opacity: 0;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    cursor: pointer;
    background-color: white;
    border: 1px solid;
}

.editAvatar__submit {
    position: relative;
    display: grid;
    place-content: center;
    cursor: pointer;
    height: 4rem;
    width: 8rem;
    border-radius: 1.5rem;
    background: linear-gradient(118deg, #7E59CB 0%, #BB649E 100%);
    color: #E5E3E3;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 600;
}

@media screen and (max-width: 650px) {
    .editAvatar__container {
        border-radius: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 7rem;
    }
}